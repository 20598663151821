import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin, Button, notification, Typography } from 'antd';
import { PitstopModal } from 'shared';
import styled from 'styled-components';
import { AppStore } from 'stores';
import WorkOrderTMTForm from 'components/WorkOrder/WOTMTDetailsForm';

const { Text } = Typography;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .footer-text {
      font-weight: bold;
      padding-right: 3vw;
    }
  }
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const CREATE_TMT_WORK_ORDER_MODAL_ID = 'CREATE_TMT_WORK_ORDER_MODAL_ID';

const CreateTMTWorkOrderModal = ({
  modalId,
  title,
  isLoading = false,
  onSave = () => {},
  loading = false,
  issues = [],
  tmtRepairShop
}) => {
  const [formRef, setFormRef] = useState([]);

  const handleSubmit = values => {
    const result = issues.map((defect, index) => ({
      defectId: defect.id,
      defectType: defect.defectType,
      complaint: values[`complaint_${index}`],
      componentCode: values[`componentCode_${index}`],
      repairReason: values[`repairReason_${index}`],
      assignedTo: values.woAssignedTo,
      createdBy: values.woCreatedBy,
      invoiceNumber: values.woInvoiceNumber,
      repairPriority: values.woRepairPriority,
      comment: defect.comment || 'NA'
    }));
    onSave(result);
  };

  return (
    <StyledModal
      id={modalId}
      title={title}
      width={850}
      okText="Confirm"
      footer={(<>
        <Text className="footer-text">You can add more details, such as description, cost, etc., to this work order once it's created</Text>
        <Button
          key="back"
          onClick={() => {
            AppStore.openModals.set(CREATE_TMT_WORK_ORDER_MODAL_ID, false);
          }}
        >
          Cancel
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            formRef.props.form.validateFields((err, values) => {
              if (err) {
                let friendlyMsg = '';
                const errorKeys = Object.keys(err);
                if (
                  errorKeys &&
                  errorKeys[0] &&
                  err[errorKeys[0]] &&
                  err[errorKeys[0]].errors &&
                  err[errorKeys[0]].errors.length &&
                  err[errorKeys[0]].errors[0].message
                ) {
                  friendlyMsg = err[errorKeys[0]].errors[0].message;
                } else {
                  friendlyMsg = 'Error on saving Work Order';
                }
                return notification.error({
                  message: 'Oops!',
                  key: 'orderSaveError',
                  description: <Container>{friendlyMsg}</Container>,
                });
              }
              handleSubmit({
                ...values
              });
            });
          }}
        >
          Create
        </Button>
      </>)}
    >
      {isLoading ? (
        <Spin size="large" />
      ) : (       
        <>  
        <Row>
          <Col span={24}>
            <WorkOrderTMTForm
              childRef={setFormRef}
              defects={issues}
              tmtRepairShop= {tmtRepairShop}
            />
          </Col>
        </Row>
        </> )}
    </StyledModal>
  );
};

CreateTMTWorkOrderModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  issues: PropTypes.array,
  loading: PropTypes.bool,
  tmtRepairShop: PropTypes.string,
};

export default CreateTMTWorkOrderModal;