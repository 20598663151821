import React, { Component } from 'react';
import { DealershipChooser } from 'components';
import { observe } from 'mobx';
import { AppStore, ShopStore } from 'stores';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import TechinicianEfficiency from 'assets/demo-charts/Frame 55.png';

const FullWidthImage = styled.img`
  width: 100%;
`;

class ReportsDemoPage extends Component {
  state = {};

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/reports');
  }

  disposer = observe(ShopStore.currentShop, 'id', async () => {});

  demoCharts = [
    {
      name: 'Technician Efficiency',
      imagePath: TechinicianEfficiency,
    },
  ];

  render() {
    return (
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <h1>Technician Efficiency</h1>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <DealershipChooser />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <iframe
                style={{ border: '1px solid #ffffff' }}
                width="1575"
                height="643"
                src="https://embed.figma.com/proto/FmiNhBUiy32L1oONLeg5hm/Fleet-Analytics---General-Reports---1AJD?page-id=0%3A1&node-id=315-93&viewport=-2162%2C1309%2C0.32&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=305%3A85&embed-host=share&hide-ui=1"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
        {/* {this.demoCharts.map((chart, index) => (
          <Row key={index} style={{ marginBottom: 10 }}>
            <Col span={24}>
              <FullWidthImage src={chart.imagePath} alt={chart.name} />
            </Col>
          </Row>
        ))} */}
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <iframe
                style={{ border: '1px solid #ffffff' }}
                width="1575"
                height="643"
                src="https://embed.figma.com/proto/FmiNhBUiy32L1oONLeg5hm/Fleet-Analytics---General-Reports---1AJD?page-id=0%3A1&node-id=432-997&viewport=-7408%2C1693%2C0.97&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=180%3A410&show-proto-sidebar=1&embed-host=share&hide-ui=1"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ReportsDemoPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ReportsDemoPage);
