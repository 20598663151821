import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validator from 'validator';
import _ from 'lodash';

import { Checkbox, Row, Col, notification, Spin, Input, Form } from 'antd';
import { ErrorField } from 'v3/components/pitstop';

import { AppStore, CurrentUserStore, ShopStore, UserStore } from 'stores';
import Logger from 'stores/Classes/Logger';

const slogans = [
  "Register now and explore Pitstop with demo data.",
  "Sign up to catch issues early and save time.",
  "Register today and discover root causes with AI.",
  "Create your account to digitize and automate maintenance.",
  "Sign up now to streamline your fleet workflows.",
  "Register today and scan work orders instantly with AI.",
  "Sign up to optimize fault priorities in real-time.",
  "Create your account to save money and keep fleets moving.",
  "Register now to experience smarter fleet management.",
  "Sign up today—your Pitstop AI assistant is ready.",
  "Fleet maintenance simplified by Pitstop AI.",
];

const getRandomMessage = () => {
  return slogans[Math.floor(Math.random() * slogans.length)];
};

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }

  p {
    font-weight: 400;
    color: #8c8c8c;
    opacity: 0.9;
    text-align: center;
  }

  label {
    font-weight: 600;
    display: block;
  }

  input {
    margin: 1.4vh 0vh;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
  width: 100%;
`;

const RegisterButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  color: #ffffff;
  border-radius: 5px;
  height: 3rem;
  min-width: 8rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const RegisterButtonContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1.6vw;
`;

class RegisterForm extends Component {
  static propTypes = {
    isUserRegister: PropTypes.func,
  };

  state = {
    email: null,
    password: null,
    fleetName: null,
    isAcceptedAgreement: false,
    isSubmitted: false,
    slogan: getRandomMessage(),
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return <ErrorField>{type} is required!</ErrorField>;
    }

    if (type === 'email' && !validator.isEmail(value)) {
      return <ErrorField>Email is invalid</ErrorField>;
    }

    if (type === 'password' && value.match(/(\s)/g)) {
      return <ErrorField>Password cannot have blank spaces</ErrorField>;
    }

    return null;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitted: true,
    });

    const { email, password, fleetName, isAcceptedAgreement } = this.state;

    if (
      this.getError('email', email) ||
      this.getError('password', password) ||
      this.getError('fleetName', fleetName) ||
      !isAcceptedAgreement
    ) {
      return;
    }

    this.props.isUserRegister(true);

    notification.open({
      key: 'register',
      description: (
        <>
          <Spin size="large" style={{ marginRight: '0.6rem' }} /> Your account
          is being created...
        </>
      ),
      duration: 0,
    });

    try {
      await UserStore.createUser({
        username: email,
        email: email,
        password: password,
        role: 'dealership',
      });

      AppStore.addSuccess(
        'Successfully added user, logging into dashboard now...'
      );

      let user = await CurrentUserStore.login(email, password);

      await ShopStore.addShop({
        userId: user.id,
        name: fleetName,
        email: email,
        address: '',
      });

      notification.close('register');
      this.props.isUserRegister(false);

      await CurrentUserStore.getCurrentUser();
      await CurrentUserStore.createHubspotContact({
        email: email,
        form_name: 'dashboard-registration',
      });
      await CurrentUserStore.user.updateSettings({
        hasAcceptedEULA: true,
      });
    } catch (err) {
      Logger.error(err.message, { fleetName, email });
      notification.close('register');
      this.props.isUserRegister(false);
      notification.error({
        message: 'Error',
        description: err.message,
      });
    }
  };

  render() {
    const {
      email,
      password,
      fleetName,
      isSubmitted,
      isAcceptedAgreement,
      slogan,
    } = this.state;

    return (
      <Container id="register-form">
        <h1>Try Pitstop AI for free</h1>
        <p>{slogan}</p>
        <DashLine />

        <Row>
          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Enter your work email</label>
            <Input
              name="email"
              placeholder="Work email (e.g., name@company.com)"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('email', email)}
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Set a new password</label>
            <Input
              name="password"
              type="password"
              placeholder="Password (min. 6 characters)"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('password', password)}
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Fleet Name</label>
            <Input
              name="fleetName"
              placeholder="Your awesome fleet name"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('fleetName', fleetName)}
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '1.6vw 0',
            }}
          >
            <Checkbox
              name="isAcceptedAgreement"
              style={{ marginRight: '0.6vw' }}
              onClick={() =>
                this.setState({
                  isAcceptedAgreement: !isAcceptedAgreement,
                })
              }
            />
            <p style={{ margin: 0 }}>
              I agree with{' '}
              <a
                style={{
                  fontWeight: 600,
                  color: '#000000',
                  fontStyle: 'italic',
                }}
                href="https://pitstopconnect.com/terms-conditions-privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
            </p>
          </Col>
          {isSubmitted && !isAcceptedAgreement && (
            <ErrorField style={{ textAlign: 'left', marginBottom: '1.6vw' }}>
              You must accept Terms and Conditions
            </ErrorField>
          )}

          <RegisterButtonContainer>
            <RegisterButton
              isActive={email && password && isAcceptedAgreement}
              onClick={this.onSubmit}
            >
              Register
            </RegisterButton>
          </RegisterButtonContainer>
        </Row>
      </Container>
    );
  }
}

export default RegisterForm;
