import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';
import axios from 'axios';

import {
  Spin,
  Tag,
  Input,
  Select,
  Row,
  Icon,
  Col,
  Form,
  Tooltip,
  Typography,
  Divider,
  Table,
  Button,
  Collapse,
  Skeleton,
} from 'antd';

import { PitstopButton } from 'shared';

import { calcMileage } from 'helpers/unitCalculations';

import {
  AppStore,
  CarStore,
  CurrentUserStore,
  IssueStore,
  ShopStore,
} from 'stores';

import {
  getPriorityCategory,
  getPriorityTagColor,
  getStatusColor,
} from 'services/Issue';

import MarkAsCompleteBtn from './MarkAsCompleteBtn';
import GoogleSearchBtn from './GoogleSearchBtn';
import MarkAsCurrentBtn from './MarkAsCurrentBtn';

const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const PitstopText = styled(Text)`
  font-size: 14px !important;
`;

const SectionTitle = styled(Title)`
  font-size: 16px !important;
  margin-bottom: 1rem !important;
`;

const BlueTitle = styled(Title)`
  font-size: 14px !important;
  color: #5774b4 !important;
`;

const BlueTitleCapitalized = styled(BlueTitle)`
  text-transform: capitalize;
`;

const PriorityWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 10px;
`;

const PriorityUpdateForm = styled(Form)`
  display: flex;
  align-items: center;
  height: 100%;
  .ant-form-item {
    align-items: center;
    margin-bottom: 0px;
    margin-right: 4px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
    margin-right: 4px;
  }
`;

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-typography {
    margin: 0px !important;
  }
`;

class WrappedIssueProfilePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    form: PropTypes.object,
  };

  state = {
    editing: false,
    openServiceHistory: false,
    symptomAnalyserReport: null,
    isFetchingSymptomAnalyserReport: false,
    isSavedConfigService: true,
    currentNotesBoxLength: 0,
    troubleshooting: null,
    loadingTroubleshooting: true,
    relevantServices: null,
    applyToSimilar: 'none',
    updatedPriority: 'nochange',
  };

  disposer = observe(CarStore, 'demo', async () => {
    if (CarStore.demo === false) {
      AppStore.openModals.set('backToDashboard', true);
    }
  });

  loadTroubleshooting = async () => {
    const code = this.issue.name;
    const fmiCode = _.get(this.issue, 'fmi.fmiCode');
    if (
      !IssueStore.data.has(
        Number(String(this.issueId) + String(code) + String(fmiCode))
      )
    ) {
      try {
        this.setState({ loadingTroubleshooting: true });
        const response = await IssueStore.getIssueTroubleshooting(
          this.issueId,
          code,
          fmiCode
        );
        if (!response) {
          return;
        }
        const troubleshooting_info = response;
        this.setState({ troubleshooting: troubleshooting_info });
      } catch (err) {
        // AppStore.addError(err.message);
        console.error(err.message);
      } finally {
        this.setState({ loadingTroubleshooting: false });
      }
    } else {
      this.setState({
        troubleshooting: IssueStore.data.get(
          Number(String(this.issueId) + String(code) + String(fmiCode))
        ),
        loadingTroubleshooting: false,
      });
    }
  };

  async componentDidMount() {
    AppStore.setSelectedSidebarKey('/issues');
    await this.getIssueData();
    // load the issue troubleshooting
    this.loadTroubleshooting();
    let relevantServicesResponse;
    if (IssueStore.data.has(String(this.issueId) + 'relevant-services')) {
      relevantServicesResponse = IssueStore.data.get(
        String(this.issueId) + 'relevant-services'
      );
    } else {
      relevantServicesResponse = await IssueStore.getRelevantServices(
        this.issueId,
        this.issue.name,
        ShopStore.currentShop.id
      );
    }
    this.setState({ relevantServices: relevantServicesResponse });
    // load if configuration is selected for future service based on user's settings
    this.setState({
      isSavedConfigService: !!CurrentUserStore.user.settings
        .defaultSavedConfigService,
    });
  }

  get issueId() {
    return Number(this.props.match.params.id);
  }

  get issue() {
    return IssueStore.data.get(this.issueId);
  }

  getIssueData = async (force = false, showLoading = true) => {
    if (force || !IssueStore.data.has(this.issueId) || !this.issue.loaded) {
      // if issue is not loaded, load it
      await IssueStore.getIssueById(this.issueId);
      // check if ShopStore.currentShop.id is not provided or isAdmin
      if (!ShopStore.currentShop.id || ShopStore.currentShop.id === -1) {
        try {
          if (showLoading) {
            AppStore.addLoading('Loading issue data ...');
          }
          // get car data
          const carId = this.issue.car.id;
          const carData = await CarStore.getCarByIdAndReturn(carId);
          let shopId = carData.shop.id;
          // Update the issue with shopId
          await IssueStore.getIssueById(this.issueId, shopId);
        } catch (err) {
          // AppStore.addError(err.message);
          console.error(err.message);
        } finally {
          if (showLoading) {
            AppStore.removeLoading('Loading issue data ...');
          }
        }
      }
    }
  };

  onUpdate = async (values) => {
    let updateIssueObject = {};

    _.each(values, (value, key) => {
      if (!_.isNil(value)) {
        updateIssueObject[key] = value;
      }
      if (updateIssueObject['priority'] === 0) {
        updateIssueObject['priority'] = 1;
      }
    });
    if (this.state.updatedPriority !== 'nochange') {
      updateIssueObject['priority'] = parseInt(this.state.updatedPriority);
    }
    if (
      this.state.applyToSimilar !== 'entire_fleet' &&
      this.state.applyToSimilar !== 'none'
    ) {
      updateIssueObject['applyToMMYConfig'] = this.state.applyToSimilar;
    }
    if (this.state.applyToSimilar !== 'entire_fleet') {
      updateIssueObject['applyToSimilarIssues'] = false;
    }
    try {
      AppStore.addLoading('Updating the issue ...');
      await this.issue.update(updateIssueObject);
      await this.getIssueData();
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Updating the issue ...');
      this.setState({
        editing: false,
        isSavedConfigService: !!CurrentUserStore.user.settings
          .defaultSavedConfigService,
      });
    }
  };

  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let _values = {
          shopId: ShopStore.currentShop.id,
          userId: CurrentUserStore.user.id,
          item: this.issue.item,
          status: this.issue.status,
          source: this.issue.source,
        };

        _.each(values, (value, key) => {
          if (key === 'item') {
            if (_values['report']) _values['report'] = { testName: value };

            _values['item'] = value;
          } else if (key === 'priority') {
            if (typeof value === 'string') {
              _values['priority'] = parseInt(values.priority);
            } else {
              _values['priority'] = parseInt(this.issue.priority);
            }
          } else {
            _values[key] = value;
          }
        });

        await this.onUpdate(_values);
      }
    });
  };

  getSymptomAnalyserReport = async (value) => {
    try {
      this.setState({
        isFetchingSymptomAnalyserReport: true,
      });

      const response = await axios.get(
        `https://3y2exkk4k4w62lqzhcjt6prly40olsin.lambda-url.us-east-2.on.aws/?search=${value.replace(
          ' ',
          '+'
        )}`
      );
      const results = JSON.parse(response.data.result);

      if (results[0].result) {
        this.setState({
          symptomAnalyserReport: results[0]['Categories'],
        });
      } else {
        this.setState({
          symptomAnalyserReport: false,
        });
      }
    } catch (e) {
      if (!_.isEmpty(value)) AppStore.addError('Error in getting report!');

      this.setState({
        symptomAnalyserReport: false,
      });
    } finally {
      this.setState({
        isFetchingSymptomAnalyserReport: false,
      });
    }
  };

  showField(field, source) {
    let allowedFields = [];
    if (source === 'dataone') {
      allowedFields = [
        'Priority',
        'Remaining Mileage',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }

    if (['dtc', 'heavyVehicleDTC', 'lightVehicleDTC', 'DTC'].includes(source)) {
      allowedFields = [
        'Priority',
        'Description',
        'Possible Causes',
        'Symptoms',
        'Notes',
        'Explanation',
        'Failure Mode Indicator',
        'Source Address',
        'Suggested Action',
        'Engine Derate Possible',
        'Finish Route',
      ];
    }

    if (
      ['user', 'algorithm', 'routine', 'algorithm_history'].includes(source)
    ) {
      allowedFields = ['Priority', 'Description', 'Action', 'Item', 'Notes'];
    }
    if (
      ['algorithm', 'algorithm_history'].includes(source) &&
      _.get(this.issue, 'action', null) === 'Cluster Detected'
    ) {
      allowedFields = [
        'Priority',
        'Description',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }
    if (source === 'recall') {
      allowedFields = ['Priority', 'Description', 'Notes', 'Explanation'];
    }
    if (source === 'archive') {
      allowedFields = [
        'Status',
        'Source',
        'Priority',
        'Description',
        'Done Date',
        'Action',
        'Item',
        'Notes',
        'Explanation',
      ];
    }
    // let check = allowedFields.includes(field);
    return allowedFields.includes(field);
  }

  showPriceField(source) {
    if (source === 'dataone') {
      return true;
    }
    return false;
  }

  onSaveConfigService = (e) => {
    this.setState({
      isSavedConfigService: !!e,
    });
    CurrentUserStore.user.updateSettings({ defaultSavedConfigService: e });
  };

  getVMRSCode = (item) => {
    let code;

    switch (item) {
      case 'Battery':
        code = '032';
        break;
      case 'Brakes':
        code = '013';
        break;
      case 'DEF fluid':
        code = '043';
        break;
      case 'Air Flow':
        code = '041';
        break;
      default:
        code = '900';
    }

    return code;
  };

  getTroubleshooting = (troubleshooting) => {
    return _.get(troubleshooting, 'description', 'Not Available');
  };

  getVMRSText = (item) => {
    let text;

    switch (item) {
      case 'Battery':
        text = 'CRANKING SYSTEM';
        break;
      case 'Brakes':
        text = 'BRAKE SYSTEM';
        break;
      case 'DEF fluid':
        text = 'EXHAUST SYSTEM';
        break;
      case 'Air Flow':
        text = 'AIR INTAKE SYSTEM';
        break;
      default:
        text = 'OTHER';
    }

    return text;
  };

  getScoreLabel = () => {
    let { report } = this.issue;

    let formattedScoreLabel = (label) => _.join(_.split(label, '_'), ' ');

    return (
      <>
        Score
        {report.score_label && (
          <>
            {` (${formattedScoreLabel(report.score_label)}) `}
            <Tooltip title={report.score_explanation}>
              <Icon type={'question-circle-o'} />
            </Tooltip>
          </>
        )}
      </>
    );
  };

  columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'date',
      render: (data) => moment(data).format('LLL'),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (data) => (
        <Tag color={getPriorityTagColor(data)}>{getPriorityCategory(data)}</Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <Tag color={getStatusColor(data)}>{data.capitalize()}</Tag>
      ),
    },
  ];

  formItemLayout = {
    labelCol: {
      lg: { span: 5 },
      sm: { span: 7 },
      xs: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  getFormattedStatus = (status) => {
    switch (status) {
      case 'waitingForApproval':
        return 'Waiting For Invoice';
      case 'new':
        return 'Current';
      case 'upcoming':
        return 'Upcoming';
      case 'pending':
        return 'Pending';
      case 'done':
        return 'Done';
      case 'deleted':
        return 'Deleted';
      default:
        return status;
    }
  };

  getFormattedColor = (status) => {
    switch (status) {
      case 'waitingForApproval':
        return 'orange';
      case 'new':
        return 'green';
      case 'upcoming':
        return 'blue';
      case 'pending':
        return 'purple';
      case 'done':
      case 'deleted':
        return 'green';
      default:
        return 'orange';
    }
  };

  isTriggeredAtValid = (triggeredAt) => {
    return (
      !_.isNil(triggeredAt) && !_.isEmpty(triggeredAt) && _.isArray(triggeredAt)
    );
  };

  onSaveNotes = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let _values = {
          shopId: ShopStore.currentShop.id,
          userId: CurrentUserStore.user.id,
          item: this.issue.item,
          status: this.issue.status,
          source: this.issue.source,
        };

        _.each(values, (value, key) => {
          if (key === 'item') {
            if (_values['report']) _values['report'] = { testName: value };

            _values['item'] = value;
          } else if (key === 'priority') {
            if (typeof value === 'string') {
              _values['priority'] = parseInt(values.priority);
            } else {
              _values['priority'] = parseInt(this.issue.priority);
            }
          } else {
            _values[key] = value;
          }
        });

        await this.onUpdate(_values);
      }
    });
  };

  onClickSavePriority = () => {
    if (!this.state.editing) {
      return this.setState({ editing: true });
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let _values = {
          shopId: ShopStore.currentShop.id,
          userId: CurrentUserStore.user.id,
          item: this.issue.item,
          status: this.issue.status,
          source: this.issue.source,
        };

        _.each(values, (value, key) => {
          if (key === 'item') {
            if (_values['report']) _values['report'] = { testName: value };

            _values['item'] = value;
          } else if (key === 'priority') {
            if (typeof value === 'string') {
              _values['priority'] = parseInt(values.priority);
            } else {
              _values['priority'] = parseInt(this.issue.priority);
            }
          } else {
            _values[key] = value;
          }
        });

        await this.onUpdate(_values);

        this.setState({ editing: false });
      }
    });
  };

  onClickDeleteIssue = async () => {
    AppStore.addLoading('Deleting...');
    await this.issue.delete();
    AppStore.removeLoading('Deleting...');
    this.props.history.push('/issues');
  };

  parseTroubleshootingDescrition = (text) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /\b[A-Z\s\/,-]+:(.*?)(?=\b[A-Z\s\/,-]+:|$)/gs;
    let match;
    let result = [];
    const processedText = text.replaceAll('\\n', ' ');
    while ((match = regex.exec(processedText)) !== null) {
      const [fullMatch, content] = match;
      const title = fullMatch.slice(0, -content.length - 1);
      result.push({ title: title.trim(), content: content.trim() });
    }

    return result;
  };

  parseManufacturerSpecificDisclaimer(issue) {
    if (!_.get(issue, 'partialMatch')) {
      if (_.get(issue, 'engineMake')) {
        return `The meaning of this fault code is specific to this vehicle's engine, a ${_.get(
          issue,
          'engineMake'
        )} ${_.get(issue, 'engineModel')}.`;
      } else {
        return `The meaning of this fault code is specific to the this type of vehicle, a ${_.get(
          issue,
          'vehicleMake'
        )} ${_.get(issue, 'vehicleModel')}.`;
      }
    } else {
      if (_.get(issue, 'engineMake')) {
        return `The meaning of this fault code is specific to the vehicle's engine manufacturer. This vehicle's engine is a ${_.get(
          issue,
          'engineMake'
        )} ${_.get(issue, 'engineModel')}, our closest match is for a ${_.get(
          issue,
          'engineMake'
        )} ${_.get(issue, 'codeEngineModel')}.`;
      } else {
        return `The meaning of this fault code is specific to the vehicle manufacturer. This vehicle is a ${_.get(
          issue,
          'vehicleMake'
        )} ${_.get(issue, 'vehicleModel')}, our closest match is a ${_.get(
          issue,
          'vehicleMake'
        )} ${_.get(issue, 'codeVehicleModel')}.`;
      }
    }
  }

  render() {
    let { editing } = this.state;

    const { getFieldDecorator } = this.props.form;

    if (_.isNil(this.issue)) {
      return (
        <>
          <h1>Issue Profile</h1>
          <Spin tip="Loading..." />
        </>
      );
    }

    let {
      id,
      priority,
      source,
      status,
      car,
      notes,
      item,
      triggeredAt,
      vmrs,
    } = this.issue;

    let { troubleshooting, loadingTroubleshooting } = this.state;

    if (['heavyVehicleDTC', 'lightVehicleDTC', 'dtc'].indexOf(source) !== -1) {
      source = 'DTC';
    }

    let troubleshootingSections = this.parseTroubleshootingDescrition(
      this.getTroubleshooting(troubleshooting)
    );

    const hasFMI =
      this.issue.getFailureModeIndicator() &&
      !String(this.issue.getFailureModeIndicator()).includes(
        'No available failure mode indicator'
      );

    const isIssueComplete = this.issue.status === 'done';

    const hasExplanation =
      this.issue.getExplanation() &&
      !String(this.issue.getExplanation()).includes('No available explanation');

    const hasChatGpt = !!this.issue.gptDescription;

    const dieselRepairPunchoutUrl = 'https://app-repair.diesellaptops.com/#/';

    const createDieselRepairPunchoutUrl = (moduleName) => {
      const { getItem, car } = this.issue;
      return `${dieselRepairPunchoutUrl}${moduleName}?src=pits&VIN=${car.vin
        }&code=${getItem()}`;
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={18}>
            <Title style={{ fontSize: '24px' }}>{this.issue.getName()}</Title>
            <p style={{ fontSize: '14px' }}>
              Vehicle: <Link to={`/car/${car.id}`}>{car.name || car.vin}</Link>
              <Text style={{ marginLeft: 10 }}>
                Current Mileage:{' '}
                {`${Math.round(calcMileage(car.mileageTotal)).toReadable()} ${CurrentUserStore.user.displayedOdometer
                  }`}
              </Text>
            </p>
            <PriorityWrapper>
              {!_.isNil(priority) && editing ? (
                <PriorityUpdateForm onSubmit={this.onSubmit}>
                  <Form.Item>
                    {getFieldDecorator('priority', {
                      initialValue: (
                        <Tag color={IssueStore.data.get(id).priorityTagColor}>
                          {IssueStore.data.get(id).priorityCategory}
                        </Tag>
                      ),
                      rules: [
                        {
                          required: true,
                          message: 'Please select your priority!',
                        },
                      ],
                    })(
                      <>
                        <p>
                          Priority:{' '}
                          <span>
                            <Select
                              style={{ width: 110 }}
                              onChange={(event) =>
                                this.setState({ updatedPriority: event })
                              }
                              placeholder="Priority"
                              defaultValue={
                                IssueStore.data.get(id).priorityCategory
                              }
                            >
                              <Option value="-1">
                                <Tag color="#c4c3c2">Not Valid</Tag>
                              </Option>
                              <Option value="1">
                                <Tag color="#dec431">Minor</Tag>
                              </Option>
                              <Option value="2">
                                <Tag color="#e28931">Major</Tag>
                              </Option>
                              <Option value="4">
                                <Tag color="#f92d2e">Critical</Tag>
                              </Option>
                            </Select>
                          </span>
                        </p>
                      </>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('applyToSimilarIssues', {
                      valuePropName: 'similarIssueSelected',
                      initialValue: true,
                    })(
                      <p>
                        Apply to:{' '}
                        <span>
                          <Select
                            value={this.state.applyToSimilar}
                            onChange={(event) =>
                              this.setState({ applyToSimilar: event })
                            }
                            style={{ width: 140 }}
                            placeholder="Only This Issue"
                          >
                            <Option value="none">
                              <Tag>Only This Issue</Tag>
                            </Option>
                            <Option value="m">
                              <Tag>Same Make</Tag>
                            </Option>
                            <Option value="mm">
                              <Tag>Same Make & Model</Tag>
                            </Option>
                            <Option value="mmy">
                              <Tag>Same Make, Model, Year</Tag>
                            </Option>
                            <Option value="id_car">
                              <Tag>This Vehicle</Tag>
                            </Option>
                            <Option value="entire_fleet">
                              <Tag>Entire Fleet</Tag>
                            </Option>
                          </Select>
                        </span>
                      </p>
                    )}
                  </Form.Item>
                </PriorityUpdateForm>
              ) : (
                <Tag color={IssueStore.data.get(id).priorityTagColor}>
                  {IssueStore.data.get(id).priorityCategory}
                </Tag>
              )}
              <Button
                shape="circle"
                icon={editing ? 'check' : 'edit'}
                onClick={() => this.onClickSavePriority()}
                style={{ marginRight: 10 }}
              />{' '}
              {status === 'new' && (
                <MarkAsCompleteBtn
                  issue={this.issue}
                  onClick={() => {
                    this.getIssueData(true, false);
                  }}
                />
              )}
              {status === 'waitingForApproval' && (
                <MarkAsCompleteBtn
                  issue={this.issue}
                  onClick={() => {
                    this.getIssueData(true, false);
                  }}
                />
              )}
              {['done', 'waitingForApproval', 'upcoming'].includes(status) && (
                <MarkAsCurrentBtn
                  issue={this.issue}
                  onClick={() => {
                    this.getIssueData(true, false);
                  }}
                />
              )}
            </PriorityWrapper>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
              <Panel
                header={
                  <PanelHeader>
                    <SectionTitle level={2}>Details</SectionTitle>
                  </PanelHeader>
                }
                key="1"
              >
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                  <Col span={7}>
                    <BlueTitle level={3}>Description</BlueTitle>
                    <PitstopText>{this.issue.getDescription()}</PitstopText>
                    <Row style={{ margin: '1rem 0 2rem' }}>
                      <Col span={24}>
                        <GoogleSearchBtn
                          icon="google"
                          issue={this.issue}
                          onClick={() => { }}
                          type="primary"
                          title="Learn more"
                          style={{ fontSize: '14px' }}
                        ></GoogleSearchBtn>
                      </Col>
                    </Row>
                    {hasExplanation ? (
                      hasChatGpt ? (
                        <>
                          <BlueTitle level={3}>Explanation</BlueTitle>
                          <PitstopText>{this.issue.gptDescription}</PitstopText>
                          <Text
                            type="secondary"
                            style={{
                              display: 'block',
                              fontSize: 13,
                              marginTop: 5,
                            }}
                          >
                            Powered by ChatGPT
                          </Text>
                        </>
                      ) : (
                        <React.Fragment>
                          <BlueTitle level={3}>Explanation</BlueTitle>
                          <PitstopText>
                            {this.issue.getExplanation()}
                          </PitstopText>
                        </React.Fragment>
                      )
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col offset={5} span={12}>
                    {isIssueComplete && (
                      <React.Fragment>
                        <BlueTitle level={3}>Feedback</BlueTitle>
                        <PitstopText>
                          {this.issue.feedback || 'N/A'}
                        </PitstopText>
                      </React.Fragment>
                    )}
                    <BlueTitle level={3}>Fault Code</BlueTitle>
                    <PitstopText>{this.issue.getItem()}</PitstopText>
                    <BlueTitle level={3}>VMRS</BlueTitle>
                    <PitstopText>
                      System -{' '}
                      {source === 'recall'
                        ? 'OTHER'
                        : vmrs
                          ? vmrs.text
                          : this.getVMRSText(item)}{' '}
                      (
                      {source === 'recall'
                        ? 900
                        : vmrs
                          ? vmrs.sys
                          : this.getVMRSCode(item)}
                      )
                    </PitstopText>
                    {hasFMI && (
                      <React.Fragment>
                        <BlueTitle level={3}>FMI</BlueTitle>
                        <PitstopText>
                          {this.issue.getFailureModeIndicator()}
                        </PitstopText>
                      </React.Fragment>
                    )}
                    <BlueTitle level={3}>Fault Code Trigger Count</BlueTitle>
                    <PitstopText>
                      {triggeredAt ? triggeredAt.length : 0} times
                    </PitstopText>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                      <Col span={7}>
                        <BlueTitle level={3}>Suggested Action</BlueTitle>
                        <PitstopText>
                          {this.issue.getSuggestedActions()}
                        </PitstopText>
                      </Col>
                      {/* <Col offset={5} span={12}>
                        <BlueTitle level={3}>Finish Route</BlueTitle>
                        <PitstopText>
                          {this.issue.getFinishRoute()
                            ? "It is safe to finish today's route"
                            : "It is not safe to finish today's route"}
                        </PitstopText>
                        <BlueTitle level={3}>Engine Derate Possible</BlueTitle>
                        <PitstopText>
                          {this.issue.getEngineDeratePossible() === true
                            ? 'Yes'
                            : 'No'}
                        </PitstopText>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                {_.get(this.issue, 'partialMatch') ? (
                  <Col span={24}>
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                      <Col span={7}>
                        <BlueTitle level={3}>Notice</BlueTitle>
                        <PitstopText>
                          {this.parseManufacturerSpecificDisclaimer(this.issue)}{' '}
                        </PitstopText>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
                {!_.isEmpty(this.state.relevantServices) ? (
                  <Row>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.history.push(
                          `/relevant-services?issueId=${this.issueId}&code=${this.issue.name}`
                        )
                      }
                    >
                      Relevant Service Records
                    </Button>
                  </Row>
                ) : null}
              </Panel>
            </Collapse>
          </Col>
        </Row>
        {loadingTroubleshooting && (
          <Row style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Skeleton active title paragraph />
            </Col>
          </Row>
        )}
        {!loadingTroubleshooting &&
          troubleshootingSections &&
          troubleshootingSections.length ? (
          <Row style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Collapse expandIconPosition={'right'} activeKey={['1']}>
                <Panel
                  header={
                    <PanelHeader>
                      <SectionTitle level={2}>Troubleshooting</SectionTitle>
                    </PanelHeader>
                  }
                  key="1"
                >
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                    {troubleshootingSections.map((section, i) => (
                      <React.Fragment key={i}>
                        <Col span={24}>
                          <BlueTitleCapitalized level={3}>
                            {_.chain(section.title)
                              .toLower()
                              .value()}
                          </BlueTitleCapitalized>
                          <PitstopText>{section.content}</PitstopText>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Collapse expandIconPosition={'right'} activeKey={['1']}>
              <Panel
                header={
                  <PanelHeader>
                    <SectionTitle level={2}>
                      <img
                        alt="Diesel Laptops Logo"
                        src="https://app-repair.diesellaptops.com/icons/favicon-128x128.png"
                        style={{
                          width: '30px',
                          marginRight: '1rem',
                        }}
                      />
                      Learn More About This Fault Code on Diesel Laptops{' '}
                    </SectionTitle>
                  </PanelHeader>
                }
                key="1"
              >
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                  <Col span={24}>
                    <Button
                      type="link"
                      target="_blank"
                      href={createDieselRepairPunchoutUrl('truck-fault-codes')}
                    >
                      <Icon type="link" />
                      Fault Code Info
                    </Button>
                    <Button
                      type="link"
                      target="_blank"
                      href={createDieselRepairPunchoutUrl('diesel-docs')}
                    >
                      <Icon type="link" />
                      Repair Documentation
                    </Button>
                    <Button
                      type="link"
                      target="_blank"
                      href={createDieselRepairPunchoutUrl('diesel-wiring')}
                    >
                      <Icon type="link" />
                      Wiring Diagrams
                    </Button>
                    <Button
                      type="link"
                      target="_blank"
                      href={createDieselRepairPunchoutUrl('truck-labor-times')}
                    >
                      <Icon type="link" />
                      Labor Time Guides
                    </Button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Collapse expandIconPosition={'right'}>
              <Panel
                header={
                  <PanelHeader>
                    <SectionTitle level={2}>Fault Code History</SectionTitle>
                  </PanelHeader>
                }
                key="1"
              >
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                  <Col span={24}>
                    <Table
                      loading={_.isNil(triggeredAt)}
                      dataSource={(() => {
                        return _.map(triggeredAt, (val, i) => ({
                          key: i,
                          time: val,
                        }));
                      })()}
                      columns={[
                        {
                          title: 'Time',
                          dataIndex: 'time',
                          key: 'time',
                          sorter: (a, b) => a > b,
                          render: (item) =>
                            moment
                              .tz(item, CurrentUserStore.user.settings.timezone)
                              .format('ll HH:mm:ss:SSS'),
                        },
                      ]}
                      pagination={{ pageSize: 5 }}
                    />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SectionTitle level={2}>Notes</SectionTitle>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
              <Col span={24}>
                <StyledForm onSubmit={this.onSubmit}>
                  <Form.Item>
                    {getFieldDecorator('applyToSimilarIssues', {
                      valuePropName: 'similarIssueSelected',
                      initialValue: true,
                    })(
                      <p>
                        Apply to{' '}
                        <span>
                          <Select
                            value={this.state.applyToSimilar}
                            onChange={(event) =>
                              this.setState({ applyToSimilar: event })
                            }
                            style={{ width: 140 }}
                            placeholder="Priority"
                          >
                            <Option value="none">
                              <Tag color="#c4c3c2">Only this issue</Tag>
                            </Option>
                            <Option value="m">
                              <Tag color="#c4c3c2">Same Make</Tag>
                            </Option>
                            <Option value="mm">
                              <Tag color="#c4c3c2">Same Make, Model</Tag>
                            </Option>
                            <Option value="mmy">
                              <Tag color="#c4c3c2">Same Make, Model, Year</Tag>
                            </Option>
                            <Option value="id_car">
                              <Tag color="#c4c3c2">This Vehicle</Tag>
                            </Option>
                            <Option value="entire_fleet">
                              <Tag color="#dec431">Entire Fleet</Tag>
                            </Option>
                          </Select>
                        </span>
                      </p>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('notes', {
                      initialValue:
                        notes ||
                        'Here you can leave a note that will appear next time this issue occurs.',
                    })(
                      <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength="600"
                        onChange={(value) =>
                          this.setState({
                            currentNotesBoxLength: value.target.value.length,
                          })
                        }
                      />
                    )}
                    <p>{this.state.currentNotesBoxLength}/600</p>
                    <PitstopButton onClick={this.onSaveNotes}>
                      Save
                    </PitstopButton>
                  </Form.Item>
                </StyledForm>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const IssueProfilePage = Form.create({ name: 'register' })(
  observer(WrappedIssueProfilePage)
);

export default withRouter(IssueProfilePage);
