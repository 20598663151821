import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Form, notification, Spin } from 'antd';
import _ from 'lodash';

import OktaAuthService from './OktaAuthService';
import OktaLogo from 'v3/assets/okta-logo.png';

import { CurrentUserStore, ShopStore } from 'stores';
import { ErrorField } from 'v3/components/pitstop';

const messages = [
  "Scan work orders instantly with Pitstop AI.",
  "Catch issues early. Save time now.",
  "Smarter maintenance starts here today.",
  "Optimize fault priorities in real-time.",
  "Discover root causes with Pitstop AI insights.",
  "Save money and keep fleets moving.",
  "Digitize, automate, and stay ahead.",
  "Invite your team to smarter workflows.",
  "Streamline maintenance with Pitstop AI.",
  "Your Pitstop AI assistant is ready to help.",
];

const getRandomMessage = () => {
  return messages[Math.floor(Math.random() * messages.length)];
};

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;

  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    color: #8c8c8c;
    margin: 0;
  }
`;

const DashLine = styled.div`
  width: 100%;
  border-top: 2px dashed #ccc;
  opacity: 0.5;
  margin: 1rem 0;
`;

const LoginButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  color: #ffffff;
  border-radius: 5px;
  height: 3rem;
  min-width: 8rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2vh;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const OktaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: white;
  border-radius: 5px;
  height: 3rem;
  min-width: 17rem;
  font-weight: bold;
  font-size: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  img {
    margin-left: 8px;
    height: 24px;
  }

  &:hover {
    background: #f3f3f3;
  }
`;

class LoginForm extends Component {
  static propTypes = {
    isUserLogin: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  state = {
    email: '',
    password: '',
    isSubmitted: false,
    randomMessage: getRandomMessage(),
    apiError: null, // To store API error message
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, apiError: null });
  };

  getError = (type, value) => {
    if (_.isEmpty(value)) {
      return <ErrorField>{`${type} is required!`}</ErrorField>;
    }
    // Check if the input is meant to be an email
    if (type === 'email') {
      // Regex for validating email format
      const emailRegex = /^\S+@\S+\.\S+$/;
      // Allow usernames without special characters or spaces, or a valid email
      if (!emailRegex.test(value) && !/^[a-zA-Z0-9_.-]+$/.test(value)) {
        return <ErrorField>Enter a valid username or email address</ErrorField>;
      }
    }
    if (type === 'password' && value.length < 6) {
      return <ErrorField>Password must be at least 6 characters</ErrorField>;
    }
    return null;
  };


  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { email, password } = this.state;

    if (this.getError('email', email) || this.getError('password', password)) return;

    this.props.isUserLogin(true);

    notification.open({
      key: 'login',
      description: (
        <>
          <Spin size="large" style={{ marginRight: '0.6rem' }} />
          Logging you into Pitstop dashboard...
        </>
      ),
      duration: 0,
    });

    try {
      ShopStore.reset();
      await CurrentUserStore.login(email.trim(), password.trim());
      await CurrentUserStore.getCurrentUser();
    } catch (err) {
      // Handle the case where email/password combo doesn't exist
      this.setState({
        apiError:
          "The email and password combination you entered doesn't match any account. Please try again or register a new account.",
      });
      notification.error({ message: 'Error', description: err.message });
    } finally {
      notification.close('login');
      this.props.isUserLogin(false);
    }
  };

  handleOktaLogin = async () => {
    try {
      const authUrl = await OktaAuthService.getAuthorizationUrl();
      window.location.href = authUrl;
    } catch (error) {
      notification.error({ message: 'Error generating Okta authorization URL' });
    }
  };

  render() {
    const { email, password, isSubmitted, randomMessage, apiError } = this.state;

    return (
      <Container>
        <h1>Welcome Back!</h1>
        <p>{randomMessage}</p>
        <Input
          name="email"
          placeholder="Work email (e.g., name@company.com)"
          onChange={this.onChange}
        />
        {isSubmitted && this.getError('email', email)}
        <Input
          name="password"
          type="password"
          placeholder="Password (min. 6 characters)"
          onChange={this.onChange}
        />
        {isSubmitted && this.getError('password', password)}
        {apiError && <ErrorField>{apiError}</ErrorField>}
        <LoginButton onClick={this.onSubmit}>Login</LoginButton>
        <Link to="/reset-password" style={{ marginTop: '1rem', color: '#02103d', fontWeight: '600' }}>
          Forgot password?
        </Link>
        <DashLine />
        <OktaButton onClick={this.handleOktaLogin}>
          <span>Continue with</span>
          <img src={OktaLogo} alt="Okta" />
        </OktaButton>
      </Container>
    );
  }
}

export default withRouter(LoginForm);
