import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
  Col,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Dropdown,
  Menu,
  Button,
} from 'antd';

import { customFleets, PitstopButton, PitstopModal } from 'shared';
import { Cancel, Split } from 'components/Icons';

import WeeklyMaintenanceEmail from 'assets/weekly_maintenance_email.png';

import { AppStore, CurrentUserStore, ShopStore } from 'stores';

import DealershipChooser from 'components/DealershipChooser';
import UsersTable from './UsersTable';
// import AddUsersModal from './AddUsersModal';
// import EmailReportModal from './modals/EmailReportModal';
import UserNotificationsModal from './modals/UserNotificationsModal';
// import VehicleIssueAlertModal from './modals/VehicleIssueAlertModal';
import PMNotificationsModal from './modals/PMNotificationsModal';
import WorkOrderModal from './modals/WorkOrderModal';
import AddVehicleIssueAlertModal from './modals/AddVehicleIssueAlertModal';

import AddUserModal from './modals/AddUserModal';

import AddEmailReportModal from './modals/AddEmailReportModal';

const Wrapper = styled.div`
  position: relative;
`;

const GuideContainer = styled.div`
  display: flex;

  i,
  p {
    margin: auto 5px;
  }
`;

const ModalContainer = styled(PitstopModal)`
  &&& {
    .ant-modal-footer {
      display: none;
    }
  }
`;

class UsersPage extends Component {
  modalId = 'addUserModal';
  VIDEO_MODAL_ID = 'VIDEO_MODAL_ID';
  EMAIL_SAMPLE_MODAL = 'EMAIL_SAMPLE_MODAL';
  EDIT_ISSUE_ALLERT_MODAL_ID = 'EDIT_ISSUE_ALLERT_MODAL_ID';
  ADD_ISSUE_ALLERT_MODAL_ID = 'ADD_ISSUE_ALLERT_MODAL_ID';

  ADD_VEHICLE_HEALTH_REPORT_MODAL_ID = 'ADD_VEHICLE_HEALTH_REPORT_MODAL_ID';
  CHANGE_USER_NOTIFICATIONS_MODAL_ID = 'CHANGE_USER_NOTIFICATIONS_MODAL_ID';
  EDIT_VEHICLE_HEALTH_REPORT_MODAL_ID = 'EDIT_VEHICLE_HEALTH_REPORT_MODAL_ID';
  ADD_PM_NOTIFICATIONS_MODAL_ID = 'ADD_PM_NOTIFICATIONS_MODAL_ID';

  EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID =
    'EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID';

  state = {
    isWeeklyMaintenanceEmailEnabled: false,
    isPriorityAlertsEmailEnabled: false,
    isFleetSegmentEnabled: false,
    discardWeeklyMaintenanceEmail: false,
    discardPriorityAlertsEmail: false,
    discardFleetSegment: false,
    newFleetName: '',
    newFleetEmail: '',
    removeCarsFromOriginalShop: false,
    selectedUserToChangeNotifications: null,
    selectedUserNotificationTypesIds: null,
    savingUserNotifications: false,
    notificationsConfiguration: null,
    currentUserPriorityList: [],
    currentDiagnosticAlertSettings: {},
  };

  async init() {
    if (ShopStore.isAdmin()) return;
    return ShopStore.getShopNotifications(ShopStore.currentShop.id);
  }

  disposer = observe(ShopStore.currentShop, 'id', this.init);

  async componentDidMount() {
    AppStore.setSelectedSidebarKey('/users');
    this.init();
  }

  usersTable = null;

  openAddUsersModal = () => {
    AppStore.openModals.set(this.modalId, true);

    let {
      isFleetSegmentEnabled,
      discardFleetSegment,
      isWeeklyMaintenanceEmailEnabled,
      discardWeeklyMaintenanceEmail,
      isPriorityAlertsEmailEnabled,
      discardPriorityAlertsEmail,
    } = this.state;

    if (isFleetSegmentEnabled || discardFleetSegment) {
      this.setState({
        discardFleetSegment: false,
        isFleetSegmentEnabled: false,
      });
    }

    if (isWeeklyMaintenanceEmailEnabled || discardWeeklyMaintenanceEmail) {
      this.setState({
        isWeeklyMaintenanceEmailEnabled: false,
        discardWeeklyMaintenanceEmail: false,
      });
    }

    if (isPriorityAlertsEmailEnabled || discardPriorityAlertsEmail) {
      this.setState({
        isPriorityAlertsEmailEnabled: false,
        discardPriorityAlertsEmail: false,
      });
    }
  };

  onClickWeeklyMaintenanceEmail = () => {
    let {
      isFleetSegmentEnabled,
      discardFleetSegment,
      isPriorityAlertsEmailEnabled,
      discardPriorityAlertsEmail,
      isWeeklyMaintenanceEmailEnabled,
    } = this.state;

    if (isFleetSegmentEnabled) {
      this.setState({
        discardFleetSegment: true,
      });
    }

    if (isPriorityAlertsEmailEnabled) {
      this.setState({
        discardPriorityAlertsEmail: true,
      });
    }

    if (
      !isFleetSegmentEnabled &&
      !discardFleetSegment &&
      !isPriorityAlertsEmailEnabled &&
      !discardPriorityAlertsEmail
    ) {
      this.setState({
        isWeeklyMaintenanceEmailEnabled: !isWeeklyMaintenanceEmailEnabled,
      });
    }
  };

  onClickPriorityAlertsEmail = () => {
    let {
      isFleetSegmentEnabled,
      discardFleetSegment,
      isWeeklyMaintenanceEmailEnabled,
      discardWeeklyMaintenanceEmail,
      isPriorityAlertsEmailEnabled,
    } = this.state;

    if (isFleetSegmentEnabled) {
      this.setState({
        discardFleetSegment: true,
      });
    }

    if (isWeeklyMaintenanceEmailEnabled) {
      this.setState({
        discardWeeklyMaintenanceEmail: true,
      });
    }

    if (
      !isFleetSegmentEnabled &&
      !discardFleetSegment &&
      !isWeeklyMaintenanceEmailEnabled &&
      !discardWeeklyMaintenanceEmail
    ) {
      this.setState({
        isPriorityAlertsEmailEnabled: !isPriorityAlertsEmailEnabled,
      });
    }
  };

  onClickFleetSegment = () => {
    let {
      isWeeklyMaintenanceEmailEnabled,
      discardWeeklyMaintenanceEmail,
      isPriorityAlertsEmailEnabled,
      discardPriorityAlertsEmail,
      isFleetSegmentEnabled,
    } = this.state;

    if (isWeeklyMaintenanceEmailEnabled) {
      this.setState({
        discardWeeklyMaintenanceEmail: true,
      });
    }

    if (isPriorityAlertsEmailEnabled) {
      this.setState({
        discardPriorityAlertsEmail: true,
      });
    }

    if (
      !isWeeklyMaintenanceEmailEnabled &&
      !discardWeeklyMaintenanceEmail &&
      !isPriorityAlertsEmailEnabled &&
      !discardPriorityAlertsEmail
    ) {
      this.setState({
        isFleetSegmentEnabled: !isFleetSegmentEnabled,
      });
    }
  };

  getEmailVehicleHealthReportConfig = async () => {
    let shopId = ShopStore.currentShop.id;

    if (shopId !== -1) {
      await ShopStore.getEmailVehicleHealthReportConfig(shopId);
    }
  };

  getCurrentSelectedPriorities = async (shopId) => {
    try {
      if (shopId === -1) {
        return;
      }
      let data = ShopStore.shopNotifications;
      if (_.isEmpty(data?.emailConfiguration)) {
        return [];
      } else {
        if (data?.emailConfiguration[0].id_shop !== shopId) {
          AppStore.addError('Sorry! Something went wrong');
          return;
        }
        return data.emailConfiguration[0].configuration.subscribeTo;
      }
    } catch (err) {
      AppStore.addError(err.message);
    }
  };

  getCurrentSelectedPriorities = async (shopId) => {
    try {
      if (shopId === -1) {
        return;
      }

      let data = ShopStore.vehicleHealthReport;

      if (_.isEmpty(data?.emailConfiguration)) {
        let defaultSelectedPriorities = [];
        return defaultSelectedPriorities;
      } else {
        if (_.get(data, 'emailConfiguration[0].id_shop') !== shopId) {
          AppStore.addError('Sorry! Something went wrong');
          return;
        }
        return _.get(data, 'emailConfiguration[0].configuration.subscribeTo');
      }
    } catch (err) {
      AppStore.addError(err.message);
    }
  };

  sendPriorityAlertsEmailConfiguration = async (configuration) => {
    try {
      let shopId = ShopStore.currentShop.id;
      if (shopId === -1) {
        AppStore.addError('Sorry! Email cannot be sent with a shop id: -1');
        return;
      }
      let data = await ShopStore.getEmailVehicleHealthReportConfig(shopId);
      let configId = data.latest_config_id;
      let userIds = [];
      let shop_config_exists = data.emailConfiguration.length > 0;

      if (!shop_config_exists) {
        configId += 1;
      } else {
        if (data.emailConfiguration[0].id_shop !== shopId) {
          AppStore.addError('Sorry! Something went wrong');
          return;
        }
        configId = data.emailConfiguration[0].id;
      }

      try {
        AppStore.addLoading('Email configuration is being set up...');

        if (!configuration.length) {
          if (shop_config_exists) {
            await ShopStore.deleteEmailPriorityAlertsConfig(configId);
          } else {
            return;
          }
        } else {
          await ShopStore.sendEmailPriorityAlertsConfig(
            shopId,
            userIds,
            configuration,
            configId
          );
        }

        AppStore.addSuccess('Email configuration has set up successfully!');
      } finally {
        AppStore.removeLoading('Email configuration is being set up...');

        this.setState({
          isPriorityAlertsEmailEnabled: false,
        });
      }
    } catch (err) {
      AppStore.addError(err.message);
    }
  };

  sendFleetSegmentConfiguration = async (selectedCarIds, selectedUserIds) => {
    try {
      let shopId = ShopStore.currentShop.id;

      let {
        removeCarsFromOriginalShop,
        newFleetEmail,
        newFleetName,
      } = this.state;

      AppStore.addLoading('Fleet Segment is being set up...');

      await ShopStore.sendFleetSegmentConfiguration(
        shopId,
        selectedCarIds,
        selectedUserIds,
        newFleetName,
        newFleetEmail,
        removeCarsFromOriginalShop
      );

      AppStore.addSuccess('Fleet Segment has set up successfully!');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Fleet Segment is being set up...');

      this.setState({
        newFleetName: '',
        newFleetEmail: '',
        isFleetSegmentEnabled: false,
      });
    }
  };

  handleBulkEditChange = async ({ key }) => {
    const selection = parseInt(key, 10);
    switch (selection) {
      case this.bulkEdit.maintenanceReport:
        AppStore.openModals.set(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID, true);
        break;
      case this.bulkEdit.vehicleIssue:
        AppStore.openModals.set(this.ADD_ISSUE_ALLERT_MODAL_ID, true);
        break;
      case this.bulkEdit.pmAlerts:
        AppStore.openModals.set(this.ADD_PM_NOTIFICATIONS_MODAL_ID, true);
        break;
      case this.bulkEdit.workOrders:
        AppStore.openModals.set(
          this.EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID,
          true
        );
        break;
      default:
        console.log('case not handle');
    }
  };

  onClickNotification = async (user, notificationsConfiguration) => {
    const currentUserNotifications = _.filter(
      notificationsConfiguration,
      (n) =>
        n.id_user === user.id || (n.emails && n.emails.includes(user.email))
    );

    const priorityAlerts = _.filter(
      currentUserNotifications,
      (n) => n.email_report_type === 'PriorityAlerts'
    );
    const diagnosticEmails = _.filter(
      currentUserNotifications,
      (n) => n.email_report_type === 'DiagnosticEmail'
    );

    const currentPriorityList = _.chain(priorityAlerts)
      .reduce(
        (acc, current) =>
          _.union(acc, _.get(current, 'configuration.subscribeTo', [])),
        []
      )
      .map((priorityNumber) => {
        switch (priorityNumber) {
          case 0:
          case 1:
            return 'minor';
          case 2:
          case 3:
            return 'major';
          default:
            return 'critical';
        }
      })
      .uniq()
      .value();

    const currentDiagnosticAlertSettings = _.chain(diagnosticEmails)
      .reduce((acc, current) => {
        acc['weekDay'] = moment(current.send_next_email_at)
          .tz(current.timezone)
          .get('weekday');
        acc['hour'] = moment(current.send_next_email_at).tz(current.timezone);
        return acc;
      }, {})
      .value();

    const typesAndIds = currentUserNotifications.map((n) => ({
      id: n.id,
      type: n.email_report_type,
    }));

    this.setState(
      {
        selectedUserToChangeNotifications: user,
        currentUserPriorityList: currentPriorityList,
        currentDiagnosticAlertSettings: currentDiagnosticAlertSettings,
        notificationsConfiguration,
        selectedUserNotificationTypesIds: typesAndIds,
      },
      () => {
        AppStore.openModals.set(this.CHANGE_USER_NOTIFICATIONS_MODAL_ID, true);
      }
    );
  };

  onUpdateUserNotifications = async (
    user,
    {
      notificationsTypesIds,
      selectedWeekDay,
      selectedHour,
      priorityCheckedList,
    }
  ) => {
    try {
      this.setState({ savingUserNotifications: true });

      let timezone = CurrentUserStore.user.settings.timezone;
      if (_.isNil(timezone)) {
        timezone = 'America/Toronto';
      }

      // Send user data to notification server patch method to save the priority alerts
      const priorityNumberList = ShopStore.generatePriorityNumbersFromPriorityList(
        priorityCheckedList
      );
      const priorityNotificationsSettings = _.find(
        notificationsTypesIds,
        (n) => n.type === 'PriorityAlerts'
      );
      if (priorityNotificationsSettings) {
        // PATCH
        await ShopStore.updateEmailPriorityAlertsConfig(
          priorityNotificationsSettings.id,
          ShopStore.currentShop.id,
          [user.id],
          priorityNumberList
        );
      } else {
        // CREATE A NEW PRIOIRTY ALERTS NOTIFICATION
        await ShopStore.sendEmailPriorityAlertsConfig(
          ShopStore.currentShop.id,
          [user],
          moment(),
          timezone,
          priorityNumberList
        );
      }

      // Send user data to notification server patch method to save the diagnostic alerts
      if (!_.isNil(selectedWeekDay) && !_.isNil(selectedHour)) {
        const sendNextEmailAt = ShopStore.generateDateObject(
          moment(),
          selectedWeekDay,
          selectedHour
        );
        const vehicleHealthReportConfig = _.find(
          notificationsTypesIds,
          (n) => n.type === 'DiagnosticEmail'
        );
        if (vehicleHealthReportConfig) {
          // PATCH
          await ShopStore.updateEmailVehicleHealthReportConfig(
            vehicleHealthReportConfig.id,
            ShopStore.currentShop.id,
            [user.id],
            sendNextEmailAt.toDate(),
            timezone
          );
        } else {
          // CREATE A NEW DIAGNOSTIC EMAIL NOTIFICATION
          await ShopStore.sendEmailVehicleHealthReportConfig(
            ShopStore.currentShop.id,
            [user.id],
            sendNextEmailAt.toDate(),
            timezone
          );
        }
      }

      AppStore.openModals.set(this.CHANGE_USER_NOTIFICATIONS_MODAL_ID, false);
      await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
    } catch (error) {
      console.error(error);
      AppStore.addError(
        'Sorry, there was an error saving your changes. Please try again.'
      );
    } finally {
      this.setState({ savingUserNotifications: false });
    }
  };

  bulkEdit = {
    default: 0,
    maintenanceReport: 1,
    vehicleIssue: 2,
    pmAlerts: 4,
    workOrders: 3,
  };

  selectedBulkEdit = 0;

  render() {
    let shopId = ShopStore.currentShop.id;
    const currentUserId = CurrentUserStore.user.userId;

    let columns = [
      'firstName',
      'lastName',
      'email',
      'displayedRole',
      'phone',
      'totalCarsAssociated',
    ];
    if (!ShopStore.isAdmin()) {
      columns.push('vehicleHealthReport');
    }

    let {
      isWeeklyMaintenanceEmailEnabled,
      isPriorityAlertsEmailEnabled,
      isFleetSegmentEnabled,
      discardWeeklyMaintenanceEmail,
      discardFleetSegment,
      discardAddUser,
    } = this.state;

    const menu = (
      <Menu onClick={this.handleBulkEditChange}>
        <Menu.Item key={this.bulkEdit.maintenanceReport}>
          Maintenance Report Schedule
        </Menu.Item>
        <Menu.Item key={this.bulkEdit.vehicleIssue}>
          Vehicle Issue Alerts
        </Menu.Item>
        <Menu.Item key={this.bulkEdit.workOrders}>Work Orders</Menu.Item>
        <Menu.Item key={this.bulkEdit.pmAlerts}>PM Notifications</Menu.Item>
      </Menu>
    );

    return (
      <Fragment>
        <Wrapper>
          <div style={{ position: 'absolute', right: '0px' }}>
            <PitstopButton
              style={{ marginRight: 10, marginTop: -1 }}
              onClick={this.onClickFleetSegment}
            >
              {isFleetSegmentEnabled ? (
                <>
                  <Cancel /> Cancel Fleet Segment
                </>
              ) : (
                <>
                  <Split /> Enable Fleet Segment
                </>
              )}
            </PitstopButton>
            <Dropdown overlay={menu}>
              <Button style={{ marginRight: 10 }} type="primary">
                <Icon type="notification" /> Notifications <Icon type="down" />
              </Button>
            </Dropdown>

            <PitstopButton icon="plus" onClick={this.openAddUsersModal}>
              Add User
            </PitstopButton>
          </div>
          <h1>User Management</h1>
        </Wrapper>

        {/* <EmailReportModal
          modalId={this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID}
          title="Add Weekly Maintenance Report Schedule"
        /> */}

        {

          AppStore.openModals.get(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID) && (
            <AddEmailReportModal id={this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID} />
          )
        }


        <UserNotificationsModal
          modalId={this.CHANGE_USER_NOTIFICATIONS_MODAL_ID}
          title={`Change ${this.state.selectedUserToChangeNotifications
            ?.firstName ||
            this.state.selectedUserToChangeNotifications
              ?.email}'s Notifications`}
          isLoading={false}
          currentUser={this.state.selectedUserToChangeNotifications}
          notificationsConfiguration={this.state.notificationsConfiguration}
          checkedPriorityList={this.state.currentUserPriorityList}
          defaultSelectedWeekDay={
            this.state.currentDiagnosticAlertSettings.weekDay
          }
          defaultSelectedHour={this.state.currentDiagnosticAlertSettings.hour}
          notificationsTypesIds={this.state.selectedUserNotificationTypesIds}
          loading={this.state.savingUserNotifications}
          onUpdateUserNotifications={(notifications) =>
            this.onUpdateUserNotifications(
              this.state.selectedUserToChangeNotifications,
              notifications
            )
          }
        />
        {/* TODO: delete later */}
        {/* <VehicleIssueAlertModal
          modalId={this.ADD_ISSUE_ALLERT_MODAL_ID}
          title="Add Vehicle Issue Alerts"
        /> */}

        {
          AppStore.openModals.get(this.ADD_ISSUE_ALLERT_MODAL_ID) && (
            <AddVehicleIssueAlertModal id={this.ADD_ISSUE_ALLERT_MODAL_ID} />
          )
        }

        <PMNotificationsModal
          title="Add PM Notifications"
          modalId={this.ADD_PM_NOTIFICATIONS_MODAL_ID}
          onClose={() => this.togglePMNotificationsModal(false)}
        />

        <WorkOrderModal
          modalId={this.EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID}
          title="Edit Work Order Notifications"
        />

        {customFleets.gmrv.includes(shopId) && (
          <PitstopButton
            type="primary"
            onClick={() => AppStore.openModals.set(this.VIDEO_MODAL_ID, true)}
          >
            <GuideContainer>
              <Icon type="info-circle" />
              <p>User Guide</p>
            </GuideContainer>
          </PitstopButton>
        )}

        <ModalContainer
          title=""
          id={this.VIDEO_MODAL_ID}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          width="60%"
          height="50%"
          style={{ marginTop: 30 }}
          bodyStyle={{ padding: 0 }}
          onOk={() => { }}
          onCancel={() => () =>
            AppStore.openModals.set(this.VIDEO_MODAL_ID, false)}
        >
          <video width="100%" height="100%" controls muted>
            <source
              src={require('./user_management_view.mp4')}
              type="video/mp4"
            />
          </video>
        </ModalContainer>

        <DealershipChooser />

        <Modal
          visible={discardAddUser}
          onOk={() =>
            this.setState({
              discardAddUser: false,
              isUserSelectionEnabled: false,
              isFleetSegmentEnabled: false,
            })
          }
          onCancel={() =>
            this.setState({
              discardAddUser: false,
            })
          }
        >
          Do you want to discard <b>Add User</b> settings?
        </Modal>

        <Modal
          visible={discardWeeklyMaintenanceEmail}
          onOk={() =>
            this.setState({
              discardWeeklyMaintenanceEmail: false,
              isWeeklyMaintenanceEmailEnabled: false,
              isFleetSegmentEnabled: false,
              isPriorityAlertsEmailEnabled: false,
            })
          }
          onCancel={() =>
            this.setState({
              discardWeeklyMaintenanceEmail: false,
            })
          }
        >
          Do you want to discard <b>Weekly Maintenance Email</b> settings?
        </Modal>

        <Modal
          visible={discardFleetSegment}
          onOk={() =>
            this.setState({
              discardFleetSegment: false,
              isFleetSegmentEnabled: false,
              isPriorityAlertsEmailEnabled: false,
              isUserSelectionEnabled: true,
            })
          }
          onCancel={() =>
            this.setState({
              discardFleetSegment: false,
            })
          }
        >
          Do you want to discard <b>Fleet Segment</b> settings?
        </Modal>

        {isWeeklyMaintenanceEmailEnabled && (
          <div>
            <PitstopButton
              type="secondary"
              onClick={() =>
                AppStore.openModals.set(this.EMAIL_SAMPLE_MODAL, true)
              }
            >
              Show Email Sample
            </PitstopButton>
            <PitstopModal
              id={this.EMAIL_SAMPLE_MODAL}
              onOk={() => { }}
              width="780px"
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <img
                src={WeeklyMaintenanceEmail}
                alt="weekly_maintenance_email"
              />
            </PitstopModal>
          </div>
        )}

        {isFleetSegmentEnabled && (
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Col sm={4} xs={24}>
                <p style={{ marginBottom: 0 }}>
                  <b>New Fleet Name</b>
                </p>
              </Col>

              <Col sm={6} xs={24}>
                <Input
                  value={this.state.newFleetName}
                  onChange={(e) =>
                    this.setState({ newFleetName: e.target.value })
                  }
                />
              </Col>
            </Col>

            <Col span={24}>
              <Col sm={4} xs={24}>
                <p style={{ marginBottom: 0 }}>
                  <b>New Fleet Email</b>
                </p>
              </Col>

              <Col sm={6} xs={24}>
                <Input
                  value={this.state.newFleetEmail}
                  onChange={(e) =>
                    this.setState({ newFleetEmail: e.target.value })
                  }
                />
              </Col>
            </Col>

            <Col span={24}>
              <Col>
                <p>
                  <b>Do you want to remove selected cars from original shop?</b>
                </p>
              </Col>

              <Col>
                <Radio.Group
                  onChange={(e) =>
                    this.setState({
                      removeCarsFromOriginalShop: e.target.value,
                    })
                  }
                  value={this.state.removeCarsFromOriginalShop}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Col>
            </Col>
          </Row>
        )}

        <UsersTable
          ref={(ref) => (this.usersTable = ref)}
          isUserSelectionEnabled={
            isWeeklyMaintenanceEmailEnabled ||
            isPriorityAlertsEmailEnabled ||
            isFleetSegmentEnabled
          }
          sendEmailConfiguration={this.sendEmailConfiguration}
          sendPriorityAlertsEmailConfiguration={
            this.sendPriorityAlertsEmailConfiguration
          }
          getCurrentSelectedPriorities={this.getCurrentSelectedPriorities}
          isDateSelectionEnabled={isWeeklyMaintenanceEmailEnabled}
          isPrioritySelectionEnabled={isPriorityAlertsEmailEnabled}
          enableUserSelection={() => {
            if (isWeeklyMaintenanceEmailEnabled)
              this.onClickWeeklyMaintenanceEmail();

            if (isFleetSegmentEnabled) this.onClickFleetSegment();
            if (isPriorityAlertsEmailEnabled) this.onClickPriorityAlertsEmail();
          }}
          isVehicleSelectionEnabled={isFleetSegmentEnabled}
          sendFleetSegmentConfiguration={this.sendFleetSegmentConfiguration}
          columnKeys={columns}
          onClickNotification={this.onClickNotification}
        />

        {/* <AddUsersModal
          id={this.modalId}
          inviterId={currentUserId}
          shopId={shopId}
          onAddUsers={() => {
            if (this.usersTable) {
              this.usersTable.tableStore.reset();
            }
          }}
        /> */}
        {
          AppStore.openModals.get(this.modalId) && (
            <AddUserModal
              id={this.modalId}
              inviterId={currentUserId}
              shopId={shopId}
              onAddUsers={() => {
                if (this.usersTable) {
                  this.usersTable.tableStore.reset();
                }
              }}
            />
          )
        }
      </Fragment>
    );
  }
}

export default observer(UsersPage);
