import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Select,
  Button,
} from 'antd';

const { Option } = Select;

class ShopContactForm extends Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit({
          ...values,
          contactId: this.props.defaultData.id,
        });
        // reset the form
        this.props.form.resetFields();
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '1',
    })(
      <Select style={{ width: 70 }}>
        <Option value="1">+1</Option>
      </Select>,
    );

    return (
      <Form
        onSubmit={this.handleSubmit}
      >
        <Form.Item label="Name" hasFeedback>
          {getFieldDecorator('name', {
            initialValue: this.props.defaultData?.name || '',
            rules: [
              {
                required: true,
                message: 'Please input the Contact Name!',
              },
            ]
          })(<Input readOnly={this.props.disableFields.includes('name') || this.props.disableFields.includes('*')} />)}
        </Form.Item>

        <Form.Item label="E-mail" hasFeedback>
          {getFieldDecorator('email', {
            initialValue: this.props.defaultData?.email || '',
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              }
            ]
          })(<Input readOnly={this.props.disableFields.includes('email') || this.props.disableFields.includes('*')} />)}
        </Form.Item>

        <Form.Item label="Contact">
          {getFieldDecorator('salesPerson', {
            initialValue: this.props.defaultData?.salesPerson || '',
            rules: [],
          })(<Input readOnly={this.props.disableFields.includes('salesPerson') || this.props.disableFields.includes('*')} />)}
        </Form.Item>

        <Form.Item label="Phone Number">
          {getFieldDecorator('phone', {
            initialValue: this.props.defaultData?.phone || '',
          })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} readOnly={this.props.disableFields.includes('phone') || this.props.disableFields.includes('*')} />)}
        </Form.Item>

        {!this.props.hideFooter && (
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="white" onClick={() => {
              this.onCancel();
            }}>
            Cancel
          </Button>
          <Button
            loading={this.props.saving}
            style={{ marginLeft: 6 }}
            icon="save"
            type="primary"
            htmlType="submit"
          >
            Save
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }
}

ShopContactForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  defaultData: PropTypes.object,
  disableFields: PropTypes.array,
  hideFooter: PropTypes.bool,
};

ShopContactForm.defaultProps = {
  onCancel: () => { },
  onSubmit: () => { },
  saving: false,
  defaultData: {},
  disableFields: [],
  hideFooter: false,
};

const WrappedShopContactForm = Form.create({ name: 'shopContactForm' })(ShopContactForm);

export default WrappedShopContactForm;
