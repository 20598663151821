import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Popover, Button } from 'antd';
import { ServiceScheduleStore } from 'stores';

class CustomEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  render() {
    // if the event title is not Reschedule Required return the title
    const event = this.props.event;
    if (event.title.includes('Service Immediately') || event.title.includes('No Reschedule Required')) {
      return (
        <span>
          <strong>{this.props.event.title}</strong>
        </span>
      );
    }

    const bookedServiceContent = (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Button
          style={{ marginBottom: '5px' }}
          size="small"
          onClick={() => ServiceScheduleStore.onClickRescheduleRequiredEventDetails(event)}
        >
          See Details
        </Button>
      </div>
    );

    const content = (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Button
          style={{ marginBottom: '5px' }}
          size="small"
          onClick={() => ServiceScheduleStore.onClickRescheduleRequiredSuggestedRescheduleDates(event)}
        >
          See Suggested Reschedule Dates
        </Button>
        <Button
          size="small"
          onClick={() => ServiceScheduleStore.onClickRescheduleRequiredEventDetails(event)}
        >
          See Details
        </Button>
      </div>
    );
    let customContent = content;
    if (event.fake) {
      customContent = (
        <div>
          <p><strong>Technician Assigned:</strong> {event.technician}</p>
          <p><strong>Type of Service:</strong> {event.serviceType}</p>
          <p><strong>Status of Work Order:</strong> {event.workOrderStatus}</p>
          <p><strong>Date:</strong> {event.date}</p>
          <p><strong>Time:</strong> {event.time}</p>
        </div>
      );
    } else if (event.title.includes('Booked Service')) {
      customContent = bookedServiceContent;
    }
    return (
      <Popover
        title={event.fake ? 'Event Details' : 'What do you want to do?'}
        content={customContent}
        trigger="click"
      >
        <span
          style={{
            display: 'block',
            width: '100%'
          }}
        >
          <strong>{this.props.event.title}</strong>
        </span>
      </Popover>
    );
  }
}

CustomEvent.propTypes = {
  event: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(CustomEvent);