import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Spin,
  Button,
  Radio,
  Collapse,
} from 'antd';

import {
  WorkOrderStatusEnum,
  WorkOrderTypeOfServiceEnum,
} from 'stores/Classes/WorkOrderObject';
import CurrentUserStore from 'stores/CurrentUserStore';
import CarStore from 'stores/CarStore';
import { TableStore } from 'stores/abstract';
import { AppStore, ContactStore, ShopStore, WorkOrderStore } from 'stores';
import AddAssetModal from 'components/Modals/AddAssetModal';
import WarrantiesTable from 'components/Warranties/WarrantiesTable';
import WarrantyStore from 'stores/WarrantyStore';
import { Logger } from 'stores/Classes';
import FormLegend from 'components/Forms/FormLegend';
import { capitalize, isArray } from 'lodash';

import WorkOrderDetailsContext from 'containers/WorkOrder/WorkOrderDetailsContext';
import { customFleets } from 'shared';

const { Option } = Select;

const fullWidth = {
  width: '100%',
};

class WorkOrderDetailsForm extends React.Component {
  static contextType = WorkOrderDetailsContext;

  static propTypes = {
    form: PropTypes.object,
    childRef: PropTypes.func,
  };

  state = {
    cars: [],
    locations: [],
    allCars: [],
    loadingShopWorkOrderStatuses: true,
    shopWorkOrderStatuses: [],
    loadingCarData: false,
    selectedCar: undefined,
    selectedCarFromForm: undefined,
    searchingCar: false,
    assetTypeDataSource: ['Vehicle', 'Trailer', 'Other'],
    addAssetModalVisible: false,
    loadingAssetWarranties: false,
    warrantyFilters: {},
    warrantyPagination: {},
    warrantySorter: {},
    assetWarranties: [],
    vendors: [],
    preSelectedAssets: [],
  };

  carsTableStore = new TableStore();

  async componentDidMount () {
    // To disable submit button at the beginning.
    const { childRef } = this.props;
    childRef(this);

    await this.initAssets();
    await this.loadShopWorkOrderStatuses();

    this.loadShopVendors();
    this.loadShopLocations();

    if (
      this.context.state.initialFormValues &&
      this.context.state.initialFormValues.woSelectVehicle
    ) {
      // get car details
      this.setState({ searchingCar: true, loadingCarData: true });
      const car = this.state.allCars.find(
        (car) => car.id === this.context.state.initialFormValues.woSelectVehicle
      );
      this.context.onGetCarDetails(car);

      // if there is a car, get the car type and force it before setting the car
      if (car) {
        await this.props.form.setFieldsValue({
          woAssetType: _.capitalize(car.carType),
        });
      }

      this.setState(
        {
          selectedCar: this.context.state.initialFormValues.woSelectVehicle,
          // cars: [car],
          searchingCar: false,
          loadingCarData: false,
        },
        () => {
          this.onChangeFormCar(
            this.context.state.initialFormValues.woSelectVehicle
          );
        }
      );
    }
  }

  loadShopVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ vendors: [] });
        return;
      }
      const vendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ vendors });
    } catch (error) {
      Logger.error(error);
    }
  };

  loadShopLocations = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ locations: [] });
        return;
      }
      const locations = await ShopStore.getShopLocations(ShopStore.currentShop.id);
      this.setState({ locations });
    } catch (error) {
      Logger.error(error);
    }
  };

  loadAssetWarranties = async (carId) => {
    try {
      this.setState({ loadingAssetWarranties: true, assetWarranties: [] });
      const filters = {
        ...this.state.warrantyFilters,
        carIds: carId,
      };
      if (filters.status && filters.status.length) {
        filters.statuses = filters.status.join(',');
      }
      if (this.state.warrantyPagination.current) {
        const page = this.state.warrantyPagination.current;
        filters.limit = this.state.warrantyPagination.pageSize;
        filters.offset = (page - 1) * filters.limit;
      }
      if (this.state.warrantySorter.column && this.state.warrantySorter.order) {
        const field = this.state.warrantySorter.field;
        const direction =
          this.state.warrantySorter.order === 'ascend' ? '' : '-';
        filters.sortBy = `${direction}${field}`;
      }
      const { data, meta } = await WarrantyStore.getWarranties(
        ShopStore.currentShop.id,
        filters
      );
      this.setState({
        assetWarranties: data,
        warrantyPagination: {
          ...this.state.warrantyPagination,
          total: meta.total,
        },
      });
    } catch (error) {
      Logger.error(error.toString());
    } finally {
      this.setState({ loadingAssetWarranties: false });
    }
  };

  get createdByOptions () {
    return this.context.shopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  get assignedToOptions () {
    return this.context.shopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  get assetOptions () {
    const filteredCarsBasedOnAssetType = this.state.cars.filter(
      (car) =>
        (car?.carType || '').toLowerCase() ===
        (this.props.form.getFieldValue('woAssetType') || '').toLowerCase()
    );
    return filteredCarsBasedOnAssetType.map((car, index) => {
      const vinInfo = car?.carType !== 'other' ? ` - ${car?.vin}` : '';
      return (
        <Option key={index} value={car?.id}>
          {`${car?.carName ||
            car?.unitId ||
            car?.make + ' ' + car?.model + ' ' + car?.year}${vinInfo}`}
        </Option>
      );
    });
  }

  initAssets = async () => {
    this.setState({ searchingCar: true, loadingCarData: true });
    const assets = await this.getShopCars();
    this.setState({
      allCars: assets && assets.length ? [...assets] : [],
      cars: assets && assets.length ? [...assets] : [],
      selectedCar: undefined,
      searchingCar: false,
      loadingCarData: false,
    });
  };

  loadShopWorkOrderStatuses = async () => {
    try {
      this.setState({ loadingShopWorkOrderStatuses: true });
      const shopWorkOrderStatuses = await WorkOrderStore.getShopWorkOrderStatuses(ShopStore.currentShop.id);
      this.setState({
        shopWorkOrderStatuses: _.chain(shopWorkOrderStatuses)
          .get('data', [])
          .map((status) => ({
            id: status.id,
            key: status.key,
            name: status.description,
            description: status.description,
            color: status.color,
            text: status.description,
            value: status.key,
            is_default: status.is_default,
          }))
          .value()
      });
    } catch (error) {
      Logger.error(error);
      AppStore.addError('Error loading work order statuses');
    } finally {
      this.setState({ loadingShopWorkOrderStatuses: false });
    }
  };

  getShopCars = async () => {
    return CarStore.getShopCarsBasicInfoAndReturn(ShopStore.currentShop.id);
  };

  searchForCars = async (search) => {
    this.setState({ searchingCar: true });
    const cars = await this.state.allCars;
    const filteredCars = cars.filter((car) => {
      return (
        (car?.carName || '').toLowerCase().includes(search.toLowerCase()) ||
        (car?.unitId || '').toLowerCase().includes(search.toLowerCase()) ||
        (car?.vin || '').toLowerCase().includes(search.toLowerCase())
      );
    });
    this.setState({ cars: filteredCars, searchingCar: false });
  };

  onCarSearch = async (value, event) => {
    if (!value) {
      return this.setState({ cars: this.state.allCars });
    }
    this.searchForCars(value);
  };

  onCarSearchChange = (selectedCar) => {
    this.setState({ selectedCar });
    this.context.onCarSelect(selectedCar);
    const car = this.state.allCars.find((car) => car.id === selectedCar);
    this.context.onGetCarDetails(car);
  };

  onChangeFormCar = (value) => {
    this.setState(
      {
        selectedCarFromForm: value,
        preSelectedAssets: [value], // this is used to add warranty to the asset
      },
      () => {
        // load asset warranties
        this.loadAssetWarranties(value);
        this.context.onCarSelect(value);
      }
    );
  };

  onChangeAssignedTo = (value) => {
    this.context.onChangeAssignedTo(value);
  };

  getCurrentLoggedUser = () => {
    return CurrentUserStore.user.id;
  };

  onAddAssets = async (newAssets) => {
    this.setState({
      addAssetModalVisible: false,
    });
    if (newAssets.length) {
      console.log(newAssets);
      try {
        let firstAssetAddedId = null;
        for (const asset of newAssets) {
          const response = await CarStore.createGenericAsset({
            vin: asset.vin,
            source: 'user',
            assetType: asset.assetType,
            shopId: ShopStore.currentShop.id,
            licensePlate: '',
            baseMileage: asset.mileage || 0,
            unitId: asset.unitId,
            engineHours: asset.engineHours || 0,
          });
          if (!firstAssetAddedId) {
            firstAssetAddedId = isArray(response)
              ? response[0].id
              : response.id;
          }
        }
        AppStore.addSuccess('Asset added successfully');
        await this.initAssets();
        // auto select the first asset type
        await this.props.form.setFieldsValue({
          woAssetType: capitalize(newAssets[0].assetType),
        });
        // auto select the asset
        await this.props.form.setFieldsValue({
          woSelectVehicle: firstAssetAddedId,
        });
      } catch (error) {
        console.log(error);
        AppStore.addError('Error adding assets');
      }
    }
  };

  render () {
    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const rawStatusOptions = this.state.shopWorkOrderStatuses;
    const defaultStatus = rawStatusOptions.find((status) => status.is_default)
      || rawStatusOptions[0]
      || { key: 'open', value: 'Open' };

    const rawTypeOptions = Object.keys(WorkOrderTypeOfServiceEnum)
      .filter((type) => {
        return [
          'driver_identified',
          'breakdown',
          'preventive_maintenance',
          'inspection',
        ].includes(type);
      })
      .map((woRepairPriorityKey) => ({
        key: woRepairPriorityKey,
        value: WorkOrderTypeOfServiceEnum[woRepairPriorityKey],
      }));

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    const woInvoiceNumberError =
      isFieldTouched('woInvoiceNumber') && getFieldError('woInvoiceNumber');
    const woCreatedByError =
      isFieldTouched('woCreatedBy') && getFieldError('woCreatedBy');
    const woSelectVehicleError =
      isFieldTouched('woSelectVehicle') && getFieldError('woSelectVehicle');
    const woStatusError =
      isFieldTouched('woStatus') && getFieldError('woStatus');
    const woRepairTypeError =
      isFieldTouched('woRepairType') && getFieldError('woRepairType');

    return (
      <Form className="limit-size-tablets" onSubmit={this.onSave}>
        <FormLegend>Initial Details</FormLegend>

        {!customFleets.ufpFleet.includes(ShopStore.currentShop.id) ? (
          <>
            <Row gutter={[16]}>
              <Col span={8}>
                <Form.Item
                  label="WO Number:"
                  validateStatus={woInvoiceNumberError ? 'error' : ''}
                  help={woInvoiceNumberError || ''}
                >
                  {getFieldDecorator('woInvoiceNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Work order number is required!',
                      },
                    ],
                    initialValue: this.context.state.initialFormValues
                      .wOid ? this.context.state.initialFormValues
                      .wOid : this.context.state.initialFormValues
                      .woInvoiceNumber,
                  })(
                    <Input
                      prefix="#"
                      allowClear={true}
                      placeholder="#000000"
                      disabled={this.context.state.isDisabled}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Asset Type:">
                  {getFieldDecorator('woAssetType', {
                    rules: [],
                    initialValue: this.context.state.initialFormValues.woAssetType,
                  })(
                    <Select
                      style={fullWidth}
                      placeholder="Asset Type"
                      disabled={this.context.state.isDisabled}
                      onChange={() =>
                        this.props.form.setFieldsValue({ woSelectVehicle: null })
                      }
                    >
                      <Option value="Vehicle">Vehicle</Option>
                      <Option value="Trailer">Trailer</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {this.state.loadingCarData ? (
                  <Spin tip="Loading asset data..." />
                ) : (
                  <>
                    <Form.Item
                      label="Select Asset:"
                      validateStatus={woSelectVehicleError ? 'error' : ''}
                      help={woSelectVehicleError || ''}
                      style={{ marginBottom: 3 }}
                    >
                      {getFieldDecorator('woSelectVehicle', {
                        rules: [{ required: true, message: 'Asset is required!' }],
                        initialValue: this.context.state.initialFormValues
                          .woSelectVehicle,
                        onChange: this.onChangeFormCar,
                      })(
                        <Select
                          style={fullWidth}
                          onChange={this.onCarSearchChange}
                          onSearch={this.onCarSearch}
                          placeholder="Search by Unit ID"
                          showSearch
                          optionFilterProp="children"
                          filterOption={filterOption}
                          loading={this.state.searchingCar}
                          disabled={this.context.state.isDisabled}
                        >
                          {this.assetOptions}
                        </Select>
                      )}
                    </Form.Item>
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      icon="plus"
                      onClick={() => {
                        this.setState({
                          addAssetModalVisible: true,
                        });
                      }}
                    >
                      Add Asset
                    </Button>
                  </>
                )}
              </Col>
              <Col span={12}>
                <Form.Item
                  label="WO Status:"
                  validateStatus={woStatusError ? 'error' : ''}
                  help={woStatusError || ''}
                >
                  {getFieldDecorator('woStatus', {
                    initialValue: this.context.state.initialFormValues.woStatus || defaultStatus.key,
                    rules: [{ required: true, message: 'Status is required!' }],
                  })(
                    <Radio.Group
                      className='stacked-by-two'
                      style={fullWidth}
                      disabled={this.context.state.isDisabled || this.state.loadingShopWorkOrderStatuses}
                    >
                      {rawStatusOptions.map((status) => (
                        <Radio className='stack-child' key={status.key} value={status.key}>
                          {status.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Repair Type:"
                  validateStatus={woRepairTypeError ? 'error' : ''}
                  help={woRepairTypeError || ''}
                >
                  {getFieldDecorator('woRepairType', {
                    initialValue: this.context.state.initialFormValues.woRepairType,
                    rules: [
                      { required: true, message: 'Repair Type is required!' },
                    ],
                  })(
                    <Radio.Group className='stacked-by-two' style={fullWidth}>
                      {rawTypeOptions.map((type) => (
                        <Radio className='stack-child' key={type.key} value={type.key}>
                          {type.value}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col xs={12} sm={8}>
                <Form.Item
                  label="Created by:"
                  validateStatus={woCreatedByError ? 'error' : ''}
                  help={woCreatedByError || ''}
                >
                  {getFieldDecorator('woCreatedBy', {
                    rules: [{ required: true, message: 'Created by is required!' }],
                    initialValue:
                      this.context.state.initialFormValues.woCreatedBy ||
                      this.getCurrentLoggedUser(),
                  })(
                    <Select
                      style={fullWidth}
                      placeholder="Select a User"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={this.context.state.isDisabled}
                    >
                      {this.createdByOptions}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={12} sm={8}>
                <Form.Item
                  label="Assigned To:"
                >
                  {getFieldDecorator('woAssignedTo', {
                    rules: [],
                    initialValue: this.context.state.initialFormValues.woAssignedTo,
                    onChange: this.onChangeAssignedTo,
                  })(
                    <Select
                      style={fullWidth}
                      placeholder="Select a User"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={this.context.state.isDisabled}
                    >
                      {this.assignedToOptions}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label="WO Number:"
                validateStatus={woInvoiceNumberError ? 'error' : ''}
                help={woInvoiceNumberError || ''}
              >
                {getFieldDecorator('woInvoiceNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'Work order number is required!',
                    },
                  ],
                  initialValue: this.context.state.initialFormValues
                    .wOid ? this.context.state.initialFormValues
                    .wOid : this.context.state.initialFormValues
                    .woInvoiceNumber,
                })(
                  <Input
                    prefix="#"
                    allowClear={true}
                    placeholder="#000000"
                    disabled={this.context.state.isDisabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Repair Type:"
                validateStatus={woRepairTypeError ? 'error' : ''}
                help={woRepairTypeError || ''}
              >
                {getFieldDecorator('woRepairType', {
                  initialValue: this.context.state.initialFormValues.woRepairType,
                  rules: [
                    { required: true, message: 'Repair Type is required!' },
                  ],
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select Repair Type"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                  >
                    {rawTypeOptions.map((type) => (
                      <Option key={type.key} value={type.key}>
                        {type.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="WO Status:"
                validateStatus={woStatusError ? 'error' : ''}
                help={woStatusError || ''}
              >
                {getFieldDecorator('woStatus', {
                  initialValue: this.context.state.initialFormValues.woStatus || defaultStatus.key,
                  rules: [{ required: true, message: 'Status is required!' }],
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select Status"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    disabled={this.context.state.isDisabled || this.state.loadingShopWorkOrderStatuses}
                  >
                    {rawStatusOptions.map((status) => (
                      <Option key={status.key} value={status.key}>
                        {status.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Asset Type:">
                {getFieldDecorator('woAssetType', {
                  rules: [],
                  initialValue: this.context.state.initialFormValues.woAssetType,
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Asset Type"
                    disabled={this.context.state.isDisabled}
                    onChange={() =>
                      this.props.form.setFieldsValue({ woSelectVehicle: null })
                    }
                  >
                    <Option value="Vehicle">Vehicle</Option>
                    <Option value="Trailer">Trailer</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={16}>
              {this.state.loadingCarData ? (
                <Spin tip="Loading asset data..." />
              ) : (
                <>
                  <Form.Item
                    label="Select Asset:"
                    validateStatus={woSelectVehicleError ? 'error' : ''}
                    help={woSelectVehicleError || ''}
                    style={{ marginBottom: 3 }}
                  >
                    {getFieldDecorator('woSelectVehicle', {
                      rules: [{ required: true, message: 'Asset is required!' }],
                      initialValue: this.context.state.initialFormValues
                        .woSelectVehicle,
                      onChange: this.onChangeFormCar,
                    })(
                      <Select
                        style={fullWidth}
                        onChange={this.onCarSearchChange}
                        onSearch={this.onCarSearch}
                        placeholder="Search by Unit ID"
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        loading={this.state.searchingCar}
                        disabled={this.context.state.isDisabled}
                      >
                        {this.assetOptions}
                      </Select>
                    )}
                  </Form.Item>
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon="plus"
                    onClick={() => {
                      this.setState({
                        addAssetModalVisible: true,
                      });
                    }}
                  >
                    Add Asset
                  </Button>
                </>
              )}
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                label="Created by:"
                validateStatus={woCreatedByError ? 'error' : ''}
                help={woCreatedByError || ''}
              >
                {getFieldDecorator('woCreatedBy', {
                  rules: [{ required: true, message: 'Created by is required!' }],
                  initialValue:
                    this.context.state.initialFormValues.woCreatedBy ||
                    this.getCurrentLoggedUser(),
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a User"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    disabled={this.context.state.isDisabled}
                  >
                    {this.createdByOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                label="Assigned To:"
              >
                {getFieldDecorator('woAssignedTo', {
                  rules: [],
                  initialValue: this.context.state.initialFormValues.woAssignedTo,
                  onChange: this.onChangeAssignedTo,
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a User"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    disabled={this.context.state.isDisabled}
                  >
                    {this.assignedToOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Vendor:">
                <Select
                  style={fullWidth}
                  value={this.context.state.initialFormValues?.vendor_id}
                  onChange={(value) => {
                    this.context.setInitialFormValue('vendor_id', value);
                  }}
                  placeholder="Select a Vendor"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  isDisabled={this.context.state.isDisabled}
                >
                  {
                    this.context.state.shopVendors.map((vendor) => (
                      <Select.Option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Attach a Location to this Work Order:">
                <Select
                  style={fullWidth}
                  value={this.context.state.initialFormValues.locationCode}
                  placeholder="Select a Location"
                  disabled={this.context.state.isDisabled}
                  onChange={(formattedValue) => {
                    const code = formattedValue.split('-')[1];
                    // update the woInvoiceNumber field with the location code attached
                    // remove the previous location code if it exists
                    const currentWOInvoiceNumber = this.props.form.getFieldValue('woInvoiceNumber');
                    const currentWOInvoiceWithoutLocation = currentWOInvoiceNumber.indexOf('-') > -1
                      ? currentWOInvoiceNumber.split('-')[1]
                      : currentWOInvoiceNumber;
                    const newWOInvoiceNumber = `${code}-${currentWOInvoiceWithoutLocation}`;

                    this.props.form.setFieldsValue({
                      woInvoiceNumber: newWOInvoiceNumber
                    }, () => {
                      this.context.onLocationSelect(formattedValue);
                    });
                  }}
                  filterOption={(input, option) => {
                    const lowerCaseInput = input.toLowerCase();
                    const optionValue = option.props.value.toLowerCase();
                    return optionValue.includes(lowerCaseInput);
                  }}
                  showSearch
                  optionFilterProp="children"
                >
                  {this.state.locations.map((location) => (
                    <Option key={`${location.id}-${location.code}-${location.description}`} value={`${location.id}-${location.code}-${location.description}`}>
                      {location.code} - {location.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={customFleets.ufpFleet.includes(ShopStore.currentShop.id) ? 12 : 24}>
              <Form.Item label="Invoice Number:">
                {getFieldDecorator('invoiceNumberCustomer', {
                  rules: [],
                  initialValue: this.context.state.initialFormValues.invoiceNumberCustomer,
                })(
                  <Input
                    prefix="#"
                    allowClear={true}
                    placeholder="000000"
                    disabled={this.context.state.isDisabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item label="Additional Notes:">
                <Input.TextArea
                  value={this.context.state.initialFormValues.additionalNotes}
                  onChange={(e) => {
                    this.context.setInitialFormValue('additionalNotes', e.target.value);
                  }}
                  style={{ width: '100%' }}
                  placeholder="Additional Notes"
                  disabled={this.context.state.isDisabled}
                  rows={6}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* END OF UFP FLEET */}
        <Row gutter={16}>
          <Col span={24}>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <FormLegend>Warranties on this Asset</FormLegend>
              <Collapse defaultActiveKey={[]} onChange={() => { }}>
                <Collapse.Panel
                  header={`(${this.state.assetWarranties.length}) warranties`}
                  key="1"
                  disabled={this.state.loadingAssetWarranties}
                >
                  <WarrantiesTable
                    isLoading={this.state.loadingAssetWarranties}
                    data={this.state.assetWarranties}
                    assets={this.state.allCars}
                    vendors={this.state.vendors}
                    preSelectedAssets={this.state.preSelectedAssets}
                    onUpdate={() => {
                      this.loadAssetWarranties(this.state.selectedCarFromForm);
                    }}
                    onChangePagination={({ pagination, filters, sorter }) => {
                      this.setState(
                        {
                          warrantyPagination: pagination,
                          warrantyFilters: filters,
                          warrantySorter: sorter,
                        },
                        () => {
                          this.loadAssetWarranties(
                            this.state.selectedCarFromForm
                          );
                        }
                      );
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
        </Row>

        <AddAssetModal
          visible={this.state.addAssetModalVisible}
          hideVin={true}
          onOk={this.onAddAssets}
          onCancel={() => {
            this.setState({
              addAssetModalVisible: false,
            });
          }}
        />
      </Form>
    );
  }
}

const WorkOrderForm = Form.create({ name: 'work_order_form' })(
  WorkOrderDetailsForm
);

export default WorkOrderForm;
