import React, { Component } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import DocumentUploader from 'containers/WorkOrder/DocumentUploader';
import PropTypes from 'prop-types';

const StyledCard = styled(Card)`
  & {
    width: 100%;
    min-height: 110px;
  }

  &.no-title.no-border {
    .ant-card-body {
      padding: 0;
    }
  }

  .title {
    margin-bottom: 12px;
  }

  .ant-card-body {
    padding: 12px 12px 24px;
  }
`;

class AddDocumentsCard extends Component {
  state = {
    documents: this.props?.documents ? [...this.props.documents] : [],
    isRefreshedDocuments: false,
  };

  componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this);
    }
  }

  handleFileUploaded = (file, status = 'done') => {
    if (status === 'removed') {
      // if has URL, remove based on the URL
      if (file.url) {
        this.setState({
          documents: this.state.documents.filter((doc) => doc.url !== file.url),
        });

        if (this.props.onFileRemove) {
          this.props.onFileRemove(file);
        }
        return;
      }

      if (this.props.onFileRemove) {
        this.props.onFileRemove(file);
      }
      this.setState({
        documents: this.state.documents.filter((doc) => doc.uid !== file.uid),
      });
      return;
    }
    this.setState({
      documents: [...this.state.documents, file],
    });

    if (this.props.onFileUploaded) {
      this.props.onFileUploaded(file);
    }
  };

  getDocuments = () => {
    return this.state.documents;
  };

  clearDocuments = () => {
    this.setState(
      {
        documents: [],
        isRefreshedDocuments: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isRefreshedDocuments: false,
          });
        }, 100);
      }
    );
  };

  handleFileClick = (file) => {
    if (this.props.onClickFile) {
      return this.props.onClickFile(file);
    }
    window.open(file.url, '_blank');
  };

  render() {
    return (
      <StyledCard
        bordered={this.props.showBorder}
        className={
          !this.props.showTitle && !this.props.showBorder
            ? 'add-documents-card no-title no-border'
            : 'add-documents-card'
        }
      >
        {!this.state.isRefreshedDocuments ? (
          <DocumentUploader
            showTitle={this.props.showTitle}
            maxVisibleItems={this.props.maxVisibleItems}
            wo_files={{
              wo_images: [],
              wo_documents:
                this.props?.documents && this.props.documents?.length
                  ? this.props.documents
                  : [],
            }}
            onFileUploaded={(file, status) => {
              this.handleFileUploaded(file, status);
            }}
            overrideUploadProps={this.props.overrideUploadProps}
            onClickFile={this.handleFileClick}
          />
        ) : null}
      </StyledCard>
    );
  }
}

AddDocumentsCard.propTypes = {
  ref: PropTypes.object,
  onFileUploaded: PropTypes.func,
  onFileRemove: PropTypes.func,
  documents: PropTypes.array,
  overrideUploadProps: PropTypes.object,
  showTitle: PropTypes.bool,
  showBorder: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
};

AddDocumentsCard.defaultProps = {
  ref: () => {},
  onFileUploaded: () => {},
  onFileRemove: () => {},
  documents: [],
  overrideUploadProps: {},
  showTitle: true,
  showBorder: true,
  maxVisibleItems: null,
};

export default AddDocumentsCard;
