import React, { useEffect, useRef } from 'react';
import Tiff from 'tiff.js';

const WorkOrderTiffViewer = () => {
  const canvasRef = useRef(null);
  const query = new URLSearchParams(window.location.search);
  const tiffUrl = query.get('tiffUrl');

  useEffect(() => {
    if (tiffUrl) {
      fetch(tiffUrl)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const tiff = new Tiff({ buffer });
          const canvas = canvasRef.current;
          if (canvas) {
            const ctx = canvas.getContext('2d');
            const tiffCanvas = tiff.toCanvas();
            canvas.width = tiffCanvas.width;
            canvas.height = tiffCanvas.height;
            ctx.drawImage(tiffCanvas, 0, 0, canvas.width, canvas.height);
          }
        })
        .catch((err) => console.error('Failed to load TIFF:', err));
    }
  }, [tiffUrl]);

  return (
    <div style={{ width: '100vw', height: '100vh', background: '#000' }}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          margin: '0 auto',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </div>
  );
};

export default WorkOrderTiffViewer;
