import CarStore from 'stores/CarStore';
import IssueStore from 'stores/IssueStore';
import ShopStore from 'stores/ShopStore';

/**
 * Fetch car details for a given car ID.
 * @param {number} carId - The ID of the car.
 * @returns {Object|null} - The car details or null if not available.
 */
export const fetchCarDetails = async (carId) => {
  if (!carId) {
    console.error('fetchCarDetails: carId is undefined or invalid.');
    return null;
  }

  try {
    // Check if car data already exists in store
    let carData = CarStore.data.get(carId);

    if (!carData) {
      // Fetch car details if not in store
      console.log(`Fetching car details for carId: ${carId}`);
      carData = await CarStore.getCarByIdAndReturn(carId);

      if (carData) {
        // Explicitly add to store
        CarStore.data.set(carId, carData);
        console.log('Car details added to store:', carData);
      }
    }

    return carData || null;
  } catch (error) {
    console.error(`Error fetching car details for carId ${carId}:`, error);
    return null;
  }
};

/**
 * Fetch sensor data for a given car ID within a specified time range.
 * @param {number} carId - The ID of the car.
 * @param {Date} startTime - The start time for the data fetch range.
 * @param {Date} endTime - The end time for the data fetch range.
 * @param {Function} setStateCallback - A callback function to update the state in the calling component.
 */
export const fetchSensorData = async (carId, startTime, endTime, setStateCallback) => {
  // Validate input parameters
  if (!carId || !startTime || !endTime) {
    console.error('Invalid parameters for sensor data fetch');
    if (setStateCallback) {
      setStateCallback({ sensorData: null, startTime, endTime });
    }
    return;
  }

  try {
    // Ensure car data is in store
    const carData = await fetchCarDetails(carId);

    if (!carData) {
      console.warn(`No car data found for ID: ${carId}`);
      if (setStateCallback) {
        setStateCallback({ sensorData: null, startTime, endTime });
      }
      return;
    }

    // Ensure getSensorData method exists and is callable
    if (typeof carData.getSensorData !== 'function') {
      console.error('getSensorData method not available');
      if (setStateCallback) {
        setStateCallback({ sensorData: null, startTime, endTime });
      }
      return;
    }

    // Fetch sensor data
    await carData.getSensorData(startTime, endTime);

    // Extract and validate sensor data
    const sensorData = carData.pids?.data?.data || null;

    // Log and callback
    console.log('Sensor Data Fetched:', sensorData);
    if (setStateCallback) {
      setStateCallback({ sensorData, startTime, endTime });
    }

  } catch (error) {
    console.error('Error fetching sensor data:', error);
    if (setStateCallback) {
      setStateCallback({ sensorData: null, startTime, endTime });
    }
  }
};

/**
 * Generate a fallback time range (7 days prior to today).
 * @returns {Object} An object containing startTime and endTime.
 */
export const generateFallbackTimeRange = () => {
  const endTime = new Date();
  const startTime = new Date();
  startTime.setDate(endTime.getDate() - 7);
  console.log('Generated fallback time range:', { startTime, endTime });

  return { startTime, endTime };
};

/**
 * Calculate a time range based on a report date.
 * @param {Date} reportDate - The base date for the calculation.
 * @param {number} daysBefore - The number of days before the report date to include in the range.
 * @returns {Object|null} - An object with startTime and endTime or null if reportDate is invalid.
 */
export const calculateTimeRange = (reportDate, daysBefore = 7) => {
  if (!reportDate) {
    console.error('calculateTimeRange: reportDate is undefined or invalid.');
    return null;
  }

  const endTime = new Date(reportDate);
  const startTime = new Date(reportDate);
  startTime.setDate(endTime.getDate() - daysBefore);

  console.log('Calculated time range:', { startTime, endTime });
  return { startTime, endTime };
};

/**
 * Validate the availability of car data in CarStore.
 * @param {number} carId - The ID of the car.
 * @returns {boolean} - True if car data is available, false otherwise.
 */
export const isCarDataAvailable = (carId) => {
  const carData = CarStore.data.get(carId);
  const isAvailable = !!carData;
  console.log(`Car data availability for carId ${carId}:`, isAvailable);
  return isAvailable;
};

/**
 * Fetch relevant service records for a given issue.
 * @param {number} issueId - The ID of the issue.
 * @param {string} issueName - The name of the issue.
 * @param {number} shopId - The ID of the shop.
 * @returns {Array} - Relevant service records.
 */
export const fetchRelevantServices = async (issueId, issueName, shopId) => {
  if (!issueId || !issueName || !shopId) {
    console.error('fetchRelevantServices: Missing required parameters.');
    return [];
  }

  console.log('Fetching relevant services for issueId:', issueId);
  try {
    const cacheKey = `${issueId}-relevant-services`;
    if (IssueStore.data.has(cacheKey)) {
      const cachedData = IssueStore.data.get(cacheKey);
      console.log('Using cached relevant services:', cachedData);
      return cachedData;
    }

    const relevantServices = await IssueStore.getRelevantServices(issueId, issueName, shopId);
    console.log('Fetched Relevant Services:', relevantServices);
    return relevantServices;
  } catch (error) {
    console.error('Error fetching relevant services:', error);
    return [];
  }
};
