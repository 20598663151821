/**
 * EventBus is a simple event bus implementation that allows you to subscribe to events and emit events.
 * It is used to communicate between components and services.
 *
 * @example
 * EventBus.$on('WORK_ORDER_SHOP_STATUSES_UPDATED', this.loadShopWorkOrderStatusesUpdatedCb);
 * EventBus.$emit('WORK_ORDER_SHOP_STATUSES_UPDATED');
 *
 * Dont forget to unsubscribe from the event when the component is unmounted.
 * EventBus.$off('WORK_ORDER_SHOP_STATUSES_UPDATED', this.loadShopWorkOrderStatusesUpdatedCb);
 */
class _EventBus {
  constructor() {
    this.bus = {};
  }

  $off(id, callback) {
    if (!this.bus[id]) return;

    if (callback) {
      // Remove specific callback
      this.bus[id] = this.bus[id].filter(cb => cb !== callback);
      // Clean up if no callbacks left
      if (this.bus[id].length === 0) {
        delete this.bus[id];
      }
    } else {
      // Remove all callbacks for this id
      delete this.bus[id];
    }
  }

  $on(id, callback) {
    if (!this.bus[id]) {
      this.bus[id] = [];
    }
    this.bus[id].push(callback);
  }

  $emit(id, ...params) {
    if (this.bus[id]) {
      this.bus[id].forEach(callback => callback(...params));
    }
  }
}

export const EventBus = new _EventBus();
