import React, { useState, useEffect } from 'react';
import { Form, Select, message } from 'antd';
import axios from 'axios';
import exp from 'constants';

import ShopStore from '../../stores/ShopStore';
import { webServiceProvider } from 'shared';
import styled from 'styled-components';

const { Option } = Select;

const NewName = styled.div`
  color: blue;
`;

const ServiceName = ({ form, initialValues }) => {
  const shopId = ShopStore.currentShop.id;

  const predefinedOptions =
    shopId === 1369 // Only for Tim Horton's Kingston
      ? [
          'CTI - commercial trailer inspection',
          'T1 - dry service on trailers - grease job',
          'CVI - commercial vehicle inspection',
          'LMI - lite maintenance interval – dry service',
          'ME1 - wet service on medium duty equipment - straight truck',
          'DE1 - wet service on heavy duty equipment (trucks) - fluid changes (oil)',
          'Damage',
          'Other',
        ]
      : [];

  const [options, setOptions] = useState(predefinedOptions);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState(undefined);
  const [showSaveOption, setShowSaveOption] = useState(false);

  const fetchOptions = async () => {
    try {
      const response = await webServiceProvider.get(
        `v1/car/serviceName/${shopId}`
      );

      if (response?.data) {
        setFilteredOptions([
          ...predefinedOptions,
          ...(Array.isArray(response.data) ? response.data : []),
        ]);
      } else {
        setFilteredOptions([...predefinedOptions]);
      }
    } catch (error) {
      message.error('Failed to fetch service options');
    }
  };

  const saveOption = async (newOption) => {
    try {
      await webServiceProvider.post(`v1/car/serviceName`, {
        shopId,
        name: newOption,
      });
      setFilteredOptions((prev) => [...prev, newOption]);
    } catch (error) {
      message.error('Failed to save new service option');
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleSearch = (value) => {
    setInputValue(value);

    if (!value) {
      fetchOptions();
      setShowSaveOption(false);
      return;
    }

    const filtered = options.filter((opt) =>
      opt.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(filtered);

    setShowSaveOption(
      !options.some((opt) => opt.toLowerCase() === value.toLowerCase())
    );
  };

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleSelect = async (value) => {
    if (value === `save_${inputValue}`) {
      await saveOption(inputValue);
      setShowSaveOption(false);
      form.setFieldsValue({ serviceName: inputValue });
      fetchOptions();
    }
  };

  return (
    <Form.Item
      label={<span style={{ fontWeight: 'bold' }}>Service Name:</span>}
    >
      {form.getFieldDecorator('serviceName', {
        initialValue: initialValues?.action || undefined,
        rules: [{ required: true, message: 'Service name is required' }],
      })(
        <Select
          showSearch
          placeholder="Start typing to add new services..."
          onSearch={handleSearch}
          onSelect={handleSelect}
          value={inputValue || undefined}
          defaultValue={undefined}
          filterOption={false}
          onBlur={(value) => {
            if (value === `save_${inputValue}`) {
              form.setFieldsValue({ serviceName: '' });
              fetchOptions();
            }
          }}
        >
          {filteredOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
          {showSaveOption && inputValue && (
            <Option key={`save_${inputValue}`} value={`save_${inputValue}`}>
              <NewName>Save "{inputValue}"</NewName>
            </Option>
          )}
        </Select>
      )}
    </Form.Item>
  );
};

export default ServiceName;
