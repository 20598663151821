import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { PitstopModal } from 'shared';
import ContactForm from 'components/Contacts/ContactForm';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      color: #ffffff;
    }
  }
`;

const ContactFormModal = ({
  modalId,
  title,
  onCancel,
  onSubmit,
  saving,
  defaultData,
  hideFooter,
  disableFields,
  closable
}) => {
  return (
    <StyledModal
      id={modalId}
      title={title}
      footer={null}
      closable={closable}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <ContactForm
            defaultData={defaultData}
            onCancel={onCancel}
            onSubmit={onSubmit}
            saving={saving}
            disableFields={disableFields}
            hideFooter={hideFooter}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

ContactFormModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  defaultData: PropTypes.object,
  hideFooter: PropTypes.bool,
  disableFields: PropTypes.array,
  closable: PropTypes.bool,
};

ContactFormModal.defaultProps = {
  title: 'Add Vendor',
  onCancel: () => {},
  saving: false,
  defaultData: {},
  hideFooter: false,
  disableFields: [],
  closable: true,
};

export default ContactFormModal;
