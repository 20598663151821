import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import validator from "validator";
import { Row, Col, Input, notification, Spin } from "antd";

import { CurrentUserStore } from "stores";
import { ErrorField } from "v3/components/pitstop";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    color: #8c8c8c;
    margin: 0;
  }
`;

const DashLine = styled.div`
  width: 100%;
  border-top: 2px dashed #ccc;
  opacity: 0.5;
  margin: 1rem 0;
`;

const ResetButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  color: #ffffff;
  border-radius: 5px;
  height: 3rem;
  min-width: 8rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

class ResetPasswordForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    email: "",
    isSubmitted: false,
    isFinished: false,
    errorMessage: "",
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: "", // Clear error message when user starts typing
    });
  };

  validateEmail = (email) => {
    if (!email) {
      return "Email is required!";
    }
    if (!validator.isEmail(email)) {
      return "Invalid email format!";
    }
    return null;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });

    const { email } = this.state;
    const validationError = this.validateEmail(email);

    if (validationError) {
      this.setState({ errorMessage: validationError });
      return;
    }

    notification.open({
      key: "reset-password",
      description: (
        <>
          <Spin size="large" style={{ marginRight: "0.6rem" }} />
          Sending password reset link...
        </>
      ),
      duration: 0,
    });

    try {
      await CurrentUserStore.resetPassword(email);
      notification.close("reset-password");
      notification.success({
        message: "Password reset email sent!",
        description: `A reset link has been sent to ${email}. Please check your inbox.`,
      });
      this.setState({ isFinished: true });
    } catch (error) {
      notification.close("reset-password");
      notification.error({
        message: "Error",
        description: error.message || "Failed to send password reset link.",
      });
    }
  };

  render() {
    const { email, isSubmitted, isFinished, errorMessage } = this.state;

    return (
      <Container>
        <h1>Forgot Password?</h1>
        <DashLine />
        {!isFinished ? (
          <>
            <Col style={{ width: "100%", marginBottom: "1rem" }}>
              <label>Registered Email</label>
              <Input
                name="email"
                placeholder="Work email (e.g., name@company.com)"
                onChange={this.onChange}
              />
              {isSubmitted && errorMessage && (
                <ErrorField>{errorMessage}</ErrorField>
              )}
            </Col>
            <ResetButton onClick={this.onSubmit}>Reset Password</ResetButton>
          </>
        ) : (
          <p>
            Password reset link has been sent to{" "}
            <span style={{ fontWeight: "600", color: "#02103d" }}>{email}</span>.
            Please check your inbox.
          </p>
        )}
        <Link
          to="/login"
          style={{
            marginTop: "1rem",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#02103d",
          }}
        >
          Back to Login
        </Link>
      </Container>
    );
  }
}

export default withRouter(ResetPasswordForm);
