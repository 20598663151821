import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, InputNumber, Select, Button, Input } from 'antd';
import IconWithBorders from 'components/Icons/IconWithBorder';
import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';
import WorkOrderLineItemContext from '../LineItems/WorkOrderLineItemContext';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

class WorkOrderLineItemLaborItem extends Component {
  static contextType = WorkOrderLineItemContext;

  static propTypes = {
    childRef: PropTypes.func,
    labor: PropTypes.object,
    onUpdateTotal: PropTypes.func,
    onRemoveItem: PropTypes.func,
    shopUsers: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    displayIcon: PropTypes.bool,
  };

  get localShopUsers() {
    return this.props.shopUsers;
  }

  get localShopVendors() {
    return this.context.shopVendors;
  }

  get shopUsersOptions() {
    return this.localShopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  get shopVendorsOptions() {
    return this.localShopVendors.map((vendor, index) => (
      <Option key={index} value={vendor.id}>
        {vendor.name}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  get totalAmount() {
    let num = (this.props.labor?.hours || 0) * (this.props.labor?.cost || 0);
    return num.toFixed(2);
  }

  get laborValue() {
    const labourTechnician = this.props.labor?.labourTechnician;
    const shopUsers = this.localShopUsers;
    const isSelectedLaborAShopUser = shopUsers.find(
      (user) => user.id === this.props.labor.id_labour
    );
    const isSelectedLaborATechnicianInShopUsers = shopUsers.find(
      (user) => user.id === parseInt(labourTechnician?.id_technician || 0, 10)
    );
    if (labourTechnician?.source && labourTechnician?.source !== 'pitstop') {
      // not from pitstop, so show the full name
      return labourTechnician?.full_name || 'N/A';
    } else if (isSelectedLaborATechnicianInShopUsers) {
      return parseInt(labourTechnician.id_technician, 10);
    } else if (!isSelectedLaborAShopUser) {
      // not a shop user, and not a technician in shop users, return null
      return null;
    }
    // if the selected labor is a shop user, return the shop user id
    return this.props.labor.id_labour;
  }

  get laborDescription () {
    return this.props.labor.description || '';
  }

  onChangeAmount = (newVal, fieldName) => {
    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(newVal, fieldName);
    }

    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  onChangeSelectField = (value = null, fieldName) => {
    if (this.props.onUpdateLocalItem) {
      if (value === null && fieldName === 'id_labour') {
        // if cleared the select, set the labourTechnician to null,
        // to avoid the bug where the technician is not cleared
        this.props.onUpdateLocalItem(null, 'labourTechnician');
      }
      this.props.onUpdateLocalItem(value, fieldName);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  render() {
    const { screenSize } = this.context;

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col key={'name'} xs={24} sm={10}>
          <FlexRow>
            {this.props.displayIcon ? (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 10,
                }}
              >
                <IconWithBorders icon="user" />
              </FlexColum>
            ) : (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: screenSize <= 575 ? 0 : 10,
                }}
              >
                <div
                  style={{
                    width: screenSize <= 575 ? 0 : 35,
                  }}
                />
              </FlexColum>
            )}
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Select Labor</Text>
              <Select
                value={this.laborValue}
                onChange={(value) => {
                  this.onChangeSelectField(value, 'id_labour');
                }}
                placeholder="Select a Labor"
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                disabled={this.props.isDisabled}
                allowClear
              >
                {this.shopUsersOptions}
              </Select>
            </FlexColum>
          </FlexRow>
        </Col>
        <Col key={'hours'} xs={8} sm={4}>
          <Text strong>Hours</Text>
          <InputNumber
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.props.labor.hours}
            onChange={(val) => this.onChangeAmount(val, 'hours')}
            disabled={this.props.isDisabled}
            inputMode="tel"
            placeholder="0"
            onFocus={(current) => {
              // if value is 0, clear it
              if (current.target.value === '0') {
                this.onChangeAmount('', 'hours');
              }
            }}
            onBlur={(current) => {
              // Clicking off the hours text field if no data was entered, it should default back to 0
              if (current.target.value === '') {
                this.onChangeAmount(0, 'hours');
              }
            }}
          />
        </Col>
        <Col key={'cost'} xs={8} sm={4}>
          <Text strong>Rate</Text>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.props.labor.cost}
            onChange={(val) => this.onChangeAmount(val, 'cost')}
            disabled={this.props.isDisabled}
            inputMode="tel"
            placeholder="0"
            onFocus={(current) => {
              // if value is 0, clear it
              if (current.target.value === '$ 0') {
                this.onChangeAmount('', 'cost');
              }
            }}
            onBlur={(current) => {
              // Clicking off the cost text field if no data was entered, it should default back to 0
              if (current.target.value.trim() === '$') {
                this.onChangeAmount(0, 'cost');
              }
            }}
          />
        </Col>
        <Col key={'laborAmount'} xs={8} sm={6}>
          <FlexRow>
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Amount:</Text>
              <InputNumber
                value={this.totalAmount}
                disabled={true}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                inputMode="tel"
              />
            </FlexColum>
            <FlexColum
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="link"
                icon="delete"
                className="delete-danger"
                onClick={(event) => {
                  event.stopPropagation();
                  this.onRemoveItem(event, this.props.labor.key);
                }}
              />
            </FlexColum>
          </FlexRow>
        </Col>
        <Col key={'vendor'} xs={24}>
          <FlexRow>
            <FlexColum
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: screenSize <= 575 ? 0 : 10,
              }}
            >
              <div
                style={{
                  width: screenSize <= 575 ? 0 : 35,
                }}
              />
            </FlexColum>
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Select Vendor</Text>
              <Select
                value={this.props.labor.vendor}
                onChange={(value) => this.onChangeSelectField(value, 'vendor')}
                placeholder="Select a Vendor"
                disabled={this.props.isDisabled}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                allowClear
              >
                {this.shopVendorsOptions}
              </Select>
            </FlexColum>
          </FlexRow>
        </Col>
        <Col key={'description'} xs={24}>
          <FlexRow>
            <FlexColum
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: screenSize <= 575 ? 0 : 10,
              }}
            >
              <div
                style={{
                  width: screenSize <= 575 ? 0 : 35,
                }}
              />
            </FlexColum>
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Description</Text>
              <TextArea
                value={this.laborDescription}
                onChange={(e) => this.onChangeAmount(e.target.value, 'description')}
                disabled={this.props.isDisabled}
                placeholder="Enter Description"
              />
            </FlexColum>
          </FlexRow>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemLaborItem, {
  // localItem: observable,
  totalAmount: computed,
  localShopUsers: computed,
  localShopVendors: computed,
});

export default observer(WorkOrderLineItemLaborItem);
