import React, { Component } from 'react';
import styled from 'styled-components';
import { RegisterForm } from 'v3/components/forms/auth';
import { Link } from 'react-router-dom';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #02103d; /* Dark blue background */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 40px; /* Distance from top */
  left: 40px; /* Distance from left */
  z-index: 1; /* Ensures it is layered correctly */
  pointer-events: none; /* Prevent interactions */

  img {
    width: 200px; /* Adjusted smaller size */
    height: auto; /* Maintains aspect ratio */
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px; /* Adjust width */
  background: #ffffff;
  padding: 4rem;
  border-radius: 40px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
`;

const FormSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align elements horizontally */
`;

const LoginLinkWrapper = styled.div`
  margin-top: 20px;
  text-align: center;

  a {
    color: #02103d;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

class RegisterPage extends Component {
  state = {
    isRegister: false,
  };

  render() {
    const { isRegister } = this.state;

    return (
      <Container isRegister={isRegister}>
        {/* Background Logo */}
        <LogoWrapper>
          <img src={require('assets/logos/logo.svg')} alt="Pitstop Logo" />
        </LogoWrapper>

        {/* Main White Content */}
        <ContentWrapper>
          <FormSection>
            {/* Register Form */}
            <RegisterForm
              isUserRegister={(isRegister) => {
                this.setState({
                  isRegister: isRegister,
                });
              }}
            />

            {/* Login Link */}
            <LoginLinkWrapper>
              Already a member? <Link to="/login">Login</Link>
            </LoginLinkWrapper>
          </FormSection>
        </ContentWrapper>
      </Container>
    );
  }
}

export default RegisterPage;
