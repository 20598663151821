import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload } from 'antd';
import styled from 'styled-components';

const StyledUploadList = styled.div`
  .ant-upload-list-item {
    border: 1px solid #d9d9d9;
    background-color: white;
    min-height: 40px;
    border-radius: 4px;
    .ant-upload-list-item-name {
      color: #394b59;
    }
    .anticon-paper-clip {
      padding-top: 8px;
      padding-right: 5px;
    }
    .ant-upload-list-item-info {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
    .ant-upload-list-item-card-actions {
      visibility: visible;
      opacity: 1;
    }
    .ant-upload-list-item-card-actions .anticon-delete {
      font-size: 14px;
      color: gray;
      padding-right: 10px;
      padding-top: 0px;
    }

    &:hover,
    &:focus {
      background-color: white;
      border: 1px solid #d9d9d9; /* Example to remove blue border on hover/focus */
      outline: none; /* Removes the blue outline */
      /* Ensure to replicate any necessary styles here to maintain appearance */
    }

    &.ant-upload-list-item-list-type-picture {
      padding: 0;
    }
  }
`;

const UploadButton = styled(Button)`
  display: block;
  margin-top: 16px;
  .anticon-upload {
    color: gray;
  }
  color: gray;
`;

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select .ant-upload {
    margin-top: 8px;
  }
`;

const DocumentUploadList = ({ uploadProps, isDisabled, maxVisibleItems }) => (
  <StyledUploadList>
    <StyledUpload {...uploadProps}>
      <UploadButton disabled={isDisabled} icon="upload">
        Upload
      </UploadButton>
    </StyledUpload>
  </StyledUploadList>
);

DocumentUploadList.propTypes = {
  uploadProps: PropTypes.shape({
    accept: PropTypes.string,
    onRemove: PropTypes.func,
    beforeUpload: PropTypes.func,
    fileList: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    onPreview: PropTypes.func,
  }).isRequired,
  isDisabled: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
};

DocumentUploadList.defaultProps = {
  isDisabled: false,
  maxVisibleItems: null,
};

export default DocumentUploadList;
