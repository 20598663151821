import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { computed, decorate, observable, observe } from 'mobx';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';

import {
  Row,
  Col,
  Radio,
  Select,
  Spin,
  Empty,
  DatePicker,
  Icon,
  Popover,
  Button,
  Dropdown,
  Menu,
  notification,
} from 'antd';

import ReactToPrint from 'react-to-print';

import {
  CarStore,
  CurrentUserStore,
  IssueStore,
  PitstopCookie,
  ShopStore,
  TableStore,
} from 'stores';
import { IssuesTableCacheStore } from 'stores/CacheStore';

import MarkAsCompleteBtn from 'containers/IssueProfile/MarkAsCompleteBtn';
import MarkAsCurrentBtn from 'containers/IssueProfile/MarkAsCurrentBtn';
import CreateWorkOrderFromIssuesBtn from 'containers/IssueProfile/CreateWorkOrderFromIssuesBtn';

import { customFleets, PitstopTable, PitstopButton } from 'shared';
import { getColumnsBySource, convertSource } from 'components/Issue/utils';
import { queryUtils } from 'helpers';

import { SearchIssue } from 'components/Issue';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterCountBadge = styled.span`
  background-color: #ffffff;
  color: #0038b3;
  border-radius: 10px;
  padding: 0 6px;
  margin-left: 6px;
  font-size: 13px;
`;

const LabelSpan = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: #333;
  display: block;
`;

const FilterItemWrapper = styled.div`
  margin-top: 13px;
`;

const FilterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 13px;

  button {
    margin-left: 10px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  .filter-button {
    margin-right: 10px;
  }
`;

const DownloadReportWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 13px;
  z-index: 4;

  .download-button {
    margin-right: 10px;
  }
`;

const PDFButton = styled(Button)`
  color: #333;
  padding-left: 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  button {
    margin-right: 10px;
  }
`;

class IssuesTable extends Component {
  static propTypes = {
    refs: PropTypes.any,
    car: PropTypes.object,
    history: PropTypes.object,
    isEnabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    fetchData: PropTypes.func.isRequired,
    limit: PropTypes.number,
    printedComponentRef: PropTypes.any,
    downloadCsv: PropTypes.func,
    location: PropTypes.object,
    page: PropTypes.string,
    onIssuesListChange: PropTypes.func,
  };

  static defaultProps = {
    isEnabled: true,
    limit: 20,
    page: null,
  };

  getPriority = () =>
    queryUtils.getSearchParamByKey(window.location.search, 'priority');

  getSource = () =>
    queryUtils.getSearchParamByKey(window.location.search, 'source');

  state = {
    searchValue: '',
    searchOn: [],
    status: 'new',
    source: this.getSource() || 'all',
    priority: this.getPriority() || 'all',
    isReloaded: false,
    isAllToOne: true,
    startDate: null,
    endDate: null,
    allInOneSelectedIssues: [],
    processingDownloadCsv: false,
    filtersControl: [],
    filterPopoverVisible: false,

    // filter values
    filterValues: {
      status: 'new',
      source: this.getSource() || 'all',
      priority: this.getPriority() || 'all',
      isAllToOne: true,
      date: [],
    },
  };

  dtcTableStore = new TableStore();
  recallsTableStore = new TableStore();
  routineTableStore = new TableStore();
  algorithmTableStore = new TableStore();
  userTableStore = new TableStore();
  archiveTableStore = new TableStore();

  allIssuesTableStore = new TableStore();

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setIsAllToOne();
  });

  constructor (props) {
    super(props);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  get allTableStores () {
    return [
      {
        key: 'dtc',
        title: 'Engine Fault Codes Issues',
        source: 'dtc',
        store: this.dtcTableStore,
        setStore: (store) => (this.dtcTableStore = store),
      },
      {
        key: 'algorithm',
        title: 'Pitstop Suggested Issues',
        source: 'algorithm',
        store: this.algorithmTableStore,
        setStore: (store) => (this.algorithmTableStore = store),
      },
      {
        key: 'routine',
        title: 'Routine Based Services',
        source: 'routine',
        store: this.routineTableStore,
        setStore: (store) => (this.routineTableStore = store),
      },
      {
        key: 'recall',
        title: 'Recall Services',
        source: 'recall',
        store: this.recallsTableStore,
        setStore: (store) => (this.recallsTableStore = store),
      },
      {
        key: 'user',
        title: 'User Issues',
        source: 'user',
        store: this.userTableStore,
        setStore: (store) => (this.userTableStore = store),
      },
      {
        key: 'archive',
        title: 'Service Center',
        source: 'archive',
        store: this.archiveTableStore,
        setStore: (store) => (this.archiveTableStore = store),
      },
    ];
  }

  get issueStatusCache () {
    return this.state.status;
  }

  get issuePriorityCache () {
    return this.state.priority;
  }

  get issueSourceCache () {
    let source = this.state.source;

    return source;
  }

  get allTableSources () {
    let source = convertSource(this.issueSourceCache);

    if (source === 'all') return this.allTableStores;

    return _.filter(this.allTableStores, (table) => table.source === source);
  }

  get isAllStoresLoaded () {
    if (this.state.isAllToOne) return this.allIssuesTableStore.loaded;

    let tables = _.filter(
      this.allTableSources,
      (table) => table.store.loaded === true
    );

    return tables.length === this.allTableSources.length;
  }

  get isAllStoresEmpty () {
    if (this.state.isAllToOne) return _.isEmpty(this.allIssuesTableStore.data);

    let tables = _.filter(this.allTableSources, (table) =>
      _.isEmpty(table.store.data)
    );

    return tables.length === this.allTableSources.length;
  }

  componentDidMount () {
    if (typeof this.props.refs === 'function') {
      this.props.refs(this);
    }
    this.setIsAllToOne();
    const queryParameters = queryString.parse(this.props.location.search);

    if (!_.isNil(queryParameters.issueStatusFilter)) {
      this.setState({ status: queryParameters.issueStatusFilter });
    }

    if (!_.isNil(queryParameters.source)) {
      this.setState({ source: queryParameters.source });
    }

    // load filters from local storage
    const filters = localStorage.getItem('issuesFilters');
    if (filters) {
      this.setState({ filterValues: JSON.parse(filters) }, () => {
        this.applyFilters();
      });
    }

    //Workaround for Ferrovial defaulting to isAllToOne
    this.onCheck({ target: { checked: true } });
  }

  componentWillUnmount () {
    this.disposer();
  }

  setIsAllToOne = () => {
    if (_.includes(customFleets.renzi, ShopStore.currentShop.id)) {
      this.setState(
        {
          isAllToOne: true,
        },
        () => PitstopCookie.set('isAllToOne', true)
      );
    }
  };

  getDataBySource = (source) => {
    let { isAllToOne } = this.state;
    let table = _.filter(
      this.allTableStores,
      (table) => table.source === source
    )[0];

    let data = isAllToOne ? this.allIssuesTableStore.data : table.store.data;

    return _.map(data, (id) => {
      let issue = IssueStore.data.get(id);

      if (!issue) return;

      return {
        ...issue,
        key: id,
        name: issue.getName(),
        priorityTagColor: issue.priorityTagColor,
        priorityCategory: issue.priorityCategory,
      };
    });
  };

  onCheck = (e) => {
    if (e.target.checked) {
      this.addFilterToControl('isAllToOne');
    } else {
      this.removeFilterFromControl('isAllToOne');
    }
    this.setState(
      {
        isAllToOne: e.target.checked,
        allInOneSelectedIssues: [],
      },
      () => {
        if (e.target.checked) {
          PitstopCookie.set('isAllToOne', true);
        } else {
          PitstopCookie.remove('isAllToOne');
        }
      }
    );
  };

  // update the filter value
  onChangeFilter = (stateKeyToChange, value) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        [stateKeyToChange]: value,
      },
    });
  };

  applyFilters = () => {
    const { filterValues } = this.state;
    const { status, source, priority, date } = filterValues;
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('issueStatusFilter', status);
    currentUrlParams.set('source', source);
    this.props.history.push(
      window.location.pathname + '?' + currentUrlParams.toString()
    );

    const newFiltersControl = [];
    if (status !== 'new') {
      newFiltersControl.push('status');
    }
    if (source !== 'all') {
      newFiltersControl.push('source');
    }
    if (priority !== 'all') {
      newFiltersControl.push('priority');
    }
    if (date && date.length) {
      newFiltersControl.push('date');
    }

    this.setState(
      {
        status,
        source: _.isNil(source) ? 'all' : source,
        priority,
        startDate:
          date && date.length
            ? moment
              .tz(date[0], CurrentUserStore.user.settings.timezone)
              .toISOString()
            : null,
        endDate:
          date && date.length
            ? moment
              .tz(date[1], CurrentUserStore.user.settings.timezone)
              .toISOString()
            : null,
        allInOneSelectedIssues: [],
        filterPopoverVisible: false,
        filtersControl: newFiltersControl,
      },
      () => {
        // remove or add item to filter control
        if (this.state.status === 'new') {
          this.removeFilterFromControl('status');
        } else {
          this.addFilterToControl('status');
        }
        if (this.state.source === 'all') {
          this.removeFilterFromControl('source');
        } else {
          this.addFilterToControl('source');
        }
        if (this.state.priority === 'all') {
          this.removeFilterFromControl('priority');
        } else {
          this.addFilterToControl('priority');
        }
        if (_.isEmpty(date)) {
          this.removeFilterFromControl('date');
        } else {
          this.addFilterToControl('date');
        }
        IssuesTableCacheStore.setData('source', this.state.source);
        IssuesTableCacheStore.setData('priority', this.state.priority);
        IssuesTableCacheStore.setData('status', this.state.status);

        // save filters to local storage
        localStorage.setItem('issuesFilters', JSON.stringify(this.state.filterValues));
      }
    );
  };

  clearFilters = () => {
    this.setState({
      searchValue: '',
      status: 'new',
      source: 'all',
      priority: 'all',
      startDate: null,
      endDate: null,
      filtersControl: [],
      allInOneSelectedIssues: [],
      filterPopoverVisible: false,

      filterValues: {
        status: 'new',
        source: 'all',
        priority: 'all',
        date: [],
      },
    });
  };

  onSelectIssues = (selectedIssues, table) => {
    if (!table.selectedIssues) {
      table.selectedIssues = [];
    }
    // remove from current selectedIssues those that are not selected
    table.selectedIssues = _.filter(table.selectedIssues, (issueId) =>
      _.find(selectedIssues, (i) => i === issueId)
    );
    table.selectedIssues = table.selectedIssues.concat(selectedIssues);
    // remove duplicates
    table.selectedIssues = _.uniq(table.selectedIssues);
    this.forceUpdate();
  };

  onSelectIssuesAllInOneTable = (selectedIssues) => {
    this.setState({
      allInOneSelectedIssues: _.chain(this.state.allInOneSelectedIssues)
        .filter((issueId) => _.find(selectedIssues, (i) => i === issueId))
        .concat(selectedIssues)
        .uniq()
        .value(),
    });
    // this.forceUpdate();
  };

  hydrateSelectedIssues = (selectedIssuesIds, dataBySource) => {
    const hydratedIssues = _.filter(dataBySource, (issue) =>
      _.includes(selectedIssuesIds, issue.key)
    );
    return hydratedIssues;
  };

  async downloadCsv () {
    let params = {
      searchValue: this.state.searchValue,
      status: this.issueStatusCache,
    };
    if (this.issueSourceCache !== 'all') {
      params.source = convertSource(this.issueSourceCache);
    }
    if (this.issuePriorityCache !== 'all') {
      params.priority = this.issuePriorityCache;
    }
    if (this.issueSourceCache === 'dtc') {
      params.includeHistory = true;
    }
    try {
      this.setState({ processingDownloadCsv: true });
      await this.props.downloadCsv(
        params,
        this.state.startDate,
        this.state.endDate
      );
      this.setState({ processingDownloadCsv: false });
    } catch (e) {
      this.setState({ processingDownloadCsv: false });
    }
  }

  addFilterToControl = (type) => {
    let { filtersControl } = this.state;
    filtersControl = _.uniq([...filtersControl, type]);
    this.setState({ filtersControl });
  };

  removeFilterFromControl = (type) => {
    let { filtersControl } = this.state;
    filtersControl = _.filter(filtersControl, (filter) => filter !== type);
    this.setState({ filtersControl });
  };

  handlePopoverVisibleChange = (visible) => {
    this.setState({
      filterPopoverVisible: visible,
    });
  };

  handleDownloadClick = ({ key }) => {
    if (key === 'csv') {
      this.downloadCsv();
    }
  };

  refreshVehiclesList = () => {
    // just send to props if list has changed
    if (this.props.onIssuesListChange) {
      this.props.onIssuesListChange();
    }
  };

  openCreatedWorkOrderDetails = (workOrder) => {
    this.props.history.push(`/work-order-desktop/${workOrder.id}/edit`);
  };

  render () {
    let {
      searchValue,
      isAllToOne,
      startDate,
      endDate,
      filterValues,
    } = this.state;
    let { isEnabled } = this.props;

    const selectedIssues = _.get(this.state, 'allInOneSelectedIssues', []);
    const allInOneRowSelectionSettings = {
      selectedRowKeys: selectedIssues,
      onChange: this.onSelectIssuesAllInOneTable,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Issues',
          onSelect: this.onSelectIssuesAllInOneTable,
        },
      ],
    };
    return (
      <Row gutter={[24, 24]}>
        <Col span={24} ref={(el) => (this.printedComponentRef = el)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <SearchWrapper>
                <Popover
                  placement="bottomLeft"
                  visible={this.state.filterPopoverVisible}
                  onVisibleChange={this.handlePopoverVisibleChange}
                  content={
                    <div>
                      <div>
                        <LabelSpan>Status</LabelSpan>
                        <RadioGroup
                          onChange={(e) =>
                            this.onChangeFilter('status', e.target.value)
                          }
                          value={this.state.filterValues.status}
                        >
                          <RadioButton value="waitingForApproval">
                            Waiting For Invoice
                          </RadioButton>
                          <RadioButton value="new">Current</RadioButton>
                          <RadioButton value="upcoming">Upcoming</RadioButton>
                          <RadioButton value="done">Done</RadioButton>
                        </RadioGroup>
                      </div>

                      <FilterItemWrapper>
                        <LabelSpan>Filter by a Source</LabelSpan>
                        <Select
                          showSearch
                          placeholder="Filter by a Source"
                          style={{ width: '100%' }}
                          onChange={(val) => this.onChangeFilter('source', val)}
                          value={this.state.filterValues.source}
                        >
                          <Option value="all">All</Option>
                          <Option value="faultCode">
                            Only Engine Fault Code
                          </Option>
                          <Option value="pitstopSuggested">
                            Only Pitstop Suggested
                          </Option>
                          <Option value="routine">
                            Only Routine Based Services{' '}
                          </Option>
                          <Option value="recall">Only Recalls</Option>
                          <Option value="user">Reported by User</Option>
                          <Option value="archive">Service Center</Option>
                        </Select>
                      </FilterItemWrapper>

                      <FilterItemWrapper>
                        <LabelSpan>Filter by Priority</LabelSpan>
                        <Select
                          showSearch
                          placeholder="Filter by Priority"
                          style={{ width: '100%' }}
                          onChange={(val) =>
                            this.onChangeFilter('priority', val)
                          }
                          value={this.state.filterValues.priority}
                        >
                          <Option value="all">All</Option>
                          <Option value="critical">Only Critical</Option>
                          <Option value="major">Only Major</Option>
                          <Option value="minor">Only Minor</Option>
                        </Select>
                      </FilterItemWrapper>

                      {this.props.history.location.pathname === '/issues' && (
                        <FilterItemWrapper>
                          <LabelSpan>Filter by Date</LabelSpan>
                          <RangePicker
                            style={{ width: '100%' }}
                            value={
                              filterValues.date.length > 0
                                ? [
                                  moment(filterValues.date[0]).tz(
                                    CurrentUserStore.user.settings.timezone
                                  ),
                                  moment(filterValues.date[1]).tz(
                                    CurrentUserStore.user.settings.timezone
                                  ),
                                ]
                                : []
                            }
                            onChange={(date) =>
                              this.onChangeFilter('date', date)
                            }
                          />
                        </FilterItemWrapper>
                      )}

                      <FilterActions>
                        <Button onClick={this.clearFilters}>Clear</Button>
                        <Button type="primary" onClick={this.applyFilters}>
                          Apply
                        </Button>
                      </FilterActions>
                    </div>
                  }
                  trigger="click"
                >
                  <Button
                    type="primary"
                    icon="filter"
                    className="filter-button"
                  >
                    Filters
                    {this.state.filtersControl.length > 0 ? (
                      <FilterCountBadge>
                        {this.state.filtersControl.length}
                      </FilterCountBadge>
                    ) : (
                      <></>
                    )}
                  </Button>
                </Popover>
                <SearchIssue
                  car={this.props.car}
                  onSearch={(val) => {
                    this.setState({
                      searchOn: val.searchOn,
                      searchValue: val.searchValue,
                    });
                  }}
                  showDownloadButton={this.props.car ? true : false}
                />
              </SearchWrapper>
            </Col>
          </Row>

          {this.props.history.location.pathname === '/issues' &&
            !ShopStore.isAdmin() && (
              <DownloadReportWrapper>
                {/* <ReactToPrint
                  trigger={() => {
                    return <PitstopButton>Download PDF report</PitstopButton>;
                  }}
                  content={() => this.printedComponentRef}
                />
                <PitstopButton className="dowload-csv" loading={this.state.processingDownloadCsv} onClick={this.downloadCsv}>Download CSV</PitstopButton> */}
                <Dropdown
                  overlay={
                    <Menu onClick={this.handleDownloadClick}>
                      <Menu.Item key={'pdf'}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <PDFButton type="link">PDF Format</PDFButton>
                            );
                          }}
                          content={() => this.printedComponentRef}
                        />
                      </Menu.Item>
                      <Menu.Item key={'csv'}>CSV Format</Menu.Item>
                    </Menu>
                  }
                >
                  <PitstopButton ghost>
                    <Icon type="download" />
                    Download Issues
                  </PitstopButton>
                </Dropdown>
              </DownloadReportWrapper>
            )}

          {CarStore.demo === false ? (
            <Empty />
          ) : (
            <>
              {isEnabled && (
                <>
                  {!this.isAllStoresLoaded && (
                    <Row>
                      <Spin size="large" tip="Getting data..." />
                    </Row>
                  )}

                  <Row hidden={!this.isAllStoresLoaded} gutter={[16, 16]}>
                    {this.issueSourceCache === 'all' &&
                      this.isAllStoresEmpty && (
                        <Empty description="You have no issues" />
                      )}

                    {isAllToOne ? (
                      <>
                        <Col
                          hidden={!this.allIssuesTableStore.loaded}
                          span={24}
                        >
                          <TitleWrapper>
                            {this.issueStatusCache === 'new' ? (
                              <ActionsWrapper>
                                <MarkAsCompleteBtn
                                  issues={this.hydrateSelectedIssues(
                                    selectedIssues,
                                    this.getDataBySource(this.issueSourceCache)
                                  )}
                                  onClick={() => {
                                    this.setState({
                                      allInOneSelectedIssues: [],
                                    });
                                    this.refreshVehiclesList();
                                  }}
                                  title={`Mark Selected as Complete (${selectedIssues.length})`}
                                  type="primary"
                                  shouldDisableButton={!selectedIssues.length}
                                />
                                {this.props.page === 'carPage' ? (
                                  <CreateWorkOrderFromIssuesBtn
                                    issues={this.hydrateSelectedIssues(
                                      selectedIssues,
                                      this.getDataBySource(
                                        this.issueSourceCache
                                      )
                                    )}
                                    onFinish={(workOrderCreated) => {
                                      this.setState({
                                        allInOneSelectedIssues: [],
                                      });

                                      const jsxMessage = !isEmpty(
                                        workOrderCreated
                                      ) ? (
                                        <div>
                                          The Work Order was created{' '}
                                          <Button
                                            style={{
                                              padding: 0,
                                            }}
                                            type="link"
                                            onClick={() => {
                                              this.openCreatedWorkOrderDetails(
                                                workOrderCreated
                                              );
                                            }}
                                          >
                                            Click Here
                                          </Button>{' '}
                                          to view the details and to make edits
                                        </div>
                                      ) : (
                                        <div>
                                          You can always click on the work order
                                          for more details and to make edits
                                        </div>
                                      );

                                      // if work order was created, show a different message
                                      notification.success({
                                        message: 'Work orders saved',
                                        key: 'orderUpdated',
                                        description: jsxMessage,
                                      });
                                    }}
                                    title={`Create Work Order (${selectedIssues.length})`}
                                    shouldDisableButton={!selectedIssues.length}
                                  />
                                ) : (
                                  <></>
                                )}
                              </ActionsWrapper>
                            ) : this.issueStatusCache ===
                              'waitingForApproval' ? (
                              <>
                                <MarkAsCompleteBtn
                                  issues={this.hydrateSelectedIssues(
                                    selectedIssues,
                                    this.getDataBySource(this.issueSourceCache)
                                  )}
                                  onClick={() => {
                                    this.setState({
                                      allInOneSelectedIssues: [],
                                    });
                                    this.refreshVehiclesList();
                                  }}
                                  title={`Mark Selected as Complete (${selectedIssues.length})`}
                                  type="primary"
                                  shouldDisableButton={!selectedIssues.length}
                                />
                                <MarkAsCurrentBtn
                                  issues={this.hydrateSelectedIssues(
                                    selectedIssues,
                                    this.getDataBySource(this.issueSourceCache)
                                  )}
                                  onClick={() => {
                                    this.setState({
                                      allInOneSelectedIssues: [],
                                    });
                                    this.refreshVehiclesList();
                                  }}
                                  title={`Move Selected to Current (${selectedIssues.length})`}
                                  type="primary"
                                  shouldDisableButton={!selectedIssues.length}
                                />
                              </>
                            ) : (
                              <MarkAsCurrentBtn
                                issues={this.hydrateSelectedIssues(
                                  selectedIssues,
                                  this.getDataBySource(this.issueSourceCache)
                                )}
                                onClick={() => {
                                  this.setState({
                                    allInOneSelectedIssues: [],
                                  });
                                  this.refreshVehiclesList();
                                }}
                                title={`Move Selected to Current (${selectedIssues.length})`}
                                type="primary"
                                shouldDisableButton={!selectedIssues.length}
                              />
                            )}
                          </TitleWrapper>
                          <PitstopTable
                            id={`${this.props.id}-${this.issueSourceCache}-${this.issueStatusCache}-${this.issuePriorityCache}-issues`}
                            data={this.getDataBySource(this.issueSourceCache)}
                            limit={
                              this.allIssuesTableStore.loaded
                                ? this.allIssuesTableStore.limit
                                : _.includes(
                                  customFleets.renzi,
                                  ShopStore.currentShop.id
                                )
                                  ? 10
                                  : this.props.limit
                            }
                            rowKey="id"
                            columns={getColumnsBySource(
                              this.issueSourceCache,
                              this.props.page
                            )}
                            pagination={{
                              showSizeChanger: true,
                              pageSizeOptions: ['5', '10', '25', '50', '100'],
                            }}
                            components={{
                              header: {
                                cell: CustomHeaderCell,
                              },
                            }}
                            searchValue={searchValue}
                            timeRange={{ startDate, endDate }}
                            fetchData={(params, store) => {
                              let _params = {
                                ...params,
                                includeHistory: this.issueSourceCache === 'dtc',
                                filter: {
                                  ...params.filter,
                                  searchValue,
                                  status: this.issueStatusCache,
                                  ...(this.issueSourceCache !== 'all' && {
                                    source: convertSource(
                                      this.issueSourceCache
                                    ),
                                  }),
                                  ...(this.issuePriorityCache === 'all'
                                    ? delete store.filter['priority']
                                    : { priority: this.issuePriorityCache }),
                                },
                                sort: params.sort || '-priority',
                              };

                              if (this.issueSourceCache === 'dtc') {
                                _params.includeHistory = true;
                              }

                              this.props.fetchData(
                                {
                                  ..._params,
                                },
                                store,
                                startDate,
                                endDate
                              );
                            }}
                            getTableStore={(tableStore) =>
                              (this.allIssuesTableStore = tableStore)
                            }
                            onRowClick={(record) => {
                              if (
                                record.source === 'dtc' ||
                                record.source === 'spn'
                              ) {
                                return (
                                  record.id &&
                                  this.props.history.push(
                                    `/issues/${record.id}/v2`
                                  )
                                );
                              }
                              return (
                                record.id &&
                                this.props.history.push(`/issues/${record.id}`)
                              );
                            }}
                            rowSelection={allInOneRowSelectionSettings || null}
                            scroll={{ x: 240 }}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        {_.map(this.allTableSources, (table) => {
                          let tableId = `${this.props.id}-${table.source}-${this.issueStatusCache}-${this.issuePriorityCache}-issues`;

                          const selectedIssues = table?.selectedIssues || [];
                          const rowSelectionSettings = {
                            selectedRowKeys: selectedIssues,
                            onChange: (selectedKeys) =>
                              this.onSelectIssues(selectedKeys, table),
                            hideDefaultSelections: true,
                            selections: [
                              {
                                key: 'all-data',
                                text: 'Select All Issues',
                                onSelect: (selectedKeys) =>
                                  this.onSelectIssues(selectedKeys, table),
                              },
                            ],
                          };

                          return (
                            <Col
                              hidden={
                                convertSource(this.issueSourceCache) !==
                                table.source &&
                                table.store.loaded &&
                                _.isEmpty(this.getDataBySource(table.source))
                              }
                              key={table.source}
                              span={24}
                            >
                              <TitleWrapper>
                                <h5>{table.title}</h5>
                                {this.issueStatusCache === 'new' ? (
                                  <ActionsWrapper>
                                    <MarkAsCompleteBtn
                                      issues={this.hydrateSelectedIssues(
                                        selectedIssues,
                                        this.getDataBySource(table.source)
                                      )}
                                      onClick={() => {
                                        this.setState({
                                          allInOneSelectedIssues: [],
                                        });
                                        this.refreshVehiclesList();
                                      }}
                                      title={`Mark Selected as Complete (${selectedIssues.length})`}
                                      type="primary"
                                      shouldDisableButton={
                                        !selectedIssues.length
                                      }
                                    />
                                    {this.props.page === 'carPage' ? (
                                      <CreateWorkOrderFromIssuesBtn
                                        issues={this.hydrateSelectedIssues(
                                          selectedIssues,
                                          this.getDataBySource(this.source)
                                        )}
                                        onFinish={() => {
                                          this.setState({
                                            allInOneSelectedIssues: [],
                                          });
                                        }}
                                        title={`Create Work Order (${selectedIssues.length})`}
                                        type="secondary"
                                        shouldDisableButton={
                                          !selectedIssues.length
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </ActionsWrapper>
                                ) : this.issueStatusCache ===
                                  'waitingForApproval' ? (
                                  <>
                                    <MarkAsCompleteBtn
                                      issues={this.hydrateSelectedIssues(
                                        selectedIssues,
                                        this.getDataBySource(table.source)
                                      )}
                                      onClick={() => {
                                        this.setState({
                                          allInOneSelectedIssues: [],
                                        });
                                        this.refreshVehiclesList();
                                      }}
                                      title={`Mark Selected as Complete (${selectedIssues.length})`}
                                      type="primary"
                                      shouldDisableButton={
                                        !selectedIssues.length
                                      }
                                    />
                                    <MarkAsCurrentBtn
                                      issues={this.hydrateSelectedIssues(
                                        selectedIssues,
                                        this.getDataBySource(table.source)
                                      )}
                                      onClick={() => {
                                        this.setState({
                                          allInOneSelectedIssues: [],
                                        });
                                        this.refreshVehiclesList();
                                      }}
                                      title={`Move Selected to Current (${selectedIssues.length})`}
                                      type="primary"
                                      shouldDisableButton={
                                        !selectedIssues.length
                                      }
                                    />
                                  </>
                                ) : (
                                  <MarkAsCurrentBtn
                                    issues={this.hydrateSelectedIssues(
                                      selectedIssues,
                                      this.getDataBySource(table.source)
                                    )}
                                    onClick={() => {
                                      this.setState({
                                        allInOneSelectedIssues: [],
                                      });
                                      this.refreshVehiclesList();
                                    }}
                                    title={`Move Selected to Current (${selectedIssues.length})`}
                                    type="primary"
                                    shouldDisableButton={!selectedIssues.length}
                                  />
                                )}
                              </TitleWrapper>

                              <PitstopTable
                                id={tableId}
                                data={this.getDataBySource(table.source)}
                                limit={
                                  table.store.loaded
                                    ? table.store.limit
                                    : this.props.limit
                                }
                                rowKey={(record) => record.id}
                                columns={getColumnsBySource(
                                  table.source,
                                  this.props.page
                                )}
                                pagination={{
                                  showSizeChanger: true,
                                  pageSizeOptions: [
                                    '5',
                                    '10',
                                    '25',
                                    '50',
                                    '100',
                                  ],
                                }}
                                searchValue={searchValue}
                                timeRange={{ startDate, endDate }}
                                fetchData={(params, store) => {
                                  let _params = {
                                    ...params,
                                    includeHistory: table.source === 'dtc',
                                    filter: {
                                      ...params.filter,
                                      searchValue,
                                      status: this.issueStatusCache,
                                      source: table.source,
                                      ...(this.issuePriorityCache === 'all'
                                        ? delete store.filter['priority']
                                        : {
                                          priority: this.issuePriorityCache,
                                        }),
                                    },
                                    sort: params.sort || '-priority',
                                  };

                                  if (table.source === 'dtc') {
                                    _params.includeHistory = true;
                                  }
                                  this.props.fetchData(
                                    {
                                      ..._params,
                                    },
                                    store,
                                    startDate,
                                    endDate
                                  );
                                }}
                                getTableStore={(tableStore) =>
                                  table.setStore(tableStore)
                                }
                                onRowClick={(record) => {
                                  if (
                                    record.id &&
                                    ['dtc', 'spn'].includes(record.source)
                                  ) {
                                    this.props.history.push(
                                      `/issues/${record.id}/v2`
                                    );
                                  } else if (record.id) {
                                    this.props.history.push(
                                      `/issues/${record.id}`
                                    );
                                  }
                                }}
                                rowSelection={rowSelectionSettings || null}
                              />
                            </Col>
                          );
                        })}
                      </>
                    )}
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

decorate(IssuesTable, {
  dtcTableStore: observable,
  recallsTableStore: observable,
  routineTableStore: observable,
  algorithmTableStore: observable,
  userTableStore: observable,
  archiveTableStore: observable,
  allIssuesTableStore: observable,
  issueStatusCache: computed,
  issuePriorityCache: computed,
  issueSourceCache: computed,
  allTableSources: computed,
  allTableStores: computed,
  isAllStoresLoaded: computed,
  isAllStoresEmpty: computed,
});

export default withRouter(observer(IssuesTable));
