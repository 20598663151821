import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import { Empty, Select } from 'antd';

import CarStore from 'stores/CarStore';
import CurrentUserStore from 'stores/CurrentUserStore';

import { LineGraph } from 'shared/PitstopUI/PitstopChart';

import { getPidName } from './utils';

const { Option } = Select;

const LabelStyled = styled.h3`
  text-align: center;
  margin: 0.5em 0em;
`;

class PidsGraph extends Component {
  static propTypes = {
    data: PropTypes.object,
    carId: PropTypes.number,
    shopName: PropTypes.string,
    startTime: PropTypes.instanceOf(moment),
    endTime: PropTypes.instanceOf(moment)
  };

  state = {
    graphOptions: {
      title: {
        text: 'Sensor Data'
      },
      chart: {
        type: 'line',
        zoomType: 'xy'
      },
      subtitle: {
        text: CarStore.data.get(this.props.carId).vin || ''
      },
      xAxis: {
        type: 'datetime'
      },
      tootltip: {
        shared: true
      },
      time: {
        getTimezoneOffset: timestamp => {
          var zone = CurrentUserStore.user.settings.timezone,
            timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

          return timezoneOffset;
        }
      },
      plotOptions: {
        line: {
          width: 1
        }
      },
      series: []
    },
    graphKeys: [],
    containerProps: null
  };

  async componentDidMount() {
    this.setState({
      containerProps: {
        style: {
          height: 450,
          width: document.getElementsByClassName('graphView')[0]
            ? document.getElementsByClassName('graphView')[0].offsetWidth - 10
            : '100%'
        }
      }
    });
    this.setDefaultGraphKeys();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.startTime !== prevProps.startTime ||
      this.props.endTime !== prevProps.endTime
    ) {
      this.setDefaultGraphKeys();
    }
  }

  setDefaultGraphKeys = () => {
    if (this.props.data) {
      let graphKeys = Object.keys(this.props.data);

      if (this.props.shopName === 'aisin') {
        graphKeys = _.filter(
          graphKeys,
          key => ['var57', 'var67', 'var23', 'var19'].indexOf(key) !== -1
        );
      } else {
        graphKeys = _.filter([...graphKeys], key =>
          ['Speed[km/h]', 'RPM[min-1]', '210C', '210D'].includes(key)
        );

        if (_.isEmpty(graphKeys)) {
          graphKeys = _.slice([...graphKeys], 0, 3);
        }
      }

      this.setState(
        {
          graphKeys
        },
        () => this.setSeries()
      );
    }
  };

  setSeries = () => {
    let { graphKeys, graphOptions } = this.state;

    let graphSeries = _.map([...graphKeys], key => ({
      name: getPidName(key),
      data: [..._.get(this.props.data, key)]
    }));

    this.setState({
      graphOptions: {
        ...graphOptions,
        series: [...graphSeries]
      }
    });
  };

  onChange = keys => {
    this.setState(
      {
        graphKeys: keys
      },
      () => this.setSeries()
    );
  };

  dropdownView = () => {
    let keys = Object.keys(this.props.data);
    let graphKeys = [...this.state.graphKeys];

    return (
      <Select
        mode="multiple"
        showArrow
        value={graphKeys}
        style={{ width: '100%' }}
        placeholder="Select PID Values to graph"
        onChange={this.onChange}
      >
        {_.map(keys, key => (
          <Option key={key}>{getPidName(key)}</Option>
        ))}
      </Select>
    );
  };

  sensorDataView = () => {
    let { graphOptions, containerProps } = this.state;

    if (_.isEmpty(this.props.data)) {
      return (
        <Empty description="There is no sensor data present for the time you selected. Please try another time range!" />
      );
    }

    return (
      <>
        {this.dropdownView()}
        <div className="graphView">
          <LineGraph options={graphOptions} containerProps={containerProps} />
        </div>
      </>
    );
  };

  render() {
    return <>{this.sensorDataView()}</>;
  }
}

export default observer(PidsGraph);
