import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { FormItem } from './styled';

import styled from 'styled-components';

const WrapperFormItem = styled(FormItem)`
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
`;

const FormCheckbox = React.memo(
  ({
    label = '',
    title,
    decorator,
    fieldName,
    initValue = false,
    validationRules = [],
  }) => {
    return (
      <WrapperFormItem label={label}>
        {decorator(fieldName, {
          initialValue: initValue,
          rules: validationRules,
        })(<Checkbox>{title}</Checkbox>)}
      </WrapperFormItem>
    );
  }
);

FormCheckbox.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  decorator: PropTypes.func,
  fieldName: PropTypes.string,
  initValue: PropTypes.string,
};

export default FormCheckbox;
