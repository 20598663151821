import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Row,
  Col,
  Modal,
  Typography,
  Input,
  Icon,
  Tooltip,
  Button,
} from 'antd';

import { IssueStore, ShopStore, AppStore } from 'stores';
import {
  convertSecondsToHours,
  calcMileage,
  convertDistanceValueToOriginal,
  convertHourToSeconds,
} from 'helpers/unitCalculations';

const { Text } = Typography;

const PMFeedbackModal = ({
  title,
  currentStep,
  pmsToReceiveFeedback,
  isModalVisible,
  onSave,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [mileage, setMileage] = useState('');
  const [engineHours, setEngineHours] = useState('');
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gCurrentIssue, gSetCurrentIssue] = useState(
    pmsToReceiveFeedback && pmsToReceiveFeedback.length
      ? pmsToReceiveFeedback[currentStep - 1]
      : {}
  );

  useEffect(() => {
    setIsVisible(isModalVisible);
    const currentIssue =
      pmsToReceiveFeedback && pmsToReceiveFeedback.length
        ? pmsToReceiveFeedback[currentStep - 1]
        : {};
    const carMileage = _.get(currentIssue, 'car.mileageTotal', 0);
    const carEngineHours = _.get(currentIssue, 'car.engineHours', 0);
    const currentMileage = Number(
      calcMileage(carMileage).toFixed(1)
    ).toReadable();
    const currentEngineHours = Math.round(
      carEngineHours
        ? Number(convertSecondsToHours(carEngineHours)).toFixed(1)
        : 0
    ).toReadable();
    setMileage(
      IssueStore.currentPMFeedbacksInputs &&
        IssueStore.currentPMFeedbacksInputs.length >= currentStep
        ? IssueStore.currentPMFeedbacksInputs[currentStep - 1]?.mileage
        : currentMileage
    );
    setEngineHours(
      IssueStore.currentPMFeedbacksInputs &&
        IssueStore.currentPMFeedbacksInputs.length >= currentStep
        ? IssueStore.currentPMFeedbacksInputs[currentStep - 1]?.engineHours
        : currentEngineHours
    );

    setNotes(
      IssueStore.currentPMFeedbacksInputs &&
        IssueStore.currentPMFeedbacksInputs.length >= currentStep
        ? IssueStore.currentPMFeedbacksInputs[currentStep - 1]?.notes
        : ''
    );
    gSetCurrentIssue(currentIssue);
  }, [isModalVisible, currentStep, pmsToReceiveFeedback]);

  const isLastStep = currentStep === pmsToReceiveFeedback.length;

  const hasMileage =
    _.get(gCurrentIssue, 'routineInfo.interval_mileage', null) ||
    _.get(gCurrentIssue, 'routineInfo.fixed_mileage', null);

  const hasEngineHours =
    _.get(gCurrentIssue, 'routineInfo.interval_engine_hours', null) ||
    _.get(gCurrentIssue, 'routineInfo.fixed_engine_hours', null);

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title="PM Service Details"
      footer={
        <>
          {// if step is not 1, show back button
          currentStep !== 1 && (
            <Button
              onClick={() => {
                if (!IssueStore.currentPMFeedbacksInputs[currentStep - 1]) {
                  IssueStore.currentPMFeedbacksInputs[currentStep - 1] = {
                    mileage: mileage,
                    engineHours: engineHours,
                    notes: notes,
                  };
                }

                IssueStore.updateCurrentPMFeedbacksInputs(
                  currentStep - 1,
                  'mileage',
                  mileage
                );
                IssueStore.updateCurrentPMFeedbacksInputs(
                  currentStep - 1,
                  'engineHours',
                  engineHours
                );
                IssueStore.updateCurrentPMFeedbacksInputs(
                  currentStep - 1,
                  'notes',
                  notes
                );

                IssueStore.goToPreviousStepOnFeedbackModal();
              }}
            >
              <Icon type="left" />
              Back
            </Button>
          )}
          <Button
            type="primary"
            onClick={async () => {
              // if no currentPMFeedbacksInputs on current index, create it
              if (
                IssueStore.currentPMFeedbacksInputs.length ===
                currentStep - 1
              ) {
                IssueStore.currentPMFeedbacksInputs[currentStep - 1] = {
                  mileage: mileage,
                  engineHours: engineHours,
                  notes: notes,
                };
              }

              IssueStore.updateCurrentPMFeedbacksInputs(
                currentStep - 1,
                'mileage',
                mileage
              );
              IssueStore.updateCurrentPMFeedbacksInputs(
                currentStep - 1,
                'engineHours',
                engineHours
              );
              IssueStore.updateCurrentPMFeedbacksInputs(
                currentStep - 1,
                'notes',
                notes
              );

              if (isLastStep) {
                // save the data
                setIsLoading(true);
                let counter = 0;
                for (const issue of pmsToReceiveFeedback) {
                  try {
                    // call IssueStore.updatePMService to update PM Feedback
                    const correctMileage = convertDistanceValueToOriginal(
                      IssueStore.currentPMFeedbacksInputs[counter]?.mileage
                    );
                    const correctEngineHours = convertHourToSeconds(
                      IssueStore.currentPMFeedbacksInputs[counter]?.engineHours
                    );
                    await IssueStore.updatePMService(
                      ShopStore.currentShop.id,
                      issue.id,
                      issue.routineInfo?.id,
                      {
                        service: {
                          start_mileage: correctMileage,
                          start_engine_hours: correctEngineHours,
                          notes:
                            IssueStore.currentPMFeedbacksInputs[counter]?.notes,
                          carId: issue.car.id,
                          rule_type: issue.rule_type,
                          maintenance_id: issue.maintenanceId,
                        },
                      }
                    );
                  } catch (error) {
                    console.error(error);
                    AppStore.addError(
                      'Unable to update PM service. Falling back to current car data.'
                    );
                  }
                  counter++;
                }
                setIsLoading(false);
                AppStore.addSuccess('Saved PM Feedbacks!');

                // after saving, reset the data
                IssueStore.resetPMFeedbackModalData();
                IssueStore.resetCurrentPMFeedbacksInputs();
                setIsVisible(false);

                onSave();

                return;
              }
              // go to next step
              IssueStore.goToNextStepOnFeedbackModal();
            }}
            loading={isLoading}
          >
            {isLastStep ? (
              'Save'
            ) : (
              <>
                Next
                <Icon type="right" />
              </>
            )}
          </Button>
        </>
      }
      onCancel={() => {
        IssueStore.resetPMFeedbackModalData();
        IssueStore.resetCurrentPMFeedbacksInputs();
      }}
      onOk={async () => {
        // if no currentPMFeedbacksInputs on current index, create it
        if (!IssueStore.currentPMFeedbacksInputs[currentStep - 1]) {
          IssueStore.currentPMFeedbacksInputs[currentStep - 1] = {
            mileage: mileage,
            engineHours: engineHours,
            notes: notes,
          };
        }

        IssueStore.updateCurrentPMFeedbacksInputs(
          currentStep - 1,
          'mileage',
          mileage
        );
        IssueStore.updateCurrentPMFeedbacksInputs(
          currentStep - 1,
          'engineHours',
          engineHours
        );
        IssueStore.updateCurrentPMFeedbacksInputs(
          currentStep - 1,
          'notes',
          notes
        );

        if (isLastStep) {
          // save the data
          setIsLoading(true);
          let counter = 0;
          for (const issue of pmsToReceiveFeedback) {
            try {
              // call IssueStore.updatePMService to update PM Feedback
              const correctMileage = convertDistanceValueToOriginal(
                IssueStore.currentPMFeedbacksInputs[counter]?.mileage
              );
              const correctEngineHours = convertHourToSeconds(
                IssueStore.currentPMFeedbacksInputs[counter]?.engineHours
              );
              await IssueStore.updatePMService(
                ShopStore.currentShop.id,
                issue.id,
                issue.routineInfo?.id,
                {
                  service: {
                    start_mileage: correctMileage,
                    start_engine_hours: correctEngineHours,
                    notes: IssueStore.currentPMFeedbacksInputs[counter]?.notes,
                    carId: issue.car.id,
                    rule_type: issue.rule_type,
                    maintenance_id: issue.maintenanceId,
                  },
                }
              );
            } catch (error) {
              console.error(error);
              AppStore.addError(
                'Unable to update PM service. Falling back to current car data.'
              );
            }
            counter++;
          }
          setIsLoading(false);
          AppStore.addSuccess('Saved PM Feedbacks!');

          // after saving, reset the data
          IssueStore.resetPMFeedbackModalData();
          IssueStore.resetCurrentPMFeedbacksInputs();
          setIsVisible(false);

          onSave();

          return;
        }

        // go to next step
        IssueStore.goToNextStepOnFeedbackModal();
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text strong>
            {title} ({currentStep + '/' + pmsToReceiveFeedback.length})
          </Text>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {hasMileage && (
          <Col span={12}>
            <Text htmlFor="mileage" strong>
              Mileage
            </Text>
            <Input
              id="mileage"
              placeholder="Mileage..."
              suffix={
                <Tooltip title="Edit the Mileage">
                  <Icon type="edit" style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
              value={mileage}
              onChange={(e) => {
                setMileage(_.get(e, 'target.value', ''));
              }}
            />
          </Col>
        )}
        {hasEngineHours && (
          <Col span={12}>
            <Text htmlFor="engineHours" strong>
              Engine Hours
            </Text>
            <Input
              id="engineHours"
              placeholder="Engine Hours..."
              suffix={
                <Tooltip title="Edit the Engine Hours">
                  <Icon type="edit" style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
              value={engineHours}
              onChange={(e) => {
                setEngineHours(_.get(e, 'target.value', ''));
              }}
            />
          </Col>
        )}
        <Col span={24}>
          <Text htmlFor="notes" strong>
            Notes
          </Text>
          <Input.TextArea
            id="notes"
            placeholder="Notes..."
            value={notes}
            onChange={(e) => {
              setNotes(_.get(e, 'target.value', ''));
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

PMFeedbackModal.propTypes = {
  title: PropTypes.string,
  currentStep: PropTypes.number,
  pmsToReceiveFeedback: PropTypes.any,
  isModalVisible: PropTypes.bool,
};

PMFeedbackModal.defaultProps = {
  title: '',
  currentStep: 1,
  pmsToReceiveFeedback: [],
  isModalVisible: false,
};

export default PMFeedbackModal;
