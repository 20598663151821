import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { ResetPasswordForm } from "v3/components/forms/auth";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #02103d; /* Dark blue background */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;

  img {
    width: 150px; /* Adjust size if needed */
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  padding: 4rem;
  border-radius: 40px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

class ResetPasswordPage extends Component {
  render() {
    return (
      <Container>
        {/* Logo in Top-Left Corner */}
        <LogoWrapper>
          <img src={require("assets/logos/logo.svg")} alt="Pitstop Logo" />
        </LogoWrapper>

        {/* Main Content */}
        <ContentWrapper>
          <ResetPasswordForm />
        </ContentWrapper>
      </Container>
    );
  }
}

export default ResetPasswordPage;
