import { Button, Card, Col, Row, Table, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DeviceStore from 'stores/Classes/DeviceStore';
import { ShopStore, WorkOrderStore } from 'stores';
import _ from 'lodash';
import { EventBus } from 'stores/EventBus';

const { Option } = Select;
const { Text } = Typography;

const StyledHeaderRow = styled(Row)`
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 1rem;
      margin: 0;
      color: #1137ab;
      font-weight: 600;

      span {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  .date-range-wrapper {
    span {
      font-size: 0.9rem;
      font-weight: 400;
      margin-right: 10px;
    }
  }
`;

const StyledTableRow = styled(Row)`
  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .ant-table-small {
    border: 0;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 0;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin-top: 20;
  }

  .ant-table-thead > tr > th .ant-table-header-column {
    font-weight: 600;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll {
    .link {
      color: #000000;
      font-weight: 400;
      &:hover {
        border-bottom: solid 1px #ccc;
        border-radius: 0;
      }
    }
  }

  // Style for column headers
  .ant-table-thead > tr > th {
    &.status-column {
      color: ${props => props.color || '#000000'};
    }
  }

  // Style for column cells
  .ant-table-tbody > tr > td {
    &.status-column {
      color: ${props => props.color || '#000000'};
    }
  }
`;

const RowStatusCount = styled(Row)`
  span:first-child {
    color: ${({ color }) => color || '#00000'};
    font-size: 14px;
    font-weight: 600;
  }

  span:last-child {
    color: ${({ color }) => color || '#00000'};
    font-size: 24px;
  }
`;

const WorkOrderStatusByAssignedToWidget = ({
  onClickElement,
  workOrdersGroupedByStatusAndAssignedUser,
}) => {
  const baseColumns = [
    {
      title: '',
      dataIndex: 'assignedToName',
      key: 'assignedToName',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('user', row.assignedToName, row)}
            type="link"
            className="link"
          >
            {row.assignedToName.length > 12
              ? `${row.assignedToName.substring(0, 12)}...`
              : row.assignedToName}
          </Button>
        );
      },
    }
  ];

  const [loading, setLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [assignedUser, setAssignedUser] = useState([]);
  const [currentTotal, setCurrentTotal] = useState({});
  const [shopWorkOrderStatuses, setShopWorkOrderStatuses] = useState([]);
  const [columns, setColumns] = useState(baseColumns);

  const { device } = DeviceStore;

  const workOrderStatusesUpdatedCb =  () => {
    // reload the shop statuses
    loadShopWorkOrderStatuses();
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    EventBus.$on('WORK_ORDER_SHOP_STATUSES_UPDATED', workOrderStatusesUpdatedCb);

    return () => {
      EventBus.$off('WORK_ORDER_SHOP_STATUSES_UPDATED', workOrderStatusesUpdatedCb);
    };
  }, []);

  useEffect(() => {
    loadShopWorkOrderStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopWorkOrderStatuses]);

  function onStart() {
    const list = workOrdersGroupedByStatusAndAssignedUser
      .filter((val) => !isNaN(val.assignedToId))
      .map((item) => ({
        value: item.assignedToId,
        label: item.assignedToName,
      }));

    setAssignedUser(list);
  }

  const loadShopWorkOrderStatuses = async () => {
    setLoading(true);
    const response = await WorkOrderStore.getShopWorkOrderStatuses(ShopStore.currentShop.id);
    setShopWorkOrderStatuses(_.get(response, 'data', []));
    setLoading(false);
  };

  const updateColumns = () => {
    const statusColumns = shopWorkOrderStatuses.map(status => ({
      title: status.description,
      dataIndex: status.key,
      key: status.key,
      align: 'center',
      className: `status-column`,
      onCell: () => ({
        style: {
          color: status.color
        }
      }),
      onHeaderCell: () => ({
        style: {
          color: status.color
        }
      }),
      render: (_, row) => (
        <Button
          onClick={onClickElement('status', status.key, row)}
          type="link"
          className="link"
          style={{ color: status.color }}
        >
          {row[status.key] || 0}
        </Button>
      )
    }));

    setColumns([...baseColumns, ...statusColumns]);
  };

  const RowStatus = ({ onClick }) => {
    return (
      <>
        {shopWorkOrderStatuses.map((status, index) => (
          <RowStatusCount
            type="flex"
            key={index}
            align="middle"
            justify="space-between"
            color={status.color || '#000000'}
            onClick={onClickElement('onlystatus', status.key, null)}
          >
            <Text strong>{status.name}</Text>
            <Text>{currentTotal[status.key] || 0}</Text>
          </RowStatusCount>
        ))}
      </>
    );
  };

  const onFilter = (e) => {
    setIsFiltered(true);
    if (e === 0) {
      const totals = {};
      shopWorkOrderStatuses.forEach(status => {
        totals[status.key] = workOrdersGroupedByStatusAndAssignedUser.reduce(
          (sum, item) => sum + (item[status.key] || 0),
          0
        );
      });
      setCurrentTotal(totals);
    } else {
      const currentData = workOrdersGroupedByStatusAndAssignedUser.find(
        (item) => item.assignedToId === e
      );

      if (currentData) {
        const totals = {};
        shopWorkOrderStatuses.forEach(status => {
          totals[status.key] = currentData[status.key] || 0;
        });
        setCurrentTotal(totals);

        setTimeout(() => {
          onClickElement('user', currentData.assignedToName, currentData)();
        }, 300);
      }
    }
  };

  return (
    <Card loading={loading}>
      <StyledHeaderRow gutter={[16, 16]}>
        <Col className="card-header" span={24}>
          <h2>
            Work Order Assigned{' '}
            <span>
              (
              {device === 'mobile'
                ? 'Click Labels to filter'
                : 'Click on any name or number to filter by'}
              )
            </span>
          </h2>
        </Col>
      </StyledHeaderRow>
      {device === 'mobile' ? (
        <>
          <Select
            style={{ width: '100%' }}
            onChange={onFilter}
            placeholder="Select assigned to"
          >
            {assignedUser.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          <RowStatus />
        </>
      ) : (
        <>
          <StyledTableRow
            gutter={[16, 16]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={workOrdersGroupedByStatusAndAssignedUser}
                pagination={false}
                size="small"
                scroll={{ y: 200 }}
              />
            </Col>
          </StyledTableRow>
        </>
      )}
    </Card>
  );
};

WorkOrderStatusByAssignedToWidget.propTypes = {
  onClickElement: PropTypes.func,
  workOrdersGroupedByStatusAndAssignedUser: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
};

WorkOrderStatusByAssignedToWidget.defaultProps = {
  onClickElement: () => {},
  workOrdersGroupedByStatusAndAssignedUser: [],
};

export default WorkOrderStatusByAssignedToWidget;
