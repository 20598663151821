import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { Button, Icon, Typography } from 'antd';
import styled from 'styled-components';

const FloatingButtons = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 9;
`;

const PageControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 9;
  background-color: white;
  padding: 10px;
`;

const SinglePageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export default function SinglePage(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const [zoom, setZoom] = useState(1);

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      '.react-pdf__Page__textContent'
    );
    textLayers.forEach(layer => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function zoomIn() {
    setZoom(zoom + 0.1);
  }

  function zoomOut() {
    setZoom(zoom - 0.1);
  }

  const { pdf } = props;

  return (
    <>
      <FloatingButtons>
        <Button type="primary" shape="circle" icon="plus" onClick={zoomIn} />
        <Button type="primary" shape="circle" icon="minus" onClick={zoomOut} />
      </FloatingButtons>
      <PageControls>
        <Typography.Text>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </Typography.Text>
        <Button
          type="primary"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </Button>
        <Button
          type="primary"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </Button>
      </PageControls>
      <SinglePageContainer>
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            width={window.screen.width < 600 ? 300 : 600}
            scale={zoom}
            pageNumber={pageNumber}
            onLoadSuccess={removeTextLayerOffset}
          />
        </Document>
      </SinglePageContainer>
    </>
  );
}

SinglePage.propTypes = {
  pdf: PropTypes.any
};
