import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './styles/styles.less';

import * as serviceWorker from './serviceWorker';
import DashboardWrapper from './DashboardWrapper';
import WorkOrderTiffViewer from './containers/WorkOrder/WorkOrderTiffViewer'; // Import your viewer component

const windowUrl = window.location.href;

// Initialize Google Analytics
if (windowUrl.includes('staging') || windowUrl.includes('localhost')) {
  ReactGA.initialize('UA-110662465-3');
} else {
  ReactGA.initialize('UA-110662465-2');
}

// Determine whether to render WorkOrderTiffViewer or DashboardWrapper
const Root = () => {
  const path = window.location.pathname;
  const query = window.location.search;

  if (path === '/work-order-tiff-viewer') {
    return <WorkOrderTiffViewer />;
  }

  // Render the DashboardWrapper for all other paths
  return (
    <BrowserRouter>
      <DashboardWrapper />
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// Service worker setup
serviceWorker.unregister();
