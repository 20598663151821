import moment from 'moment';

const statusColors = {
  Overdue: '#FF202F',
  'Due soon': '#FF812A',
  Upcoming: '#0086FA',
  Default: '#333333',
  Safe: '#52c41a',
  'In Compliance': '#52c41a',
  Unsafe: '#000000',
};

function calcTime(dueTime, safetyInspection) {
  const now = moment();
  const dueAt = moment.unix(dueTime);
  const diffInDays = dueAt.diff(now, 'days');
  const diffInMonths = Math.floor(diffInDays / 30.4375);

  let displayValue = Math.abs(diffInDays);
  let displayText = 'day(s)';

  if (diffInMonths >= 1) {
    displayValue = Math.abs(diffInMonths);
    displayText = 'month(s)';
  }

  if (safetyInspection) {
    const overdueOrDueIn = diffInDays < 0 ? Math.abs(diffInDays) : diffInDays;
    displayValue = overdueOrDueIn;
    displayText = 'days';
  }

  return { displayValue, displayText };
}

function calculateMileage(dueMileage, totalMileage, odometer) {
  const kmRemaining = Math.ceil(
    isNaN(Number(dueMileage)) && isNaN(Number(totalMileage))
      ? undefined
      : Math.abs(Number(dueMileage) - Number(totalMileage))
  );

  const milesRemaining = kmRemaining
    ? Math.ceil(kmRemaining / 1.60934)
    : undefined;

  const milesOrKm = odometer === 'km' ? kmRemaining : milesRemaining;
  const milesOrKmText = odometer === 'km' ? 'km' : 'mile(s)';
  const displayText = milesOrKmText ?? null;
  const displayValue = Math.abs(milesOrKm);

  return { displayValue, displayText };
}

function calculateEngineHours(dueEngineHours, engineHours) {
  const hoursRemainingValue = Math.ceil(
    isNaN(Number(dueEngineHours)) || isNaN(Number(engineHours))
      ? 0
      : Math.abs(Number(dueEngineHours) - Number(engineHours)) / 3600
  );
  const displayText = 'engine hour(s)';
  const displayValue = Math.abs(hoursRemainingValue);

  return { displayValue, displayText };
}

export function remains({
  dueTime,
  safetyInspection,
  ruleType,
  dueEngineHours,
  engineHours,
  dueMileage,
  totalMileage,
  odometer,
  displayType,
}) {
  const remains = [];

  const ruleTypeMap = {
    time: [calcTime(dueTime, safetyInspection)],
    time_or_mileage: [
      calcTime(dueTime, safetyInspection),
      calculateMileage(dueMileage, totalMileage, odometer),
    ],
    engine_hours: [calculateEngineHours(dueEngineHours, engineHours)],
    mileage: [calculateMileage(dueMileage, totalMileage, odometer)],
  };

  remains.push(...ruleTypeMap[displayType?.display || 'mileage']);

  return remains;
}

export function getColor(pm_status) {
  return statusColors[pm_status] || '#333333';
}

export function getNextPMColor(
  pmStatus,
  safetyInspection,
  safetyInspectionFlag
) {
  if (pmStatus in statusColors) {
    return statusColors[pmStatus];
  }

  if (pmStatus === 'No action needed') {
    return safetyInspection || safetyInspectionFlag
      ? statusColors.Safe
      : statusColors.Unsafe;
  }

  return statusColors.Default;
}
