import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { subLocationOptions } from 'containers/Vehicles/VehiclesFiltersHelper';

const { Option } = Select;

const SubLocationDropdown = ({ shopId, value, onChange, placeholder }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchSubLocations = async () => {
      setLoading(true);
      try {
        const result = subLocationOptions(shopId);
        setOptions(result);
      } catch (error) {
        console.error('Error fetching sub-location options:', error);
      } finally {
        setLoading(false);
      }
    };

    if (shopId) {
      fetchSubLocations();
    }
  }, [shopId]);

  return (
    <Select
      showSearch
      allowClear
      placeholder={placeholder || 'Select Sub-location'}
      value={value}
      onChange={onChange}
      loading={loading}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().includes(input.toLowerCase())
      }
      style={{ width: '100%' }}
    >
      {options.map((opt) => (
        <Option key={opt.key} value={opt.key}>
          {opt.name}
        </Option>
      ))}
    </Select>
  );
};

SubLocationDropdown.propTypes = {
  shopId: PropTypes.number.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SubLocationDropdown;