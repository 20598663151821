import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import styled from 'styled-components';
import _, { isArray } from 'lodash';
import queryString from 'query-string';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';
import moment from 'moment-timezone';
import {
  Typography,
  Tag,
  Button,
  Icon,
  Menu,
  Dropdown,
  message,
  Row,
  Col,
  Input,
  List,
  Spin,
  Checkbox,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
} from 'antd';

import { TableStore } from 'stores/abstract';

import { Link } from 'react-router-dom';

import { ShareVehicleHealthReportPage } from 'containers/ShareVehicleHealthReport';

import { customFleets, PitstopModal } from 'shared';

import {
  AppStore,
  CarStore,
  CurrentUserStore,
  IssueStore,
  ShopStore,
  CarStatusStore,
  ContactStore,
} from 'stores';

import {
  renderIndicator,
  renderVehicleId,
  renderNextPMDueInPercentage,
  renderWorkOrderAssigned,
  renderIssuesWithShowIssuesLink,
  renderAssetSafetyInspections,
} from 'containers/CarListTable/CarListTableColumns';

import { renderEngineLightStatus } from 'containers/CarListTable/CarListTableColumnsV2';

import PMFeedbackModal from 'containers/IssueProfile/PMFeedbackModal';

import { IssuesTable } from 'containers/Issues';
import VehicleNoteTextarea from 'components/Vehicles/VehicleNoteTextarea';
import { Logger } from 'stores/Classes';
import AddAssetModal from 'components/Modals/AddAssetModal';
import NotificationBanner from 'components/NotificationBanner';
import ArchiveAssetModal from 'components/Modals/ArchiveAssetModal';
import DeviceStore from 'stores/Classes/DeviceStore';
import { CardVehicle, CardTrailer } from 'containers/CarCard/CartCardElements';
import FormFilter from 'components/Vehicles/FormFilter';

import CustomTableColumn from 'components/Tables/CustomTableColumn';
import PMTable from './PMTable';
import AppAlert from 'components/AppAlert';
import TableContainer from 'components/Tables/TableContainer';
import DVIRWrapper from 'components/Vehicles/VehiclesTableComponents/DVIRWrapper';
import IndicatorTableWrapper from 'components/Vehicles/VehiclesTableComponents/IndicatorTableWrapper';
import StatusWrapper from 'components/Vehicles/VehiclesTableComponents/StatusWrapper';
import FilterDropdown from 'components/FilterDropdown';

import {
  dvirFilterOptions,
  engineLightFilterOptions,
  nextPMFilterOptions,
  safetyInspectionsFilterOptions,
  issuesFilterOptions,
  subLocationOptions,
} from './VehiclesFiltersHelper';

import VendorsDropdown from './VendorsDropdown';

const Text = Typography.Text;
const { Search } = Input;

const StyledPitstopModal = styled(PitstopModal)`
  max-width: ${(props) => (props.device === 'mobile' ? '80%' : 'auto')};

  ${(props) =>
    props.device !== 'mobile' &&
    `
    @media (max-width: 1600px) {
      max-width: 45%;
    }
    @media (min-width: 1601px) {
      max-width: 35%;
    }
  `}
`;

const StyledVendorTitle = styled(Text)`
  margin-right: 0.5rem;
  text-align: center;
  margin-bottom: 4px;
  margin-left: 10px;

  @media (max-width: 1680px) {
    width: 100px;
  }

  @media (max-width: 1400px) {
    width: 80px;
  }
`;

class VehiclesTable extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    onUpdateCarStatus: PropTypes.func,
    type: PropTypes.string,
    childRef: PropTypes.func,
    loadTotalAssetsCount: PropTypes.func,
  };

  constructor(props) {
    super(props);
    // Create a reference to PMTable
    this.pmTableRef = createRef();
  }

  tableStore;

  state = {
    contentType: this.props.type,
    popoverVisible: false,
    customColumns: [],
    selectedCarId: undefined,
    vehiclesApiUrl: '',
    issueReportStatus: ['new'],
    currentPagination: {},
    countFiltersSelected: 0,
    shopSettings: null,
    issueSources: [
      'dtc',
      'routine',
      'recall',
      'user',
      'algorithm',
      'archive',
      'lightVehicleDTC',
      'heavyVehicleDTC',
    ],
    isPrintHealthReport: false,
    expandedCards: {},
    dropdownVisibility: {},
    searchKey: null,
    searchValue: null,
    searchTrailerValue: null,
    searchTrailerKey: null,
    connectivityStatus: null,
    maxPriority: _.isNil(
      queryString.parse(this.props.location.search).priorityFilter
    )
      ? 'All'
      : queryString.parse(this.props.location.search).priorityFilter,
    nextPmCars: [],
    isLoadingStatuses: false,
    currentShopStatuses: [],
    newShopCarStatuses: [],
    isLoadingData: false,
    currentShopVendors: [],
    showVendorTitle: false,

    isLoadingVehicles: false,
    currentVehicles: [],
    paginationMeta: {
      pageSize: 10,
      total: 0,
      current: 1,
    },
    dropdownVisible: false,
    currentFilters: [],
    filterOptions: [],
    filteredInfo: {},
    filterByStatusDropdownVisible: false,
    nextPMFilterDropdownVisible: false,
    dvirFilterDropdownVisible: false,
    trailerDvirFilterDropdownVisible: false,
    trailerNextPMFilterDropdownVisible: false,
    currentTrailerDVIRFilters: [],
    currentTrailersPMFilters: [],
    currentPMFilters: [],
    currentDVIRFilters: [],
    currentSafetyFilters: [],
    currentEngineLightFilters: [],
    currentTrailerSafetyFilters: [],
    currentTrailerStatusFilters: [],
    currentIssuesFilters: [],
    currentSubLocationFilters:[],
    previousFilters: {},
    currentTrailers: [],
    shouldShowButton: true,
    isArchiveAssetsModalVisible: false,
    expandedCarIds: [],
    selectedAssets: [],
    isArchivingAssets: false,
    archivingAssetType: null,
    isExportingCSV: false,
    safetyInspectionFilterDropdownVisible: false,
    expandedPMCarIds: [],
    expandedSafetyInspectionIds: [],
    subLocationSearchText: '',
  };

  get filterOptions() {
    const { currentShopStatuses } = this.state;
    return currentShopStatuses.map((status) => ({
      text: status.description,
      value: status.key,
    }));
  }

  get combinedCurrentFilters() {
    return [
      ...this.state.currentFilters,
      ...this.state.currentPMFilters,
      ...this.state.currentDVIRFilters,
      ...this.state.currentSafetyFilters,
      ...this.state.currentEngineLightFilters,
      ...this.state.currentIssuesFilters,
      ...this.state.currentSubLocationFilters
    ];
  }

  refreshTableOffset = false;

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.loadShopStatuses();
    this.loadShopVendors();

    // clear the filters that are per shop but keep the generic filters
    this.setState(
      {
        currentFilters: [],
      },
      () => {
        if (this.props.type === 'trailers') {
          // this.handleSearchChangeAndLoadTrailers();
          // this.handleOnChangeCurrentTrailersFiltersDebouncer();
          this.handleOnChangeCurrentFiltersDebouncer();
        } else {
          // this.handleSearchChangeAndLoadVehicles();
          this.handleOnChangeCurrentFiltersDebouncer();
        }

        this.showOrHideCustomColumns();
      }
    );

    // this.onClearAllFilters();
    // this.onClearAllTrailerFilters();
  });

  get shopId() {
    return ShopStore.currentShop.id;
  }

  searchDebouncer = null;

  handleSearchData = () => {
    clearTimeout(this.searchDebouncer);

    this.searchDebouncer = setTimeout(() => {
      this.loadData(this.props.type);
      if (this.props.type === 'vehicles') {
        if (this.props.onUpdateCarStatus) {
          this.props.onUpdateCarStatus();
        }
      }
    }, 500);
  };

  searchDebouncerTrailers = null;

  handleFilterChange = ({
    selectedKeys,
    confirm,
    filterStateKey,
    options,
    dropdonwVisibleKey,
    callback,
    extraState = {},
  }) => {
    this.setState(
      {
        [filterStateKey]: selectedKeys.map((key) => {
          const option = options.find((s) => s.key === key || s.value === key);

          return {
            key: key,
            filterKey: option?.filterKey || key,
            label: option?.description || option?.text || key,
          };
        }),
        [dropdonwVisibleKey]: false,
        selectedAssets: [],
        ...extraState,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
        if (callback) callback();
      }
    );
  };

  handleFilterReset = ({ clearFilters, filterKey, dropDownKey, callback }) => {
    clearFilters();
    this.setState(
      {
        [filterKey]: [],
        [dropDownKey]: false,
      },
      () => {
        if (callback) callback();
      }
    );
  };

  handleCarStatusFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentFilters',
      options: this.state.currentShopStatuses,
      dropdownVisibleKey: 'filterByStatusDropdownVisible',
    });
  };

  handleNextPMFilterChange = (selectedKeys, confirm, callback) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentPMFilters',
      options: nextPMFilterOptions(),
      dropdownVisibleKey: 'nextPMFilterDropdownVisible',
      callback,
    });
  };

  handleDVIRFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentDVIRFilters',
      options: dvirFilterOptions(),
      dropdownVisibleKey: 'dvirFilterDropdownVisible',
    });
  };

  handleEngineLightFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentEngineLightFilters',
      options: engineLightFilterOptions(),
      dropdownVisibleKey: 'engineLightFilterDropdownVisible',
    });
  };

  handleIssuesFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentIssuesFilters',
      options: issuesFilterOptions(),
      dropdownVisibleKey: 'issuesFilterDropdownVisible',
    });
  };

  handleTrailerSafetyInspectionFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentTrailerSafetyFilters',
      options: safetyInspectionsFilterOptions(),
      dropdownVisibleKey: 'trailerSafetyInspectionFilterDropdownVisible',
    });
  };

  handleReset = (clearFilters, source = 'status') => {
    const callback = this.onChangeCurrentFilters();
    if (source === 'pm') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentPMFilters',
        dropDownKey: 'nextPMFilterDropdownVisible',
        callback,
      });
    } else if (source === 'dvir') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentDVIRFilters',
        dropDownKey: 'dvirFilterDropdownVisible',
        callback,
      });
    } else if (source === 'status') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentTrailerStatusFilters',
        dropDownKey: 'trailerFilterByStatusDropdownVisible',
        callback,
      });
    } else if (source === 'engineLight') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentEngineLightFilters',
        dropDownKey: 'engineLightFilterDropdownVisible',
        callback,
      });
    } else if (source === 'issues') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentIssuesFilters',
        dropDownKey: 'issuesFilterDropdownVisible',
        callback,
      });
    } else if (source === 'subLocations') {
      clearFilters(); // Clear the dropdown internal state
      this.setState(
        {
          currentSubLocationFilters: [],
          subLocationFilterDropdownVisible: false,
          filteredInfo: {
            ...this.state.filteredInfo,
            tagNames: [],
          },
        },
        () => {
          this.onChangeCurrentFilters(); // Refresh the table after resetting
        }
      );
    } else {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentFilters',
        dropDownKey: 'filterByStatusDropdownVisible',
        callback,
      });
    }
  };

  handleTrailerFilterReset = (clearFilters, source = 'dvir') => {
    const callback = this.onChangeCurrentFilters();
    if (source === 'dvir') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentTrailerDVIRFilters',
        dropDownKey: 'trailerDvirFilterDropdownVisible',
        callback,
      });
    } else if (source === 'status') {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentTrailerStatusFilters',
        dropDownKey: 'trailerFilterByStatusDropdownVisible',
        callback,
      });
    } else {
      this.handleFilterReset({
        clearFilters,
        filterKey: 'currentTrailersPMFilters',
        dropDownKey: 'trailerNextPMFilterDropdownVisible',
        callback,
      });
    }
  };

  handleSafetyInspectionFilterReset = (clearFilters, source = 'status') => {
    const callback = this.onChangeCurrentFilters();

    this.handleFilterReset({
      clearFilters,
      filterKey: 'currentTrailersPMFilters',
      dropDownKey: 'trailerNextPMFilterDropdownVisible',
      callback,
    });
  };

  handleSafetyInspectionFilterChange = (selectedKeys, confirm) => {
    this.handleFilterChange({
      selectedKeys,
      confirm,
      filterStateKey: 'currentSafetyFilters',
      options: safetyInspectionsFilterOptions(),
      dropdownVisibleKey: 'safetyInspectionFilterDropdownVisible',
    });
  };

  handleTrailerSafetyInspectionFilterReset = (
    clearFilters,
    source = 'status'
  ) => {
    const callback = this.onChangeCurrentFilters();
    this.handleFilterReset({
      clearFilters,
      filterKey: 'currentTrailerSafetyFilters',
      dropDownKey: 'trailerSafetyInspectionFilterDropdownVisible',
      callback,
    });
  };

  showOrHideCustomColumns = () => {
    if (
      !this.vehicleColumns.find((col) => col.key === 'tagNames') &&
      customFleets.ufpFleet.includes(this.shopId) || customFleets.summitMaterialsWest.includes(this.shopId)
    ) {
      this.vehicleColumns.splice(2, 0, {
        key: 'tagNames',
        title: customFleets.ufpFleet.includes(this.shopId) ? 'Sub Locations' : 'Tags',
        align: 'center',
        sorter: false,
        filters: this.subLocationsFilterOptions(),
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ subLocationFilterDropdownVisible: true });
            }}
          />
        ),
        filterDropdown: this.renderSubLocationsFilterDropdown,
        render: (record) => record.tags?.[0]?.name || '-',
      });
    }
    // Ensure the 'Safety Inspections' column isn't already present in vehicleColumns
    if (!this.vehicleColumns.find((col) => col.key === 'safetyInspections')) {
      this.vehicleColumns.splice(4, 0, {
        key: 'safetyInspections',
        title: 'Safety Inspections',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        sorter: true,
        render: (car) => {
          return renderAssetSafetyInspections(
            car,
            this.handleSafetyInspectionToggle, // Handle the expand/collapse click
            this.state.expandedSafetyInspectionIds // Track expanded state
          );
        },
        filters: safetyInspectionsFilterOptions(),
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ safetyInspectionFilterDropdownVisible: true });
            }}
          />
        ),
        filterDropdown: this.renderSafetyInspectionFilterDropdown,
      });
    }

    
    // Ensure the 'Safety Inspections' column isn't already present in trailerColumns
    if (!this.trailerColumns.find((col) => col.key === 'safetyInspections')) {
      this.trailerColumns.splice(3, 0, {
        key: 'safetyInspections',
        title: 'Safety Inspections',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        sorter: true,
        render: (car) => {
          return renderAssetSafetyInspections(
            car,
            this.handleSafetyInspectionToggle, // Handle the expand/collapse click
            this.state.expandedSafetyInspectionIds // Track expanded state
          );
        },
        filters: safetyInspectionsFilterOptions(),
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                trailerSafetyInspectionFilterDropdownVisible: true,
              });
            }}
          />
        ),
        filterDropdown: this.renderTrailerSafetyInspectionFilterDropdown,
      });
    }

    // show issues filter if the shop is in the foodExpressVancouver custom fleet
    // if (customFleets.foodExpressVancouver.includes(this.shopId)) {
    //   this.vehicleColumns = this.vehicleColumns.map((column) => {
    //     if (column.key === 'issues') {
    //       return {
    //         ...column,
    //         filters: issuesFilterOptions(),
    //         filterIcon: (filtered) => (
    //           <Icon
    //             type="filter"
    //             theme="filled"
    //             style={{
    //               color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
    //             }}
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               this.setState({ issuesFilterDropdownVisible: true });
    //             }}
    //           />
    //         ),
    //         filterDropdown: this.renderIssuesFilterDropdown,
    //       };
    //     }
    //     return column;
    //   });
    // } else {
    //   // delete the filter related keys from the issues column
    //   this.vehicleColumns = this.vehicleColumns.map((column) => {
    //     if (column.key === 'issues') {
    //       const auxColumn = { ...column };
    //       delete auxColumn.filters;
    //       delete auxColumn.filterIcon;
    //       delete auxColumn.filterDropdown;
    //       return auxColumn;
    //     }
    //     return column;
    //   });
    // }
  };

  saveCarNotes = async ({ id, notes }) => {
    try {
      await CarStore.updateCarNotes(id, notes);
    } catch (error) {
      AppStore.addError('Failed to save notes');
    }
  };

  renderCarStatusFilterDropdown = (
    { setSelectedKeys, selectedKeys, confirm, clearFilters },
    type = 'vehicles'
  ) => {
    const onReset = () => {
      if (this.props.type === 'vehicles') {
        this.handleReset(clearFilters);
      }

      if (this.props.type === 'trailers') {
        this.handleTrailerFilterReset(clearFilters, 'status');
      }
    };

    const onOk = () => {
      this.handleCarStatusFilterChange(selectedKeys, confirm);
    };

    // if (type === 'vehicles') {
    return (
      <FilterDropdown>
        {/* For each filterOptions display a checkbox and the nase of the filterOptions */}
        <div className="filter-dropdown-list">
          {this.filterOptions.map((filter) => {
            return (
              <div className="filter-dropdown-list-item" key={filter.value}>
                <Checkbox
                  checked={selectedKeys.includes(filter.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, filter.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== filter.value)
                      );
                    }
                  }}
                >
                  {filter.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button onClick={onReset} size="small">
            Reset
          </Button>
          <Button type="primary" size="small" onClick={onOk}>
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  handlePmsCountSectionClick = (sectionKey) => {
    this.setState({ currentPMFilters: [{ key: sectionKey }] }, () => {
      this.handleNextPMFilterChange([sectionKey], () => {});
    });
  };

  handleSafetyInspectionsCountSectionClick = (sectionKey) => {
    this.setState({ currentSafetyFilters: [{ key: sectionKey }] }, () => {
      this.handleSafetyInspectionFilterChange([sectionKey], () => {});
    });
  };

  renderNextPMFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {nextPMFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'pm')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleNextPMFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderTrailerNextPMFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {nextPMFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleTrailerFilterReset(clearFilters, 'nextPm')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleNextPMFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  subLocationsFilterOptions = () => {
    const shopId = ShopStore.currentShop.id; // Get the current shopId from ShopStore
    const options = subLocationOptions(shopId); // Retrieve options based on shopId
  
    // Map the options into filter-compatible format
    return options.map((option) => ({
      text: option.name,
      value: option.key,
    }));
  };

  renderSubLocationsFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    // Retrieve current shopId dynamically
    const shopId = ShopStore.currentShop.id;
  
    // Get the options based on the current shopId
    const allOptions = this.subLocationsFilterOptions(shopId);
  
    // Filter options based on the search text
    const filteredOptions = allOptions.filter((option) =>
      option.text.toLowerCase().includes(this.state.subLocationSearchText?.toLowerCase() || '')
    );
  
    return (
      <FilterDropdown>
        {/* Search Input */}
        <div style={{ padding: '8px' }}>
          <Input
            placeholder={`Search ${customFleets.ufpFleet.includes(this.shopId) ? 'sub-location' : 'tag'}`}
            value={this.state.subLocationSearchText || ''}
            onChange={(e) => this.setState({ subLocationSearchText: e.target.value })}
            style={{ marginBottom: '8px', display: 'block' }}
          />
        </div>
        <div className="filter-dropdown-list" style={{ maxHeight: '150px', overflowY: 'auto' }}>
          {filteredOptions.map((filter) => (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  const newKeys = e.target.checked
                    ? [...selectedKeys, filter.value]
                    : selectedKeys.filter((key) => key !== filter.value);
                  setSelectedKeys(newKeys);
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          ))}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() => this.handleReset(clearFilters, 'subLocations')}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              this.handleFilterChange({
                selectedKeys,
                confirm,
                filterStateKey: 'currentSubLocationFilters',
                options: allOptions,
                dropdonwVisibleKey: 'subLocationFilterDropdownVisible',
              });
            }}
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };  

  renderSafetyInspectionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {safetyInspectionsFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() =>
            this.handleSafetyInspectionFilterReset(clearFilters, 'pm')
          }
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleSafetyInspectionFilterChange(selectedKeys, confirm)
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderTrailerSafetyInspectionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {safetyInspectionsFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() =>
            this.handleTrailerSafetyInspectionFilterReset(clearFilters, 'pm')
          }
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleTrailerSafetyInspectionFilterChange(
              selectedKeys,
              confirm
            )
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderDVIRFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {dvirFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'dvir')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleDVIRFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderEngineLightFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      <div className="filter-dropdown-list">
        {engineLightFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'engineLight')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleEngineLightFilterChange(selectedKeys, confirm)
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderIssuesFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {issuesFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'issues')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleIssuesFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderTrailerDVIRFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {dvirFilterOptions().map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'dvir')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleDVIRFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  toggleCardExpansion = (vehicleId) => {
    this.setState((prevState) => ({
      expandedCards: {
        ...prevState.expandedCards,
        [vehicleId]: !prevState.expandedCards[vehicleId],
      },
    }));
  };

  StatusDropdown = (car, source = 'vehicles') => {
    const { currentShopStatuses } = this.state;
    const defaultStatuses = CarStatusStore.getDefaultStatusList;
    const { device } = DeviceStore;
    const { dropdownVisibility } = this.state;

    const statuses =
      currentShopStatuses.length === 0 ? defaultStatuses : currentShopStatuses;

    // Determine the current car status
    let currentCarStatus = statuses.find(
      (status) => status.key === car.carStatus
    ) || {
      key: car.carStatus,
      description: 'Status Deleted',
      color: 'grey',
    };

    const showVendorStatus = statuses.find((status) => status.is_show_vendor);

    if (!statuses.find((status) => status.key === currentCarStatus.key)) {
      currentCarStatus =
        statuses.find((status) => status.is_default) || statuses[0];
    }

    const isShowVendor = showVendorStatus
      ? showVendorStatus.id === currentCarStatus.id
      : false;

    const DropButton = styled(Button)`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: white;
      border-color: ${({ color }) => color || 'inherit'};
      color: ${({ color }) => color || 'inherit'};
      padding: 5px 10px;
      border-radius: 6px;
    `;

    return (
      <StatusWrapper margin={device === 'mobile' ? '0px' : '5px'}>
        <Dropdown
          trigger={['click']}
          visible={dropdownVisibility[car.id]}
          onVisibleChange={(flag) => this.toggleDropdown(car.id, flag)}
          overlay={
            <Menu
              className="status-menu"
              onClick={(e) => e.domEvent?.stopPropagation()}
            >
              {statuses.map((status) => (
                <Menu.Item
                  onClick={(e) => this.onClickNewStatus(e, status, car, source)}
                  className="menu-item"
                  key={status.key}
                >
                  <Button
                    onClick={(e) =>
                      this.onClickNewStatus(e, status, car, source)
                    }
                    className="btn-status"
                    ghost
                    style={{
                      color: status.color,
                      backgroundColor: status.color,
                      borderColor: status.color,
                    }}
                  >
                    {status.description}
                  </Button>
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                onClick={this.stopPropagation}
                className="menu-item"
                key="actions"
              >
                <Button
                  block
                  icon="edit"
                  type="primary"
                  onClick={this.openShopStatusesModal}
                >
                  Edit Statuses
                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <DropButton
            onClick={(e) => e.stopPropagation()}
            color={currentCarStatus.color}
            loading={car.isLoadingStatus}
          >
            {currentCarStatus.description} <Icon type="down" />
          </DropButton>
        </Dropdown>
        <VendorsDropdown
          car={car}
          vendors={this.state.currentShopVendors}
          isShown={isShowVendor}
          shopId={ShopStore.currentShop.id}
          onUpdate={(carId, vendorId) => {
            this.setState((prevState) => ({
              currentVehicles: prevState.currentVehicles.map((car) =>
                car.id === carId ? { ...car, carVendorId: vendorId } : car
              ),
            }));
          }}
        />
      </StatusWrapper>
    );
  };

  get baseColumns() {
    return {
      indicator: {
        key: 'indicator',
        className: 'indicator',
        title: null,
        render: renderIndicator,
      },
      car_name: {
        field: 'nextPm',
        key: 'car_name',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        title: `Unit ID`,
        sorter: true,
        render: renderVehicleId,
      },
      nextPm: {
        field: 'nextPm',
        key: 'percentage',
        filter: false,
        align: 'center',
        title: (
          <div>
            <span>Next PM</span>
          </div>
        ),
        sorter: true,
        defaultSortOrder: 'descend',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        filters: nextPMFilterOptions(),
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ trailerNextPMFilterDropdownVisible: true });
            }}
          />
        ),
        filterDropdown: this.renderTrailerNextPMFilterDropdown,
        render: (car) => {
          return renderNextPMDueInPercentage(
            car,
            this.handleCarPMsToggle,
            this.state.expandedPMCarIds
          );
        },
      },
      status: {
        field: 'status',
        key: 'car_status',
        title: 'Status',
        align: 'center',
        sorter: true,
        defaultSortOrder: 'descend',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        filters: this.filterOptions,
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ filterByStatusDropdownVisible: true });
            }}
          />
        ),
        filterDropdown: (opt) =>
          this.renderCarStatusFilterDropdown(opt, this.state.contentType),
        render: (car) => this.StatusDropdown(car, this.state.contentType),
        rowClick: false,
      },
      workOrder: {
        key: 'workOrderAssigned',
        title: 'Work Orders Assigned',
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        sorter: false,
        render: (car) => {
          if (car.workOrdersAssigned) {
            return renderWorkOrderAssigned(car.workOrdersAssigned);
          }
          return 'N/A';
        },
      },
      notes: {
        field: 'notes',
        key: 'notes',
        filter: false,
        onCell: () => ({
          className: 'hover-effect-cell',
        }),
        align: 'center',
        title: (
          <div>
            <span>Notes</span>
          </div>
        ),
        sorter: false,
        render: (car) => {
          return (
            <VehicleNoteTextarea
              car={car}
              onSave={({ id, notes }) => {
                this.saveCarNotes({ id, notes });
              }}
            />
          );
        },
        rowClick: false,
      },
    };
  }

  vehicleColumns = [
    this.baseColumns.indicator,
    { ...this.baseColumns.car_name, ...{ title: 'Unit ID - Vehicles' } },
    {
      key: 'engine_light',
      title: 'Engine Light',
      align: 'center',
      render: (car) => {
        if (!car.currentEngineLightStatus) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        } else if (car.currentEngineLightStatus === 'not-found') {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        return renderEngineLightStatus({
          engine_light_status: car?.currentEngineLightStatus?.payload,
        });
      },
      sorter: (car1, car2) => {
        return (
          this.mapEngineLightToValue(car1) - this.mapEngineLightToValue(car2)
        );
      },
      filters: engineLightFilterOptions(),
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ engineLightFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderEngineLightFilterDropdown,
    },
    {
      key: 'issues',
      title: 'Issues',
      sorter: true,
      align: 'center',
      defaultSortOrder: 'descend',
      render: (car) =>
        renderIssuesWithShowIssuesLink(
          car,
          this.handleCarIssuesToggle,
          this.state.expandedCarIds
        ),
      width: 200,
      filters: issuesFilterOptions(),
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ issuesFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderIssuesFilterDropdown,
    },
    this.baseColumns.nextPm,
    {
      field: 'dvir',
      key: 'dvir',
      title: 'Latest DVIR',
      align: 'center',
      sorter: true,
      filters: dvirFilterOptions(),
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ dvirFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderDVIRFilterDropdown,
      render: (car) => {
        if (!car.dvir) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        }
        const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
        if (!safetyStatus) {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        let color;
        let label;
        if (safetyStatus === 'safe') {
          color = 'green';
          label = 'Safe';
        } else if (safetyStatus === 'unsafe') {
          color = 'red';
          label = 'Unsafe';
        } else {
          color = 'geekblue';
          label = 'Resolved';
        }
        const vehicleDefects = _.get(
          car,
          'dvir.record.payload.vehicleDefects',
          []
        );
        const trailerDefects = _.get(
          car,
          'dvir.record.payload.trailerDefects',
          []
        );
        const totalDefects = vehicleDefects.length + trailerDefects.length;
        return (
          <DVIRWrapper>
            <div
              className="tag"
              style={{
                color: color,
              }}
              key={car.id}
            >
              {label}
            </div>
            <div className="defects-count">
              Defects: <b>{totalDefects}</b>
            </div>
            <Link
              onClick={(evt) => evt.stopPropagation()}
              to={`/dvir/${car.id}`}
              style={{ marginTop: 5 }}
            >
              View DVIR
            </Link>
          </DVIRWrapper>
        );
      },
      onCell: (record) => {
        if (!record.dvir || !_.get(record, 'dvir.record.safetyStatus')) {
          return {};
        }
        return { className: 'hover-effect-cell' };
      },
    },
    this.baseColumns.workOrder,
    this.baseColumns.status,
    this.baseColumns.notes,
    {
      field: 'actions',
      key: 'actions',
      filter: false,
      align: 'center',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Health Report',
      defaultSortOrder: 'descend',
      render: (car) => {
        // return <Button shape="circle" icon="share-alt" />;
        const handleShareClick = (car, { key }) => {
          this.setState({ selectedCarId: car.id }, () => {
            if (key === 'download') {
              this.setState(
                {
                  isPrintHealthReport: false,
                },
                () => {
                  this.onDownloadVehicleHealthReport();
                }
              );
            } else if (key === 'print') {
              this.setState(
                {
                  isPrintHealthReport: true,
                },
                () => {
                  this.onPrintVehicleHealthReport();
                }
              );
            } else if (key === 'share') {
              AppStore.openModals.set(
                'SHARE_VEHICLE_HEALTH_REPORT_MODAL',
                true
              );
            }
          });
        };
        const menu = (
          <Menu
            onClick={(data) => {
              handleShareClick(car, data);
            }}
          >
            <Menu.Item
              key="print"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="printer" />
              Print Report
            </Menu.Item>
            <Menu.Item
              key="download"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="download" />
              Download Report
            </Menu.Item>
            <Menu.Item
              key="share"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="share-alt" />
              Share Report
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} placement="bottomRight">
            <Button
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
              shape="circle"
              icon="file-text"
            />
          </Dropdown>
        );
      },
      rowClick: false,
    },
  ];

  trailerColumns = [
    this.baseColumns.indicator,
    { ...this.baseColumns.car_name, ...{ title: 'Unit ID - Trailers' } },
    this.baseColumns.nextPm,
    {
      field: 'dvir',
      key: 'dvir',
      title: 'Latest DVIR',
      align: 'center',
      sorter: true,
      filters: dvirFilterOptions(),
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ trailerDvirFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderTrailerDVIRFilterDropdown,
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      render: (car) => {
        if (!car.dvir) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        }
        const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
        if (!safetyStatus) {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        let color;
        let label;
        if (safetyStatus === 'safe') {
          color = 'green';
          label = 'Safe';
        } else if (safetyStatus === 'unsafe') {
          color = 'red';
          label = 'Unsafe';
        } else {
          color = 'geekblue';
          label = 'Resolved';
        }
        const vehicleDefects = _.get(
          car,
          'dvir.record.payload.vehicleDefects',
          []
        );
        const trailerDefects = _.get(
          car,
          'dvir.record.payload.trailerDefects',
          []
        );
        const totalDefects = vehicleDefects.length + trailerDefects.length;
        return (
          <DVIRWrapper>
            <div
              className="tag"
              style={{
                color: color,
              }}
              key={car.id}
            >
              {label}
            </div>
            <div className="defects-count">
              Defects: <b>{totalDefects}</b>
            </div>
            <Link
              onClick={(evt) => evt.stopPropagation()}
              to={`/dvir/${car.id}?trailer=true`}
              style={{ marginTop: 5 }}
            >
              View DVIR
            </Link>
          </DVIRWrapper>
        );
      },
    },
    this.baseColumns.workOrder,
    this.baseColumns.status,
    this.baseColumns.notes,
  ];

  get columns() {
    return this.props.type === 'vehicles'
      ? this.vehicleColumns
      : this.trailerColumns;
  }

  mapEngineLightToValue = (car) => {
    const engineLightStatus = _.get(car, 'engine_light_status');
    let engineLightValue = 0;
    if (_.isNil(engineLightStatus)) {
      return engineLightValue;
    }
    if (_.get(engineLightStatus, 'generalVehicleWarningLight')) {
      return 2;
    }
    if (_.get(engineLightStatus, 'redStopLamp')) {
      engineLightValue += 6;
    }
    if (_.get(engineLightStatus, 'malfunctionLamp')) {
      engineLightValue += 3;
    }
    if (_.get(engineLightStatus, 'amberWarningLamp')) {
      engineLightValue += 2;
    }
    if (_.get(engineLightStatus, 'protectWarningLamp')) {
      engineLightValue += 2;
    }

    return engineLightValue;
  };

  loadShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingStatuses: true });
      const shopStatuses = await CarStatusStore.fetchShopCarStatuses(shopId);
      const hasShowVendor = shopStatuses.some(
        (status) => status.is_show_vendor
      );
      this.setState({
        showVendorTitle: hasShowVendor,
        currentShopStatuses: shopStatuses || [],
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  };

  loadShopVendors = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingStatuses: true });
      const shopVendors = await ContactStore.getShopContacts();

      this.setState({
        currentShopVendors:
          shopVendors.sort((prev, next) => prev.name > next.name) || [],
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  };

  saveNewShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    const statuses = this.state.newShopCarStatuses;
    // check if there's a default status
    if (!_.some(statuses, { is_default: true })) {
      message.error('Please select a default status');
      return;
    }
    try {
      this.setState({ isSavingStatuses: true });
      const newStatuses = await CarStatusStore.updateShopStatuses(
        shopId,
        statuses
      );
      this.setState({ currentShopStatuses: newStatuses || [] });
      message.success('Statuses have been updated successfully!');
      AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
      if (this.props.onUpdateCarStatus) {
        this.props.onUpdateCarStatus();
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isSavingStatuses: false });
    }
  };

  stopPropagation = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.domEvent?.stopPropagation) {
      e.domEvent.stopPropagation();
    }
  };

  onClickNewStatus = async (e, status, car, source = 'vehicles') => {
    this.stopPropagation(e);
    const newStatus = status.key;
    const carId = car?.id;
    // update the table with new status
    let toUpdateStatus = {
      currentVehicles: this.state.currentVehicles.map((ccar) => {
        if (ccar.id === carId) {
          ccar.carStatus = newStatus;
          ccar.isLoadingStatus = true;
        }

        return ccar;
      }),
    };

    try {
      // update the car status in the server
      await CarStore.updateCarStatusOnServer(car, newStatus);
      message.info('Status has been updated successfully!');
      if (this.props.onUpdateCarStatus) {
        this.props.onUpdateCarStatus({
          carId,
          newStatus,
        });
      }

      toUpdateStatus = {
        currentVehicles: this.state.currentVehicles.map((ccar) => {
          if (ccar.id === carId) {
            ccar.isLoadingStatus = false;
          }
          return ccar;
        }),
      };

      this.setState({
        dropdownVisible: false, // Close dropdown
        ...toUpdateStatus,
      });

      this.toggleDropdown(carId, false);
    } catch (error) {
      AppStore.addError('Failed to update status, please try again.');
      Logger.error(error);
    }
  };

  openShopStatusesModal = (e) => {
    this.stopPropagation(e);
    if (this.state.isLoadingStatuses) {
      return;
    }
    this.setState({
      newShopCarStatuses:
        this.state.currentShopStatuses.length > 0
          ? JSON.parse(JSON.stringify(this.state.currentShopStatuses))
          : CarStatusStore.getDefaultStatusList,
    });
    AppStore.openModals.set('SHOP_STATUSES_MODAL', true);
  };

  onChangeCurrentFiltersDebouncer = null;

  handleOnChangeCurrentFiltersDebouncer = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.onChangeCurrentFiltersDebouncer);
    this.onChangeCurrentFiltersDebouncer = setTimeout(() => {
      this.onChangeCurrentFilters();
    }, 500);
  };

  onChangeCurrentTrailersFiltersDebouncer = null;

  unlisten = null;

  async componentDidMount() {
    if (this.props.childRef) {
      this.props.childRef(this);
    }
    await this.loadShopStatuses();
    await this.loadShopVendors();
    await this.loadQueryParamsAndUpdateTable();

    this.handleOnChangeCurrentFiltersDebouncer();

    this.showOrHideCustomColumns();

    this.unlisten = this.props.history.listen((location, action) => {
      const query = queryString.parse(location.search);

      const updateFilter = (queryKey, filterOptions, stateKey) => {
        const filterValue = query[queryKey];
        if (filterValue) {
          const selectedFilter = filterOptions.find(
            (filter) =>
              filter.value === filterValue ||
              clearString(filter.text) === clearString(filterValue)
          );
          if (selectedFilter) {
            return {
              [stateKey]: [
                {
                  key: selectedFilter.value,
                  label: selectedFilter.text,
                },
              ],
            };
          }
        }
        return null;
      };

      const clearString = (str) => _.deburr(_.kebabCase(str));

      const newState = {
        ...updateFilter('dvir', dvirFilterOptions(), 'currentDVIRFilters'),
        ...updateFilter(
          'dvirTrailer',
          dvirFilterOptions(),
          'currentTrailerDVIRFilters'
        ),
        ...updateFilter('status', this.filterOptions, 'currentFilters'),
      };

      if (Object.keys(newState).length > 0) {
        this.setState(newState, this.handleOnChangeCurrentFiltersDebouncer);
      }
    });
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
    if (this.unlisten) {
      this.unlisten();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let { searchValue, currentShopStatuses } = this.state;
    if (searchValue !== prevState.searchValue) {
      this.handleSearchData();
    }

    if (currentShopStatuses !== prevState.currentShopStatuses) {
      this.vehicleColumns = this.vehicleColumns.map((column) => {
        if (column.key === 'car_status') {
          return {
            ...column,
            filters: this.filterOptions,
          };
        }
        return column;
      });
    }

    // vehicles filters
    const isSearchValueChanged = searchValue !== prevState.searchValue;
    const isCurrentFiltersChanged = !_.isEqual(
      this.state.currentFilters,
      prevState.currentFilters
    );
    const isCurrentPMFiltersChanged = !_.isEqual(
      this.state.currentPMFilters,
      prevState.currentPMFilters
    );
    const isCurrentDVIRFiltersChanged = !_.isEqual(
      this.state.currentDVIRFilters,
      prevState.currentDVIRFilters
    );
    const isCurrentSafetyFiltersChanged = !_.isEqual(
      this.state.currentSafetyFilters,
      prevState.currentSafetyFilters
    );
    if (
      isSearchValueChanged ||
      isCurrentFiltersChanged ||
      isCurrentPMFiltersChanged ||
      isCurrentDVIRFiltersChanged ||
      isCurrentSafetyFiltersChanged
    ) {
      // update the query params with the current filters applied
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(
        'vehicleStatus',
        this.state.currentFilters.map((filter) => filter.key)
      );
      queryParams.set(
        'vehicleNextPmFilter',
        this.state.currentPMFilters.map((filter) => filter.filterKey)
      );
      queryParams.set(
        'vehicleDvir',
        this.state.currentDVIRFilters.map((filter) => filter.key)
      );
      queryParams.set(
        'vehicleSafetyInspections',
        this.state.currentSafetyFilters.map((filter) => filter.filterKey)
      );
      queryParams.set('vehicleSearch', searchValue || '');

      localStorage.setItem('vehiclesFilters', queryParams.toString());

      this.props.history.push({
        search: queryParams.toString(),
      });
    }
  }

  loadQueryParamsAndUpdateTable = () => {
    return new Promise((resolve) => {
      const queryParams = new URLSearchParams(
        localStorage.getItem('vehiclesFilters')
      );
      const formattedCurrentFilters = (stringKeys) =>
        stringKeys?.split(',').map((key) => ({
          key,
          label:
            this.filterOptions.find(
              (option) => String(option.value) === String(key)
            )?.text || key,
        }));
      const formattedCurrentPMFilters = (stringKeys) =>
        stringKeys?.split(',').map((key) => ({
          key,
          label:
            nextPMFilterOptions().find(
              (option) => String(option.value) === String(key)
            )?.text || key,
          filterKey: key,
        }));
      const formattedCurrentDVIRFilters = (stringKeys) =>
        stringKeys?.split(',').map((key) => ({
          key,
          label:
            dvirFilterOptions().find(
              (option) => String(option.value) === String(key)
            )?.text || key,
        }));
      const formattedCurrentSafetyFilters = (stringKeys) =>
        stringKeys?.split(',').map((key) => ({
          key,
          label:
            safetyInspectionsFilterOptions().find(
              (option) => String(option.value) === String(key)
            )?.text || key,
          filterKey: key,
        }));

      this.setState(
        {
          currentFilters: queryParams.get('vehicleStatus')
            ? formattedCurrentFilters(queryParams.get('vehicleStatus'))
            : [],
          currentPMFilters: queryParams.get('vehicleNextPmFilter')
            ? formattedCurrentPMFilters(queryParams.get('vehicleNextPmFilter'))
            : [],
          currentDVIRFilters: queryParams.get('vehicleDvir')
            ? formattedCurrentDVIRFilters(queryParams.get('vehicleDvir'))
            : [],
          currentSafetyFilters: queryParams.get('vehicleSafetyInspections')
            ? formattedCurrentSafetyFilters(
                queryParams.get('vehicleSafetyInspections')
              )
            : [],
          searchValue: queryParams.get('vehicleSearch') || '',
          searchKey: queryParams.get('vehicleSearch') ? 'carName' : null,
        },
        () => {
          // update the table with the current filters applied
          resolve();
        }
      );
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let column = null;
    let offset = (pagination.current - 1) * pagination.pageSize;

    if (sorter.columnKey) {
      let sortString = sorter.columnKey;
      const sortColumn = _.filter(this.columns, (el) => {
        return sorter.columnKey === (el.sortLabel || el.dataIndex || el.key);
      })[0];

      if (!sortColumn) {
        sortString =
          sortColumn.sortLabel || sortColumn.dataIndex || sortColumn.key;
      }

      if (!sorter.order) {
        column = undefined;
      } else {
        column = sorter.order === 'ascend' ? `${sortString}` : `-${sortString}`;
      }
    }

    this.setState({
      currentPagination: {
        offset,
        limit: pagination.pageSize,
        sort: column,
        filter: {
          exclude: 'issueList',
          ...filters,
        },
      },
    });

    this.loadVehicles({
      offset,
      limit: pagination.pageSize,
      sort: column,
      filter: {
        exclude: 'issueList',
        ...filters,
      },
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let column = null;
    let offset = (pagination.current - 1) * pagination.pageSize;

    if (sorter.columnKey) {
      let sortString = sorter.columnKey;

      const sortColumn = _.filter(this.columns, (el) => {
        return sorter.columnKey === (el.sortLabel || el.dataIndex || el.key);
      })[0];

      if (!_.isNil(sortColumn)) {
        sortString =
          sortColumn.sortLabel || sortColumn.dataIndex || sortColumn.key;
      }

      if (!sorter.order) {
        column = undefined;
      } else {
        column = sorter.order === 'ascend' ? `${sortString}` : `-${sortString}`;
      }
    }

    // if filtering by engine light, we need to change the filter key to engineLightStatus
    if (filters.engine_light) {
      filters.engineLightStatus = filters.engine_light;
      delete filters.engine_light;
    }

    const params = {
      offset,
      limit: pagination.pageSize,
      sort: column,
      filter: {
        exclude: 'issueList',
        ...filters,
      },
    };

    this.setState({
      currentPagination: pagination,
    });

    this.loadData(this.props.type, params);
  };

  onChangeCurrentFilters = () => {
    // update table filter based on currentFilters
    const filters = {
      car_status: [],
      nextPmFilter: [],
      dvirStatus: [],
      safetyNextPmFilter: [],
      engineLightStatus: [],
      issues: [],
      tagNames:[]
    };
    this.state.currentFilters.forEach((filter) => {
      filters['car_status'].push(filter.key);
    });
    this.state.currentPMFilters.forEach((filter) => {
      filters['nextPmFilter'].push(filter.filterKey);
    });
    this.state.currentDVIRFilters.forEach((filter) => {
      filters['dvirStatus'].push(filter.key);
    });
    this.state.currentSafetyFilters.forEach((filter) => {
      filters['safetyNextPmFilter'].push(filter.filterKey);
    });
    this.state.currentEngineLightFilters.forEach((filter) => {
      filters['engineLightStatus'].push(filter.filterKey);
    });
    this.state.currentIssuesFilters.forEach((filter) => {
      filters['issues'].push(filter.filterKey);
    });
    this.state.currentSubLocationFilters.forEach((filter) => {
      filters['tagNames'].push(filter.filterKey);
    });
    
    this.setState({ filteredInfo: filters });
    // update the columns
    this.vehicleColumns = this.vehicleColumns.map((column) => {
      if (column.key === 'car_status') {
        return {
          ...column,
          filters: this.filterOptions,
          filteredValue: filters['car_status'],
        };
      } else if (column.key === 'percentage') {
        return {
          ...column,
          filteredValue: filters['nextPmFilter'].map((filter) =>
            _.camelCase(filter)
          ),
        };
      } else if (column.key === 'dvir') {
        return {
          ...column,
          filteredValue: filters['dvirStatus'],
        };
      } else if (column.key === 'safetyInspections') {
        return {
          ...column,
          filteredValue: filters['safetyNextPmFilter'].map((filter) =>
            _.camelCase(filter)
          ),
        };
      } else if (column.key === 'engine_light') {
        return {
          ...column,
          filteredValue: filters['engineLightStatus'],
        };
      } else if (column.key === 'issues') {
        return {
          ...column,
          filteredValue: filters['issues'],
        };
      } else if (column.key === 'tagNames') {
        return {
          ...column,
          filteredValue: filters['tagNames'],
        };
      }
      return column;
    });

    this.loadData(this.props.type, {
      filter: filters,
    });
  };

  asyncGetCarsEngineLightStatus = async () => {
    try {
      const engineLightStatuses = await CarStore.fetchEngineLightStatusForCars(
        _.map(this.state.currentVehicles, 'id')
      );
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          const carEngineLightStatus = _.find(engineLightStatuses.data, {
            vin: car.vin,
          });
          car.currentEngineLightStatus = carEngineLightStatus || 'not-found';
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.currentEngineLightStatus = 'not-found';
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  asyncGetDVIR = async (vehiclesIds) => {
    try {
      let isTrailer = false;
      if (this.props.type === 'trailers') {
        isTrailer = true;
      }

      const dvirData = await CarStore.fetchDVIRsForCars(vehiclesIds, isTrailer);

      this.setState((prevState) => ({
        currentVehicles: prevState.currentVehicles.map((car) => {
          return {
            ...car,
            dvir: _.find(dvirData, { carId: car.id }),
          };
        }),
      }));
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.dvir = null;
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  asyncGetNextPM = async (vehiclesIds) => {
    try {
      const nextPMData = await CarStore.getPmScheduleSummaries(vehiclesIds);
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.nextPm = _.find(nextPMData, { carId: car.id });
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.nextPm = null;
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  trailerTableStore = new TableStore();

  loadData = async (type, filters = null) => {
    try {
      this.setState({
        customColumns: [],
        selectedAssets: [],
      });

      this.setState({ isLoadingData: true });

      if (_.isNil(filters)) {
        filters = {
          offset: 0,
          limit: 10,
          sort: null,
          filter: {},
        };
      }

      filters.offset = filters.offset || 0;
      filters.limit = filters.limit || 10;

      _.set(filters, 'filter.exclude', 'issueList');
      _.set(
        filters,
        'filter.car_type',
        type === 'vehicles' ? 'vehicle' : 'trailer'
      );

      const { searchValue, searchKey } = this.state;

      if (!_.isNil(searchValue)) {
        filters.filter = {
          ...filters.filter,
          [searchKey]: searchValue,
        };
      }

      const queryString = new URLSearchParams(filters.filter);
      if (filters.sort) queryString.set('sort', filters.sort);
      if (filters.offset) queryString.set('offset', filters.offset);
      if (filters.limit) queryString.set('limit', filters.limit);

      this.setState({ vehiclesApiUrl: queryString.toString() });

      const cleanFilterIfEmpty = (key) => {
        if (
          filters.filter[key] &&
          (!_.isArray(filters.filter[key]) || filters.filter[key].length === 0)
        ) {
          delete filters.filter[key];
        }
      };

      [
        'car_status',
        'nextPmFilter',
        'safetyNextPmFilter',
        'dvirStatus',
      ].forEach(cleanFilterIfEmpty);

      if (['percentage', '-percentage'].includes(_.get(filters, 'sort'))) {
        const sortDirection = _.get(filters, 'sort');
        filters.sortPm =
          sortDirection[0] === '-' ? 'percentage' : '-percentage';
        delete filters.sort;
      }

      if (_.get(filters, 'filter.percentage', null)) {
        filters.filter.nextPmFilter = filters.filter.percentage.map((filter) =>
          [
            'overdue',
            'dueSoon',
            'upcoming',
            'noActionNeeded',
            'setupPm',
          ].includes(filter)
            ? {
                overdue: 'Overdue',
                dueSoon: 'Due soon',
                upcoming: 'Upcoming',
                noActionNeeded: 'No action needed',
                setupPm: 'Setup PM',
              }[filter]
            : filter
        );
        delete filters.filter.percentage;
      }

      if (_.get(filters, 'filter.safetyInspections', null)) {
        filters.filter.safetyNextPmFilter = filters.filter.safetyInspections.map(
          (filter) =>
            ['overdue', 'dueSoon', 'upcoming', 'noActionNeeded'].includes(
              filter
            )
              ? {
                  overdue: 'Overdue',
                  dueSoon: 'Due soon',
                  upcoming: 'Upcoming',
                  noActionNeeded: 'No action needed',
                }[filter]
              : filter
        );
        delete filters.filter.safetyInspections;
      }

      if (_.get(filters, 'filter.dvir', null)) {
        filters.filter.dvirStatus = filters.filter.dvir;
        delete filters.filter.dvir;
      }

      let response;

      const tableStore = new TableStore();

      if (CurrentUserStore.user.role === 'customer') {
        response = await CarStore.getUserCarsForTable(
          filters,
          tableStore,
          CurrentUserStore.user.id,
          CurrentUserStore.user.shopId,
          {
            isAddNextPmSummary: false,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      } else {
        console.log('load vehicle', this.shopId);
        response = await CarStore.getShopCarsForTable(
          filters,
          tableStore,
          this.shopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: true,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      }

      if (!response) {
        return;
      }

      const setStateParams = {
        contentType: type === 'vehicles' ? 'vehicle' : 'trailer',
        paginationMeta: {
          ...this.state.paginationMeta,
          total: response.meta.count,
          current: filters.offset / 10 + 1,
        },
      };

      await this.getColumns(`${type}-table`);

      const vehicles = response.data.map((car) => ({
        ...car,
        key: car.id,
      }));

      let vehiclesIds = _.map(vehicles, 'id').join(',');

      setStateParams.currentVehicles = vehicles;

      this.setState(setStateParams, () => {
        this.asyncGetDVIR(vehiclesIds);
        this.asyncGetNextPM(vehiclesIds);

        if (type === 'vehicles') {
          this.asyncGetCarsEngineLightStatus();
        }
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingData: false });
    }
  };

  getColumns = async (tableName) => {
    try {
      const settings = await ShopStore.getShopSettings(this.shopId);

      const tables = _.get(settings.result, '[0].settings.table');
      if (tables) {
        const currentTable = _.find(tables, { name: tableName });
        if (currentTable) {
          this.setState({
            customColumns: currentTable.columns,
          });
        }
      }
    } catch (e) {
      console.log('wrong table');
    } finally {
      console.log('finish loaded table');
    }
  };

  setFilter = (key, value) => {
    // force to reset table
    this.setState({
      searchKey: key,
      searchValue: value,
    });
    this.refreshTableOffset = true;
  };

  openShopStatusesModal = (e) => {
    this.stopPropagation(e);
    if (this.state.isLoadingStatuses) {
      return;
    }
    this.setState({
      newShopCarStatuses:
        this.state.currentShopStatuses.length > 0
          ? JSON.parse(JSON.stringify(this.state.currentShopStatuses))
          : CarStatusStore.getDefaultStatusList,
    });
    AppStore.openModals.set('SHOP_STATUSES_MODAL', true);
  };

  onDownloadVehicleHealthReport = async () => {
    try {
      AppStore.addLoading('Getting Health Report');
      await CarStore.data
        .get(this.state.selectedCarId)
        .downloadVehicleHealthReport(
          this.state.issueReportStatus,
          1000,
          this.state.issueSources
        );
      AppStore.addSuccess('Report downloaded.');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Getting report...');
    }
  };

  onPrintVehicleHealthReport = async () => {
    try {
      AppStore.addLoading('Getting Health Report');
      await CarStore.data
        .get(this.state.selectedCarId)
        .printVehicleHealthReport(
          this.state.issueReportStatus,
          1000,
          this.state.issueSources
        );
      AppStore.addSuccess('Report printing.');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Getting report...');
    }
  };

  selectStatusAsDefault = (event, key) => {
    if (event.target.checked) {
      return this.setState({
        newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
          if (status.key === key) {
            status.is_selected = true;
            status.is_default = true;
          } else {
            status.is_selected = false;
            status.is_default = false;
          }
          return status;
        }),
      });
    }
    return this.setState({
      newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
        if (status.key === key) {
          status.is_selected = false;
          status.is_default = false;
        }
        return status;
      }),
    });
  };

  selectStatusToShowVendors = async (event, id) => {
    const { checked } = event.target;

    const shopId = ShopStore.currentShop.id;

    this.setState((prevState) => ({
      showVendorTitle: checked,
      newShopCarStatuses: prevState.newShopCarStatuses.map((status) => ({
        ...status,
        is_show_vendor: status.id === id ? checked : false,
      })),
    }));
  };

  setQueryToRoutineQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.set('source', 'routine');
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  removeQueryToRoutineQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete('source');
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  clearAllQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete('status');
    queryParams.delete('dvir');
    queryParams.delete('tagNames');
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  clearSpecificQueryParam = (param) => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete(param);
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  onClearAllFilters = () => {
    // clear all query params
    this.clearAllQueryParams();
    this.setState(
      {
        currentFilters: [],
        currentPMFilters: [],
        currentSafetyFilters: [],
        currentEngineLightFilters: [],
        currentDVIRFilters: [],
        selectedAssets: [],
        currentIssuesFilters: [],
        currentSubLocationFilters: [],
        filteredInfo: {}
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  onCloseFilter = (filter) => {
    this.setState(
      {
        currentFilters: this.state.currentFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentPMFilters: this.state.currentPMFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentSafetyFilters: this.state.currentSafetyFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentEngineLightFilters: this.state.currentEngineLightFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentIssuesFilters: this.state.currentIssuesFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentSubLocationFilters: this.state.currentSubLocationFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentDVIRFilters: this.state.currentDVIRFilters.filter(
          (f) => f.key !== filter.key
        ),
      },
      () => {
        if (this.state.currentFilters.length === 0) {
          this.clearSpecificQueryParam('status');
        }
        if (this.state.currentDVIRFilters.length === 0) {
          this.clearSpecificQueryParam('dvir');
        }
        if (this.state.currentPMFilters.length === 0) {
          this.clearSpecificQueryParam('percentage');
        }
        if (this.state.currentSafetyFilters.length === 0) {
          this.clearSpecificQueryParam('safetyInspections');
        }
        if (this.state.currentEngineLightFilters.length === 0) {
          this.clearSpecificQueryParam('engineLight');
        }
        if (this.state.currentIssuesFilters.length === 0) {
          this.clearSpecificQueryParam('issues');
        }
        if (this.state.currentSubLocationFilters.length === 0) {
          this.clearSpecificQueryParam('tagNames');
        }
        this.onChangeCurrentFilters();
      }
    );
  };

  onAddAsset = async (assetsToAdd) => {
    AppStore.openModals.set('ADD_ASSET_MODAL', false);
    // save the list of trailers using the trailer create endpoint
    if (assetsToAdd.length) {
      try {
        let firstAssetAddedId = null;
        for (const asset of assetsToAdd) {
          const response = await CarStore.createGenericAsset({
            vin: asset.vin,
            source: 'user',
            assetType: asset.assetType,
            shopId: ShopStore.currentShop.id,
            licensePlate: '',
            baseMileage: asset.mileage || 0,
            unitId: asset.unitId,
            engineHours: asset.engineHours || 0,
            make: asset.make,
            model: asset.model,
            year: asset.year,
          });
          if (!firstAssetAddedId) {
            firstAssetAddedId = isArray(response)
              ? response[0].id
              : response.id;
          }
        }
        AppStore.addSuccess('Assets added successfully');
        this.props.loadTotalAssetsCount();
      } catch (error) {
        Logger.log(error);
        AppStore.addError('Error adding assets');
      } finally {
        this.handleSearchData();
      }
    }
  };

  // Attribute for the vehicle table
  vehicleRowSelection = {
    onChange: (selectedRowKeys) => {
      this.setState({
        selectedAssets: selectedRowKeys,
      });
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  mapIssuesTable = {};

  handleArchiveAssets = async (notes) => {
    try {
      this.setState({ isArchivingAssets: true });
      await CarStore.archiveAssets({
        assetIds: this.state.selectedAssets,
        notes,
        shopId: ShopStore.currentShop.id,
      });
      notification.success({
        message: 'Asset(s) Archived',
        description: 'Assets archived successfully',
        icon: <Icon type="check-circle" style={{ color: 'green' }} />,
      });
      this.setState({ isArchiveAssetsModalVisible: false });
      this.handleSearchData();
      this.props.loadTotalAssetsCount();
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'Error archiving asset(s)',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />,
      });
    } finally {
      this.setState({ isArchivingAssets: false });
    }
  };

  // Toggle for Issues
  handleCarIssuesToggle = (record, type) => {
    const {
      expandedCarIds,
      expandedPMCarIds,
      expandedSafetyInspectionIds,
    } = this.state;

    // Toggle issues expansion
    let newExpandedCarIds = [...expandedCarIds];
    if (newExpandedCarIds.includes(record.id)) {
      newExpandedCarIds = newExpandedCarIds.filter((id) => id !== record.id);
    } else {
      // When expanding issues, collapse PMs and Safety Inspections for this car
      newExpandedCarIds.push(record.id);
      this.setState({
        expandedPMCarIds: expandedPMCarIds.filter((id) => id !== record.id),
        expandedSafetyInspectionIds: expandedSafetyInspectionIds.filter(
          (id) => id !== record.id
        ),
      });
    }

    if (type === 'pm') {
      const queryParams = new URLSearchParams(
        this.props.history.location.search
      );
      const hasRoutineSource = queryParams.get('source');
      if (!hasRoutineSource) {
        if (this.mapIssuesTable[record.id]) {
          this.mapIssuesTable[record.id].setState({
            source: 'routine',
          });
          this.mapIssuesTable[record.id].allIssuesTableStore.reset();
        }
        this.setQueryToRoutineQueryParams();
      }
    } else {
      const queryParams = new URLSearchParams(
        this.props.history.location.search
      );
      const hasRoutineSource = queryParams.get('source');
      if (hasRoutineSource) {
        if (this.mapIssuesTable[record.id]) {
          this.mapIssuesTable[record.id].setState({
            source: 'all',
          });
          this.mapIssuesTable[record.id].allIssuesTableStore.reset();
        }
        this.removeQueryToRoutineQueryParams();
      }
    }

    this.setState({ expandedCarIds: newExpandedCarIds });
  };

  // Toggle for PMs
  handleCarPMsToggle = (car) => {
    const {
      expandedCarIds,
      expandedPMCarIds,
      expandedSafetyInspectionIds,
    } = this.state;

    // Toggle PMs expansion
    let newExpandedPMCarIds = [...expandedPMCarIds];
    if (newExpandedPMCarIds.includes(car.id)) {
      newExpandedPMCarIds = newExpandedPMCarIds.filter((id) => id !== car.id);
    } else {
      // When expanding PMs, collapse Issues and Safety Inspections for this car
      newExpandedPMCarIds.push(car.id);
      this.setState({
        expandedCarIds: expandedCarIds.filter((id) => id !== car.id),
        expandedSafetyInspectionIds: expandedSafetyInspectionIds.filter(
          (id) => id !== car.id
        ),
      });
    }

    this.setState({ expandedPMCarIds: newExpandedPMCarIds });
  };

  // Toggle for Safety Inspections
  handleSafetyInspectionToggle = (car) => {
    const {
      expandedCarIds,
      expandedPMCarIds,
      expandedSafetyInspectionIds,
    } = this.state;

    // Toggle Safety Inspections expansion
    let newExpandedSafetyInspectionIds = [...expandedSafetyInspectionIds];
    if (newExpandedSafetyInspectionIds.includes(car.id)) {
      newExpandedSafetyInspectionIds = newExpandedSafetyInspectionIds.filter(
        (id) => id !== car.id
      );
    } else {
      // When expanding Safety Inspections, collapse Issues and PMs for this car
      newExpandedSafetyInspectionIds.push(car.id);
      this.setState({
        expandedCarIds: expandedCarIds.filter((id) => id !== car.id),
        expandedPMCarIds: expandedPMCarIds.filter((id) => id !== car.id),
      });
    }

    this.setState({
      expandedSafetyInspectionIds: newExpandedSafetyInspectionIds,
    });
  };

  convertQueryStringToObject = (queryString) => {
    const params = new URLSearchParams(queryString);
    const shopId = ShopStore.currentShop.id;
    const result = { offset: 0, shopId };

    for (const [key, value] of params.entries()) {
      if (value) {
        // Convert comma-separated values into arrays, or single values as strings
        result[key] = value.includes(',') ? value.split(',') : value;
      }
    }

    if (result.dvirStatus) {
      result.dvirStatus = Array.isArray(result.dvirStatus)
        ? result.dvirStatus
        : [result.dvirStatus];
    }

    if (result.car_status) {
      result.car_status = Array.isArray(result.car_status)
        ? result.car_status
        : [result.car_status];
    }

    if (result.nextPmFilter) {
      result.nextPmFilter = Array.isArray(result.nextPmFilter)
        ? result.nextPmFilter
        : [result.nextPmFilter];
    }

    return result;
  };

  handleExportCSV = async () => {
    if (this.state.selectedAssets.length === 0) {
      console.log('No items selected');
      return;
    }

    // console.log("Exporting URL:", this.state.vehiclesApiUrl);

    const { selectedAssets, currentVehicles, paginationMeta } = this.state;
    const pageTotal = paginationMeta.total;

    const params = this.convertQueryStringToObject(this.state.vehiclesApiUrl);
    params.limit = pageTotal;

    if (pageTotal > 10) {
      if (selectedAssets.length === 10) {
        try {
          this.setState({ isExportingCSV: true });
          const result = await CarStore.getCarsForCSV(params);
          const csvContent = this.convertToCSV(result.data);
          this.downloadCSV(csvContent);
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({ isExportingCSV: false });
        }
      } else {
        console.log(`Start exporting without calling Api!`);
        this.startExportCSVWithoutCallingAPI(currentVehicles, selectedAssets);
      }
    } else {
      console.log(`Start exporting without calling Api!`);
      this.startExportCSVWithoutCallingAPI(currentVehicles, selectedAssets);
    }
  };

  handleExportTrailerCSV = async () => {
    if (this.state.selectedAssets.length === 0) {
      console.log('No trailers selected');
      return;
    }

    console.log(`exporting trailers...`);
    console.log('Exporting URL:', this.state.vehiclesApiUrl);

    const { selectedAssets, currentVehicles, paginationMeta } = this.state;
    const pageTotal = paginationMeta.total;

    const params = this.convertQueryStringToObject(this.state.vehiclesApiUrl);
    params.limit = pageTotal;
    if (pageTotal > 10) {
      if (selectedAssets.length === 10) {
        try {
          this.setState({ isExportingCSV: true });
          params.trailers = true;
          const result = await CarStore.getCarsForCSV(params);
          const csvContent = this.convertToTrailerCSV(result.data);
          this.downloadCSV(csvContent);
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({ isExportingCSV: false });
        }
      } else {
        console.log(`Start exporting selected trailers without calling API!`);
        this.startExportCSVWithoutCallingAPITrailers(
          currentVehicles,
          selectedAssets,
          this.convertTrailerToCSV
        );
      }
    } else {
      console.log(`Start exporting selected trailers without calling API!`);
      this.startExportCSVWithoutCallingAPITrailers(
        currentVehicles,
        selectedAssets,
        this.convertTrailerToCSV
      );
    }
  };

  startExportCSVWithoutCallingAPITrailers = (
    currentVehicles,
    selectedAssets
  ) => {
    const selectedData = currentVehicles.filter((car) =>
      selectedAssets.includes(car.id)
    );

    const csvContent = this.convertToTrailerCSV(selectedData);

    this.downloadCSV(csvContent);
  };

  startExportCSVWithoutCallingAPI = (currentVehicles, selectedAssets) => {
    const selectedData = currentVehicles.filter((car) =>
      selectedAssets.includes(car.id)
    );

    const csvContent = this.convertToCSV(selectedData);

    this.downloadCSV(csvContent);
  };

  convertToTrailerCSV = (data) => {
    const headers = [
      'Trailer Name | Mileage | Engine Hours',
      'DVIR Status',
      'Next PM',
    ];
    // Determine whether to use kilometers or miles
    const userSettings =
      CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'miles';

    const rows = data.map((car) => {
      const totalKmMi =
        userSettings === 'km'
          ? `${Math.round(Number(car.totalMileage))} km`
          : `${(Number(car.totalMileage) * 0.621371).toFixed(2)} mi`;

      const engineHours = Math.round(car.engineHours / 3600);
      let carName = car.carName || '--';
      carName = `${carName} | ${totalKmMi} | ${engineHours} engine hours`;

      // Handle DVIR status
      let safetyStatus =
        car.dvir && car.dvir.record
          ? car.dvir.record.safetyStatus || 'Unknown'
          : '--';
      const totalDefects =
        car.dvir && car.dvir.record?.payload?.trailerDefects
          ? car.dvir.record.payload.trailerDefects.length
          : 0;

      if (totalDefects > 0) {
        safetyStatus = `${safetyStatus} | Defects: ${totalDefects}`;
      }

      // Handle Next PM
      const {
        due_engine_hours,
        due_mileage,
        due_time_in_s,
        rule_type,
        pm_percent,
        pm_status,
      } = car;
      let nextPM = '--'; // Default value when no PM data is available

      if (pm_percent !== null && pm_percent !== undefined) {
        let displayValue;
        let displayText;

        if (rule_type === 'time') {
          // Calculate time remaining in days or months
          const now = new Date();
          const dueAt = moment.unix(due_time_in_s);
          const diffInDays = dueAt.diff(now, 'days');
          const diffInMonths = Math.ceil(diffInDays / 30.4375);

          if (diffInMonths < 1) {
            displayValue = Math.abs(diffInDays);
            displayText = 'day(s)';
          } else {
            displayValue = Math.abs(diffInMonths);
            displayText = 'month(s)';
          }
        } else {
          // Calculate remaining mileage or engine hours
          const hoursRemainingValue =
            rule_type === 'engine_hours'
              ? Math.ceil(
                  Math.abs(
                    (Number(due_engine_hours) - Number(car.engineHours)) / 3600
                  )
                )
              : undefined;

          const kmRemaining =
            rule_type === 'mileage'
              ? Math.ceil(
                  Math.abs(Number(due_mileage) - Number(car.totalMileage))
                )
              : undefined;

          const milesRemaining = kmRemaining
            ? Math.ceil(kmRemaining / 1.60934)
            : undefined;
          const milesOrKm =
            userSettings === 'km' ? kmRemaining : milesRemaining;
          const milesOrKmText = userSettings === 'km' ? 'km' : 'miles';

          displayText =
            milesOrKm !== undefined ? milesOrKmText : 'engine hours';
          displayValue =
            milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
        }

        nextPM = `${pm_percent}% (${pm_status} by ${displayValue} ${displayText})`;
      }

      return [carName, safetyStatus, nextPM];
    });

    const csvArray = [headers, ...rows];
    return csvArray.map((e) => e.join(',')).join('\n');
  };

  convertToCSV = (data) => {
    const headers = [
      'Car Name | Mileage | Engine Hours',
      'DVIR Status',
      'Engine Light Status',
      'Issues',
      'Next PM',
      'Car Status, Notes',
    ];
    // Determine whether to use kilometers or miles
    const userSettings =
      CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'miles';

    const rows = data.map((car) => {
      const totalKmMi =
        userSettings === 'km'
          ? `${Math.round(Number(car.totalMileage))} km`
          : `${(Number(car.totalMileage) * 0.621371).toFixed(2)} mi`;
      const engineHours = Math.round(car.engineHours / 3600);
      const notes = car.notes || '--';
      let carName = car.carName || '--';
      carName = `${carName} | ${totalKmMi} | ${engineHours} engine hours`;

      // Handle DVIR status
      let safetyStatus =
        car.dvir && car.dvir.record
          ? car.dvir.record.safetyStatus || 'Unknown'
          : '--';
      const totalDefects =
        car.dvir && car.dvir.record && car.dvir.record.payload?.vehicleDefects
          ? car.dvir.record.payload.vehicleDefects.length
          : 0;
      if (totalDefects > 0) {
        safetyStatus = `${safetyStatus} | Defects: ${totalDefects}`;
      }

      // Handle Engine Light Status
      const engineLightStatus = car.currentEngineLightStatus?.payload
        ? [
            car.currentEngineLightStatus.payload.faultState === 'active'
              ? 'Active'
              : 'Inactive',
            car.currentEngineLightStatus.payload.redStopLamp
              ? 'Red Stop Lamp: On'
              : '',
            car.currentEngineLightStatus.payload.malfunctionLamp
              ? 'Malfunction Lamp: On'
              : '',
            car.currentEngineLightStatus.payload.amberWarningLamp
              ? 'Amber Warning Lamp: On'
              : '',
            car.currentEngineLightStatus.payload.protectWarningLamp
              ? 'Protect Warning Lamp: On'
              : '',
            car.currentEngineLightStatus.payload.generalVehicleWarningLight
              ? 'General Warning: On'
              : '',
          ]
            .filter((status) => status !== '')
            .join('; ')
        : '--';

      // Handle Issues
      const issueCounts = car.issueCounts
        ? `Critical: ${car.issueCounts.critical || 0} | Major: ${car.issueCounts
            .major || 0} | Minor: ${car.issueCounts.minor || 0}`
        : 'No Issues';

      // Handle Next PM
      let nextPM = '--';
      const {
        due_engine_hours,
        due_mileage,
        due_time_in_s,
        rule_type,
        pm_percent,
        pm_status,
      } = car;

      if (pm_percent !== null && pm_percent !== undefined) {
        let displayValue;
        let displayText;

        if (rule_type === 'time') {
          const now = new Date();
          const dueAt = moment.unix(due_time_in_s);
          const diffInDays = dueAt.diff(now, 'days');
          const diffInMonths = Math.ceil(diffInDays / 30.4375);

          if (diffInMonths < 1) {
            displayValue = Math.abs(diffInDays);
            displayText = 'day(s)';
          } else {
            displayValue = Math.abs(diffInMonths);
            displayText = 'month(s)';
          }
        } else {
          const hoursRemainingValue =
            rule_type === 'engine_hours'
              ? Math.ceil(
                  Math.abs(
                    (Number(due_engine_hours) - Number(car.engineHours)) / 3600
                  )
                )
              : undefined;

          const kmRemaining =
            rule_type === 'mileage'
              ? Math.ceil(
                  Math.abs(Number(due_mileage) - Number(car.totalMileage))
                )
              : undefined;

          const milesRemaining = kmRemaining
            ? Math.ceil(kmRemaining / 1.60934)
            : undefined;
          const milesOrKm =
            userSettings === 'km' ? kmRemaining : milesRemaining;
          const milesOrKmText = userSettings === 'km' ? 'km' : 'miles';

          displayText =
            milesOrKm !== undefined ? milesOrKmText : 'engine hours';
          displayValue =
            milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
        }

        nextPM = `${pm_percent}% (${pm_status} by ${displayValue} ${displayText})`;
      }

      // Handle Car Status
      const carStatus = this.getCarStatus(car.carStatus) || 'Running';

      return [
        carName,
        safetyStatus,
        engineLightStatus,
        issueCounts,
        nextPM,
        carStatus,
        notes,
      ]; // Include Car Status in the return value
    });

    const csvArray = [headers, ...rows];
    return csvArray.map((e) => e.join(',')).join('\n');
  };

  getCarStatus = (carStatus) => {
    if (carStatus === 'new-1' || carStatus === 'new') {
      return 'Running';
    }

    return carStatus;
  };

  downloadCSV = (csvContent) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    const now = new Date();
    const timestamp = now
      .toISOString()
      .replace(/[-:T]/g, '')
      .split('.')[0];

    const filename = `exported_vehicles_data_${timestamp}.csv`;

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  handleFormSubmit = (values) => {
    let total = 0;

    if (values.car_status) {
      total += values.car_status.length;
    }

    if (values.dvirStatus) {
      total += values.dvirStatus.length;
    }

    if (values.nextPmFilter) {
      total += values.nextPmFilter.length;
    }

    this.setState({ popoverVisible: false });
    this.setState({ countFiltersSelected: total });
  };

  handleChangeFormFilter = (key, value) => {
    let filters = {};
    if (key === 'car_status') {
      filters = { ...filters, ...{ car_status: value } };
    }

    if (key === 'dvirStatus') {
      filters = { ...filters, ...{ dvirStatus: value } };
    }

    if (key === 'nextPmFilter') {
      filters = { ...filters, ...{ nextPmFilter: value } };
    }

    this.loadData(this.props.type, filters);
  };

  togglePopoverVisibility = (visible) => {
    this.setState({ popoverVisible: visible });
  };

  toggleDropdown = (carId, visible) => {
    this.setState((prevState) => ({
      dropdownVisibility: {
        ...prevState.dropdownVisibility,
        [carId]: visible, // Atualiza a visibilidade apenas do carro específico
      },
    }));
  };

  handlerResetCustomColumnTable = async (data) => {
    try {
      AppStore.addLoading('Setting Table Columns');
      const settings = await ShopStore.getShopSettings(this.shopId);

      let tables = _.get(settings.result, '[0].settings.table', []);

      let currentTableIndex = _.findIndex(tables, { name: data.name });
      if (currentTableIndex !== -1) {
        tables.splice(currentTableIndex, 1);
        _.set(settings.result, '[0].settings.table', tables);
        await ShopStore.upsertShopSettings(
          this.shopId,
          settings.result[0].settings
        );
        this.setState((prevState) => ({
          customColumns: [],
        }));
      } else {
        AppStore.addError('Unable to make the change, please try again!');
      }
    } catch (e) {
    } finally {
      AppStore.removeLoading('');
    }
  };

  handlerCustomColumnTable = async (data) => {
    try {
      AppStore.addLoading('Setting Table Columns');
      const settings = await ShopStore.getShopSettings(this.shopId);

      let tables = _.get(settings.result, '[0].settings.table', []);

      let currentTable = _.find(tables, { name: data.name });

      if (currentTable) {
        currentTable.columns = data.columns;
      } else {
        tables.push(data);
      }

      _.set(settings.result, '[0].settings.table', tables);

      await ShopStore.upsertShopSettings(
        this.shopId,
        settings.result[0].settings
      );

      this.setState({
        customColumns: data.columns,
      });

      AppStore.addSuccess('Saved successfully!');
    } catch (e) {
      console.log('error');
    } finally {
      AppStore.removeLoading('');
    }
  };

  render() {
    const { device, orientation } = DeviceStore;
    let newColumns;

    const statusOptions = [
      { label: 'New', value: 'new' },
      { label: 'Waiting For Invoice', value: 'waitingForApproval' },
      { label: 'Done', value: 'done' },
    ];

    const sourceOptions = [
      { label: 'Engine Codes', value: 'dtc' },
      { label: 'Routine/OEM Recommended Services', value: 'routine' },
      { label: 'Recall', value: 'recall' },
      { label: 'Fleet Manager/Driver Reported', value: 'user' },
      { label: 'Pitstop Algorithm Generated Services', value: 'algorithm' },
      { label: 'Service Records', value: 'archive' },
      { label: 'Light Vehicle DTCs', value: 'lightVehicleDTC' },
      { label: 'Heavy Vehicle DTCs', value: 'heavyVehicleDTC' },
    ];

    this.backupColumns =
      this.props.type === 'vehicles'
        ? this.vehicleColumns
        : this.props.type === 'trailers'
        ? this.trailerColumns
        : [];

    if (this.state.customColumns.length > 0) {
      newColumns = this.backupColumns.filter((col) =>
        this.state.customColumns.includes(col.key)
      );
    } else if (device === 'tablet' && orientation === 'portrait') {
      const allowedTabletColumn = [
        'indicator',
        'car_name',
        'engine_light',
        'issues',
        'dvir',
        'actions',
      ];
      const renameColumn = [
        {
          key: 'car_name',
          title: 'Unit Id',
        },
        {
          key: 'engine_light',
          title: 'Engine Light',
        },
        {
          key: 'dvir',
          title: 'DIVR',
        },
      ];
      newColumns = this.vehicleColumns
        .filter((c) => allowedTabletColumn.includes(c.key))
        .map((col) => {
          const renamedColumn = renameColumn.find((rn) => rn.key === col.key);
          if (renamedColumn) {
            return {
              ...col,
              title: renamedColumn.title,
            };
          }

          return col;
        });
    } else {
      newColumns = this.backupColumns;
    }

    const headerTable = () => {
      const { device, orientation } = DeviceStore;
      return (
        <>
          {device === 'tablet' && orientation === 'portrait' && (
            <Row style={{ marginBottom: 24 }}>
              <Col span={24}>
                <>
                  <AppAlert
                    message={
                      <>
                        <Text strong>
                          Some elements are now shown in portrait view
                        </Text>
                        <br />
                        <Text>
                          To view all columns and reports, use the device in
                          landscape
                        </Text>
                      </>
                    }
                  />
                </>
              </Col>
            </Row>
          )}
          <Row
            type="flex"
            justify={device === 'mobile' ? 'space-between' : 'start'}
            style={{ marginBottom: 10 }}
          >
            {device === 'mobile' && (
              <Col span={9}>
                <FormFilter
                  type={this.props.type}
                  filterOptions={this.filterOptions}
                  dvirFilterOptions={dvirFilterOptions()}
                  nextPMFilterOptions={nextPMFilterOptions()}
                  onSubmit={this.handleFormSubmit}
                  onChangeFilter={this.handleChangeFormFilter}
                  onClear={this.onClearAllFilters}
                  popoverVisible={this.state.popoverVisible}
                  togglePopoverVisibility={this.togglePopoverVisibility}
                  countFiltersSelected={this.state.countFiltersSelected}
                />
              </Col>
            )}
            <Col span={device === 'mobile' ? 14 : 8}>
              <Row>
                <Col
                  span={
                    this.props.type !== 'vehicles' && device !== 'mobile'
                      ? 14
                      : 14
                  }
                >
                  <Search
                    placeholder={`Search ${
                      this.props.type === 'vehicles' ? 'Vehicle' : 'Trailer'
                    } ID`}
                    value={this.state.searchValue}
                    onChange={(event) => {
                      this.setState({
                        searchValue: event.target.value,
                        searchKey: 'carName',
                      });
                    }}
                  />
                </Col>
                {/* this.props.type !== 'vehicles' &&  */}
                {device !== 'mobile' ? (
                  <Col span={8}>
                    <Button
                      style={{ marginLeft: '1rem' }}
                      onClick={() => {
                        // open modal to add trailer
                        AppStore.openModals.set('ADD_ASSET_MODAL', true);
                      }}
                    >
                      <Icon type="plus" />
                      Add Asset
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            {// only show notification if it hasn't been viewed yet
            localStorage.getItem('viewedIssuesNotification') !== 'true' && (
              <Col span={24}>
                <NotificationBanner
                  message={
                    <>
                      <Text strong>
                        How you{' '}
                        <Text
                          strong
                          style={{ fontWeight: 'bold', color: 'black' }}
                        >
                          view issues
                        </Text>{' '}
                        has changed!
                      </Text>
                      <br />
                      <Text>
                        To view issues associated with an asset, click “Show
                        Issues” drop down in the Issues column. To hide those
                        issues, click “Hide Issues”
                      </Text>
                    </>
                  }
                  onClose={() => {
                    // save to local storage that the notification was already shown
                    localStorage.setItem('viewedIssuesNotification', 'true');
                  }}
                />
              </Col>
            )}
          </Row>

          <Row
            type="flex"
            style={{ marginBottom: 10 }}
            gutter={[16, 16]}
            justify="space-between"
          >
            <Col>
              {!CurrentUserStore.isTechnician && (
                <Button
                  type="primary"
                  size="small"
                  disabled={this.state.selectedAssets.length === 0}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.setState({
                      isArchiveAssetsModalVisible: true,
                      archivingAssetType: this.props.type,
                    });
                  }}
                  data-test="archive-vehicles-button"
                >
                  Archive Assets
                </Button>
              )}

              <Button
                type="primary"
                size="small"
                disabled={this.state.selectedAssets.length === 0}
                style={{ marginRight: 10 }}
                onClick={
                  this.props.type === 'vehicles'
                    ? this.handleExportCSV
                    : this.handleExportTrailerCSV
                }
                data-test="export-csv-button"
              >
                Export CSV
              </Button>

              <Modal
                title="Exporting CSV..."
                visible={this.state.isExportingCSV}
                footer={null}
                closable={false}
                centered
              >
                <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  <p>Processing your CSV export. Please wait...</p>
                </div>
              </Modal>

              {this.combinedCurrentFilters.length === 0 ? (
                <Button disabled size="small" style={{ marginRight: 10 }}>
                  No filters selected
                </Button>
              ) : (
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => this.onClearAllFilters()}
                >
                  Clear all filters
                </Button>
              )}
              {/* Add badges with current selected filters here */}
              {this.combinedCurrentFilters.map((filter) => {
                return (
                  <Tag
                    key={filter.key}
                    className="filter-tag"
                    closable
                    onClose={() => this.onCloseFilter(filter)}
                  >
                    {filter.label}
                  </Tag>
                );
              })}
            </Col>
            <Col>
              <CustomTableColumn
                title={'Customize column assets'}
                shopId={this.shopId}
                fixedColumns={
                  this.props.type === 'vehicles'
                    ? ['car_name']
                    : ['car_name', 'actions', 'indicator']
                }
                key={`custom-table-${this.props.type}-${new Date()}`}
                tableName={`${this.props.type}-table`}
                columns={
                  this.props.type === 'vehicles'
                    ? this.vehicleColumns
                    : this.trailerColumns
                }
                defaultColumns={this.state.customColumns}
                onSubmit={this.handlerCustomColumnTable}
                onReset={this.handlerResetCustomColumnTable}
              />
            </Col>
          </Row>
        </>
      );
    };

    return (
      <IndicatorTableWrapper>
        {headerTable()}

        {device === 'mobile' ? (
          <>
            <List
              dataSource={this.state.currentVehicles}
              loading={this.state.isLoadingData}
              pagination={{
                ...this.state.paginationMeta,
                onChange: this.handleCardsChange,
              }}
              renderItem={(item) => {
                const isExpanded = this.state.expandedCards[item.id];
                if (this.props.type === 'vehicles') {
                  return (
                    <CardVehicle
                      key={item.id}
                      car={item}
                      isExpanded={isExpanded}
                      dropdown={() => this.StatusDropdown(item, 'vehicles')}
                      toggleCard={this.toggleCardExpansion}
                      saveNotes={this.saveCarNotes}
                      nextPm={renderNextPMDueInPercentage(
                        item,
                        this.handleCarIssuesToggle,
                        this.state.expandedCarIds
                      )}
                      actions={
                        <Row
                          type="flex"
                          justify="space-between"
                          align="middle"
                          gutter={[16, 0]}
                        >
                          <Col span={12}>
                            <Button
                              type="primary"
                              ghost
                              block
                              onClick={() =>
                                this.setState(
                                  { selectedCarId: item.id },
                                  () => {
                                    AppStore.openModals.set(
                                      'SHARE_VEHICLE_HEALTH_REPORT_MODAL',
                                      true
                                    );
                                  }
                                )
                              }
                            >
                              Share Report
                            </Button>
                          </Col>

                          <Col span={12}>
                            <Button
                              type="primary"
                              block
                              onClick={() =>
                                this.setState(
                                  { selectedCarId: item.id },
                                  () => {
                                    this.setState(
                                      {
                                        isPrintHealthReport: false,
                                      },
                                      () => {
                                        this.onDownloadVehicleHealthReport();
                                      }
                                    );
                                  }
                                )
                              }
                            >
                              Download Report
                            </Button>
                          </Col>
                        </Row>
                      }
                    />
                  );
                } else {
                  return (
                    <CardTrailer
                      key={item.id}
                      trailer={item}
                      isExpanded={isExpanded}
                      toggleCard={this.toggleCardExpansion}
                      nextPm={renderNextPMDueInPercentage(
                        item,
                        this.handleCarIssuesToggle,
                        this.state.expandedCarIds
                      )}
                    />
                  );
                }
              }}
            />
          </>
        ) : (
          <TableContainer
            rowKey="id"
            data-test={`${this.props.type}-table`}
            columns={newColumns}
            dataSource={this.state.currentVehicles}
            rowSelection={this.vehicleRowSelection}
            loading={this.state.isLoadingData}
            pagination={this.state.paginationMeta}
            onChange={this.handleTableChange}
            expandedRowRender={(car) => {
              // Check if the current car ID is in the expanded PM car list
              const isPMExpanded = this.state.expandedPMCarIds.includes(car.id);
              const isSafetyExpanded = this.state.expandedSafetyInspectionIds.includes(
                car.id
              );
              // Render the PM-related view when PMs are expanded
              if (isPMExpanded) {
                return (
                  <div>
                    <div className="pm-table-wrapper">
                      <PMTable
                        carId={car.id}
                        ref={this.pmTableRef}
                        onIssuesListChange={() => {
                          // Custom functionality when issues list changes
                          this.handleSearchData();
                        }}
                      />
                    </div>
                    <PMFeedbackModal
                      id="pm-feedback-modal"
                      title={IssueStore.currentPMFeedbacks.title}
                      currentStep={IssueStore.currentPMFeedbacks.currentStep}
                      pmsToReceiveFeedback={
                        IssueStore.currentPMFeedbacks.pmsToReceiveFeedback
                      }
                      isModalVisible={
                        IssueStore.currentPMFeedbacks.isModalVisible
                      }
                      onSave={() => {
                        this.handleOnChangeCurrentFiltersDebouncer();
                        this.pmTableRef.current.fetchData(1, 8, {}, []);
                      }}
                    />
                  </div>
                );
              }

              // Render the Safety Inspection-related view when Safety Inspections are expanded
              if (isSafetyExpanded) {
                return (
                  <div className="safety-inspection-wrapper">
                    <PMTable
                      ref={this.pmTableRef}
                      carId={car.id}
                      onIssuesListChange={() => {
                        // Custom functionality when issues list changes
                        this.handleSearchData();
                      }}
                      isSafetyInspection={true}
                    />
                    <PMFeedbackModal
                      id="pm-feedback-modal"
                      title={IssueStore.currentPMFeedbacks.title}
                      currentStep={IssueStore.currentPMFeedbacks.currentStep}
                      pmsToReceiveFeedback={
                        IssueStore.currentPMFeedbacks.pmsToReceiveFeedback
                      }
                      isModalVisible={
                        IssueStore.currentPMFeedbacks.isModalVisible
                      }
                      onSave={() => {
                        this.handleOnChangeCurrentFiltersDebouncer();
                        this.pmTableRef.current.fetchData(1, 8, {}, []);
                      }}
                    />
                  </div>
                );
              }
              // Otherwise, render the IssuesTable as default
              return (
                <div className="issues-table-wrapper">
                  <IssuesTable
                    refs={(ref) => {
                      this.mapIssuesTable[car.id] = ref;
                    }}
                    isEnabled={!_.isNil(car.id)}
                    id={`car-${car.id}`}
                    fetchData={(params, store) => {
                      IssueStore.getIssuesForCarId(
                        {
                          ...params,
                        },
                        store,
                        car.id
                      );
                    }}
                    onIssuesListChange={() => {
                      this.handleSearchData();
                    }}
                    page="carPage"
                  />
                </div>
              );
            }}
            expandedRowKeys={[
              ...this.state.expandedCarIds,
              ...this.state.expandedPMCarIds,
              ...this.state.expandedSafetyInspectionIds,
            ]}
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
            onExpand={(expanded, record) => {
              if (this.state.expandedPMCarIds.includes(record.id)) {
                // Handle PM toggle
                this.handleCarPMsToggle(record);
              } else if (
                this.state.expandedSafetyInspectionIds.includes(record.id)
              ) {
                // Handle Safety PM toggle
                this.handleSafetyInspectionToggle(record);
              } else {
                // Handle Issues toggle
                this.handleCarIssuesToggle(record);
              }
            }}
            components={{
              header: {
                cell: CustomHeaderCell,
              },
            }}
          />
        )}

        <AddAssetModal
          visible={AppStore.openModals.get('ADD_ASSET_MODAL') || false}
          type="trailer"
          onOk={this.onAddAsset}
          onCancel={() => {
            AppStore.openModals.set('ADD_ASSET_MODAL', false);
          }}
        />

        <StyledPitstopModal
          okButtonProps={{ loading: this.state.isSavingStatuses }}
          onOk={this.saveNewShopStatuses}
          onCancel={() => {
            AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
          }}
          title="Edit Status Options"
          width={800}
          id="SHOP_STATUSES_MODAL"
          isManualClose
        >
          <Text type="secondary" style={{ marginBottom: 5 }}>
            <span
              style={{
                fontWeight: 'bold',
                color: 'red',
                marginRight: 5,
              }}
            >
              *
            </span>
            Changes to these options will adjust all affected fields
          </Text>
          <List
            itemLayout="horizontal"
            dataSource={this.state.newShopCarStatuses}
            renderItem={(item) => {
              const shouldShowText = this.state.showVendorTitle
                ? item.is_show_vendor
                : item.is_default;

              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <input
                        type="color"
                        id={`color-picker-${item.key}`}
                        name={`color-picker-${item.key}`}
                        value={item.color}
                        onChange={(e) => {
                          item.color = e.target.value;
                          this.setState({
                            newShopCarStatuses: this.state.newShopCarStatuses,
                          });
                        }}
                        style={{
                          border: 0,
                          padding: 0,
                        }}
                      />
                    }
                    title={
                      item.description ? (
                        <input
                          placeholder="Insert new status name..."
                          id={`description-${item.key}`}
                          value={item.description}
                          style={{
                            border: 0,
                            width: '100%',
                          }}
                          onChange={(e) => {
                            item.description = e.target.value;
                            this.setState({
                              newShopCarStatuses: this.state.newShopCarStatuses,
                            });
                          }}
                        />
                      ) : (
                        <input
                          id={`description-${item.key}`}
                          placeholder="Insert new status name..."
                          style={{
                            border: 0,
                            width: '100%',
                          }}
                          onChange={(e) => {
                            item.description = e.target.value;
                            this.setState({
                              newShopCarStatuses: this.state.newShopCarStatuses,
                            });
                          }}
                        />
                      )
                    }
                  />
                  {shouldShowText && (
                    <StyledVendorTitle>Show vendor dropdown</StyledVendorTitle>
                  )}
                  <Tooltip
                    title={
                      item.is_show_vendor
                        ? 'Remove vendor dropdown'
                        : 'Show vendor dropdown'
                    }
                  >
                    <Checkbox
                      checked={item.is_show_vendor}
                      value={item.key}
                      onChange={(event) => {
                        this.selectStatusToShowVendors(event, item.id);
                      }}
                      style={{
                        marginRight: '1rem',
                      }}
                    />
                  </Tooltip>
                  <Text
                    style={{
                      marginRight: '0.5rem',
                      visibility: item.is_default ? 'inherit' : 'hidden',
                    }}
                  >
                    Set as default
                  </Text>
                  <Tooltip
                    title={
                      item.is_default ? 'Remove default' : 'Set as default'
                    }
                  >
                    <Checkbox
                      checked={item.is_selected}
                      value={item.key}
                      onChange={(event) => {
                        this.selectStatusAsDefault(event, item.key);
                      }}
                      style={{
                        marginRight: '1rem',
                      }}
                    />
                  </Tooltip>
                  <Popconfirm
                    placement="right"
                    title={'Are you sure you want to delete this status?'}
                    onConfirm={() => {
                      this.setState(
                        {
                          newShopCarStatuses: this.state.newShopCarStatuses.filter(
                            (status) => status.key !== item.key
                          ),
                        },
                        () => {
                          // if there's no default status, set the first one as default
                          if (
                            !_.some(this.state.newShopCarStatuses, {
                              is_default: true,
                            })
                          ) {
                            this.setState({
                              newShopCarStatuses: this.state.newShopCarStatuses.map(
                                (status, index) => {
                                  if (index === 0) {
                                    status.is_selected = true;
                                    status.is_default = true;
                                  }
                                  return status;
                                }
                              ),
                            });
                          }
                        }
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger" key="1" icon="delete" />
                  </Popconfirm>
                </List.Item>
              );
            }}
          />
          <div style={{ marginTop: 10 }}>
            <Button
              icon="plus"
              type="primary"
              ghost
              onClick={() => {
                this.state.newShopCarStatuses.push({
                  key: `new-${this.state.newShopCarStatuses.length + 1}`,
                  color: '#CFCFCF',
                });
                this.setState(
                  { newShopCarStatuses: this.state.newShopCarStatuses },
                  () => {
                    // focus on the last item
                    setTimeout(() => {
                      document
                        .getElementById(
                          `description-new-${this.state.newShopCarStatuses.length}`
                        )
                        .focus();
                    }, 100);
                  }
                );
              }}
            >
              Create New Status
            </Button>
          </div>
        </StyledPitstopModal>

        <PitstopModal
          id={'HEALTH_REPORT_MODAL_ID'}
          title=""
          width={700}
          onOk={() => {
            AppStore.openModals.set('HEALTH_REPORT_MODAL_ID', false);
            if (this.state.isPrintHealthReport) {
              return this.onPrintVehicleHealthReport();
            }
            this.onDownloadVehicleHealthReport();
          }}
          onCancel={() =>
            AppStore.openModals.set('HEALTH_REPORT_MODAL_ID', false)
          }
        >
          <Row>
            <h3>Please select report's format:</h3>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <b>Issues Status</b>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.issueReportStatus}
                onChange={(values) => {
                  this.setState({ issueReportStatus: values });
                }}
              >
                <Row>
                  {_.map(statusOptions, (status) => (
                    <Col key={status.value} span={8}>
                      <Checkbox value={status.value}>{status.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col span={24}>
              <b>Issues Sources</b>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.issueSources}
                onChange={(values) => {
                  this.setState({ issueSources: values });
                }}
              >
                <Row>
                  {_.map(sourceOptions, (source) => (
                    <Col key={source.value} span={12}>
                      <Checkbox value={source.value}>{source.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </PitstopModal>

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => {}}
          onCancel={() => {
            AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
          }}
          title=""
          width={800}
          id="SHARE_VEHICLE_HEALTH_REPORT_MODAL"
        >
          <ShareVehicleHealthReportPage
            selectedCarId={this.state.selectedCarId}
          />
        </PitstopModal>

        <ArchiveAssetModal
          visible={this.state.isArchiveAssetsModalVisible}
          isArchiving={this.state.isArchivingAssets}
          onCancel={() => {
            this.setState({ isArchiveAssetsModalVisible: false });
          }}
          onOk={(notes) => {
            this.handleArchiveAssets(notes);
          }}
        />
      </IndicatorTableWrapper>
    );
  }
}

decorate(VehiclesTable, {
  tableStore: observable,
  refreshTableOffset: observable,
  shopId: computed,
});

export default withRouter(observer(VehiclesTable));
