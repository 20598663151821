import React, { useEffect, useRef } from 'react';

import { Select } from 'antd';

import { CarStore } from 'stores';

const VendorsDropdown = ({ car, vendors, onUpdate, isShown, shopId }) => {
  const isShownRef = useRef(isShown);
  const shopIdRef = useRef(shopId);

  const onChange = async (value) => {
    await CarStore.updateCarVendor(car?.id, value);

    onUpdate(car?.id, value);
  };

  useEffect(() => {
    const handleIsShownChange = async () => {
      if (isShownRef.current && !isShown) {
        await CarStore.updateCarVendor(car?.id, 0);
        onUpdate(car?.id, 0);
      }
      isShownRef.current = isShown;
      shopIdRef.current = shopId;
    };
    if (shopIdRef.current === shopId) {
      handleIsShownChange();
    }
  }, [isShown, shopId]);

  if (!isShown) return null;

  return (
    <Select
      style={{ width: 'auto' }}
      showSearch
      allowClear
      placeholder="Select a vendor"
      onChange={onChange}
      optionFilterProp="children"
      value={car?.carVendorId || undefined}
    >
      {vendors.map((vendor) => (
        <Select.Option value={vendor.id}>{vendor.name}</Select.Option>
      ))}
    </Select>
  );
};

export default VendorsDropdown;
