import React, { Component } from "react";
import styled from "styled-components";
import { LoginForm } from "v3/components/forms/auth";
import { Link } from "react-router-dom";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #02103d; /* Dark blue background */
  position: relative; /* Enable stacking context for layering */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoBackground = styled.div`
  position: absolute;
  top: 40px; /* Distance from top */
  left: 40px; /* Distance from left */
  z-index: 1; /* Ensures it is layered correctly */
  pointer-events: none; /* Prevent interactions */

  img {
    width: 200px; /* Adjusted smaller size */
    height: auto; /* Maintains aspect ratio */
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px; /* Adjust width */
  background: #ffffff;
  padding: 4rem;
  border-radius: 40px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  z-index: 2; /* Topmost layer */
`;

const FormSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align elements horizontally */
`;

const SignupWrapper = styled.div`
  margin-top: 20px;
  text-align: center;

  a {
    color: #02103d;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

class LoginPage extends Component {
  state = {
    isLogin: false,
  };

  render() {
    const { isLogin } = this.state;

    return (
      <Container>
        {/* Logo in the Background */}
        <LogoBackground>
          <img src={require("assets/logos/logo.svg")} alt="Pitstop Logo" />
        </LogoBackground>

        {/* Main White Content */}
        <ContentWrapper>
          <FormSection>
            {/* Login Form */}
            <LoginForm
              isUserLogin={(isLogin) => {
                this.setState({
                  isLogin: isLogin,
                });
              }}
            />

            {/* Sign Up Link */}
            <SignupWrapper>
              Don't have an account? <Link to="/register">Sign up</Link>
            </SignupWrapper>
          </FormSection>
        </ContentWrapper>
      </Container>
    );
  }
}

export default LoginPage;
