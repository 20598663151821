import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Upload, Icon } from 'antd';
import { notification } from 'antd';
import WorkOrderDetailsContext from './WorkOrderDetailsContext';
import Tiff from 'tiff.js';

const ImageUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
`;

const ImagePreviewWrapper = styled.div`
  border-radius: 2px;
  position: relative;
  width: 112px;
  height: 112px;
  padding-left:5px;
  &:hover {
    .delete-btn {
      display: flex;
    }
  }
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #d5d3d3;
`;

const CanvasThumbnail = styled.canvas`
  width: 100%;
  height: 100%;
  background: #d5d3d3;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: -10%;
  right: -10%;
  background: black;
  border-radius: 56%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;

  .anticon {
    font-size: 13px;
  }
`;

const UploadButton = styled(Upload)`
  .ant-upload {
    width: 112px;
    height: 112px;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-upload-text {
      text-align: center;
    }

    .anticon-plus {
      font-size: 13px;
    }
  }
`;

const UploadAreaContainer = styled.div`
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #fff;
`;

const UploadButtonWrapper = styled.div`
  width: 112px;
  height: 112px;
  padding-left: 5px;
`;


// eslint-disable-next-line react/prop-types
const ImageUploader = ({onImageUploaded, wo_files, childRef}) => {
  const [fileList, setFileList] = useState([]);
  const { onClickImage } = useContext(WorkOrderDetailsContext);

  useEffect(() => {
    if (wo_files.wo_images && wo_files.wo_images.length > 0) {
      const initialFileList = wo_files.wo_images.map((url, index) => ({
        uid: `old-${index}`,
        name: `Image ${index}`,
        status: 'done',
        url,
        thumbUrl: url,
      }));

      setFileList(initialFileList);
    }
  }, [wo_files.wo_images]);

  const renderTiffImage = (url, canvasRef) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const tiff = new Tiff({ buffer });
        const canvas = canvasRef.current;
        const tiffCanvas = tiff.toCanvas();
  
        // Set the canvas dimensions to match the TIFF image
        canvas.width = tiffCanvas.width;
        canvas.height = tiffCanvas.height;
  
        // Draw the TIFF image onto the canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(tiffCanvas, 0, 0, canvas.width, canvas.height);
      })
      .catch((error) => console.error('Error rendering TIFF image:', error));
  };
  

  const handlePreview = (file) => {
    const isGreaterThan2M = file.size / 1024 / 1024 > 10;

    if (isGreaterThan2M) {
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        file.preview = e.target.result;
        setFileList([...fileList, file]);
      };
      reader.readAsDataURL(file);
    }

    onImageUploaded(file);
  };

  if (childRef) {
    childRef({
      getImages: () => fileList,
      handlePreview
    });
  }

  const handleChange = ({ file, fileList: newFileList }) => {
    console.log('newFileList', newFileList);
    const filteredList = newFileList.filter(f => !f.size || f.size / 1024 / 1024 <= 10);
    console.log('filteredList', filteredList);
    if (newFileList.length > filteredList.length) {
      notification.open({
          message: <div style={{ color: 'red' }}>Error</div>,
          description: 'File size must be less than 10MB.',
      });
    }

    setFileList(filteredList);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    onImageUploaded(file, 'removed');
  };

  const uploadButton = (
    <div style={{ width: '92px', height: '92px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Icon type="plus" />
      <div style={{fontSize: '13px'}}>Upload</div>
    </div>
  );

  const openTiffViewer = (fileUrl) => {
    const tiffViewerUrl = `/work-order-tiff-viewer?tiffUrl=${encodeURIComponent(fileUrl)}`;
    const fullUrl = `${window.location.origin}${tiffViewerUrl}`;
    window.open(fullUrl, '_blank'); // Open the viewer in a new tab
  };

  return (
    <UploadAreaContainer>
      <div><b>Add images:</b></div>
      <ImageUploadContainer>
        {fileList.map((file) => {
          const canvasRef = React.createRef();
          let isTiff = false;
          if (file.thumbUrl && file.thumbUrl.includes('UFP-Workorder/')) {
            isTiff = true;
          }
          return (
            <ImagePreviewWrapper
              key={file.uid}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isTiff) {
                  onClickImage(file.preview || file.thumbUrl);
                }
              }}
            >
              {isTiff ? (
                <CanvasThumbnail
                  ref={canvasRef}
                  onClick={() => openTiffViewer(file.thumbUrl)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <ImageThumbnail
                  src={file.preview || file.thumbUrl}
                  alt={file.name}
                />
              )}
              {isTiff && renderTiffImage(file.thumbUrl, canvasRef)}
              <DeleteButton
                className="delete-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemove(file);
                }}
              >
                <Icon type="close" />
              </DeleteButton>
            </ImagePreviewWrapper>
          );
        })}
        {fileList.length < 18 && (
          <UploadButtonWrapper>
            <UploadButton
              accept=".png,.jpg,.jpeg"
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              onRemove={handleRemove}
              beforeUpload={(file) => {
                handlePreview(file);
                return false;
              }}
              showUploadList={false}
            >
              {uploadButton}
            </UploadButton>
          </UploadButtonWrapper>
        )}
      </ImageUploadContainer>
    </UploadAreaContainer>
  );
};

export default ImageUploader;
