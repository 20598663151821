import React, { useEffect, useState } from 'react';
import PitstopModal from "shared/PitstopUI/PitstopModal";
import { Button, Checkbox, List, Popconfirm, Tooltip, Typography } from "antd";
import { AppStore, ShopStore, WorkOrderStore } from 'stores';
import { EventBus } from 'stores/EventBus';
import { v4 as uuidv4 } from 'uuid';

const { Text } = Typography;

type DataSourceItem = {
  id?: number;
  key: string;
  color: string;
  description: string;
  is_default: boolean;
};

interface WorkOrderEditShopStatusesModalProps {
  isSavingStatuses: boolean;
  onCancel: () => void;
  dataSource: DataSourceItem[];
  shopId: number;
}

const WorkOrderEditShopStatusesModal = ({
  onCancel,
  dataSource,
  shopId = ShopStore.currentShop.id || -1,
}: WorkOrderEditShopStatusesModalProps): React.ReactNode => {
  const [isSavingStatuses, setIsSavingStatuses] = useState(false);
  const [newShopStatuses, setNewShopStatuses] = useState<DataSourceItem[]>([]);

  const handleSaveNewShopStatuses = async () => {
    try {
      setIsSavingStatuses(true);
      await WorkOrderStore.saveNewShopStatuses(
        shopId,
        newShopStatuses
      );
      EventBus.$emit('WORK_ORDER_SHOP_STATUSES_UPDATED');
      AppStore.openModals.set(WorkOrderStore.WORK_ORDER_EDIT_SHOP_STATUSES_MODAL, false);
      setIsSavingStatuses(false);
      setNewShopStatuses([]);
    } catch (error) {
      AppStore.addError('Failed to save new shop statuses');
    } finally {
      setIsSavingStatuses(false);
    }
  };

  useEffect(() => {
    if (AppStore.openModals.get(WorkOrderStore.WORK_ORDER_EDIT_SHOP_STATUSES_MODAL)) {
      // Create a deep copy of dataSource to avoid mutating the original data
      setNewShopStatuses(dataSource.map(status => ({...status})));
    }
  }, [dataSource]);

  const handleColorChange = (key: string, newColor: string) => {
    setNewShopStatuses(prevStatuses =>
      prevStatuses.map(status =>
        status.key === key ? {...status, color: newColor} : status
      )
    );
  };

  const handleDescriptionChange = (key: string, newDescription: string) => {
    setNewShopStatuses(prevStatuses =>
      prevStatuses.map(status =>
        status.key === key ? {...status, description: newDescription} : status
      )
    );
  };

  const handleDefaultChange = (key: string, isDefault: boolean) => {
    setNewShopStatuses(prevStatuses =>
      prevStatuses.map(status =>
        status.key === key
          ? {...status, is_default: isDefault}
          : {...status, is_default: false}
      )
    );
  };

  return (
    <PitstopModal
      id={WorkOrderStore.WORK_ORDER_EDIT_SHOP_STATUSES_MODAL}
      okButtonProps={{ loading: isSavingStatuses }}
      onOk={() => {
        handleSaveNewShopStatuses();
      }}
      onCancel={onCancel}
      title="Edit Work Order Statuses"
      width={800}
      style={{ maxWidth: '35%' }}
      isManualClose
    >
      <Text type="secondary" style={{ marginBottom: 5 }}>
        <span
          style={{
            fontWeight: 'bold',
            color: 'red',
            marginRight: 5,
          }}
        >
          *
        </span>
        Changes to these options will adjust all affected fields
      </Text>
      <List
        itemLayout="horizontal"
        dataSource={newShopStatuses}
        renderItem={(item: DataSourceItem) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <input
                  type="color"
                  id={`color-picker-${item.key}`}
                  name={`color-picker-${item.key}`}
                  value={item.color}
                  onChange={(e) => handleColorChange(item.key, e.target.value)}
                  style={{
                    border: 0,
                    padding: 0,
                  }}
                />
              }
              title={
                <input
                  placeholder="Insert new status name..."
                  id={`description-${item.key}`}
                  value={item.description}
                  style={{
                    border: 0,
                    width: '100%',
                  }}
                  onChange={(e) => handleDescriptionChange(item.key, e.target.value)}
                />
              }
            />
            {item.is_default && (
              <Text style={{ marginRight: '0.5rem' }}>Set as default</Text>
            )}
            <Tooltip
              title={item.is_default ? 'Remove default' : 'Set as default'}
            >
              <Checkbox
                checked={item.is_default}
                value={item.key}
                onChange={(event) => handleDefaultChange(item.key, event.target.checked)}
                style={{
                  marginRight: '1rem',
                }}
              />
            </Tooltip>
            <Popconfirm
              placement="right"
              title={'Are you sure you want to delete this status?'}
              onConfirm={() => {
                setNewShopStatuses(prevStatuses =>
                  prevStatuses.filter(status => status.key !== item.key)
                );
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" key="1" icon="delete" />
            </Popconfirm>
          </List.Item>
        )}
      />
      <div style={{ marginTop: 10 }}>
        <Button
          icon="plus"
          type="primary"
          ghost
          onClick={() => {
            const newStatus: DataSourceItem = {
              key: uuidv4(),
              color: '#CFCFCF',
              description: '',
              is_default: false,
            };
            setNewShopStatuses(prevStatuses => [...prevStatuses, newStatus]);
          }}
        >
          Create New Status
        </Button>
      </div>
    </PitstopModal>
  )
};

export default WorkOrderEditShopStatusesModal;
