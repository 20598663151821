import React from 'react';
import { PDF } from 'shared/PitstopUI/pdf';

interface MediaPreviewProps {
  media: string;
}

/**
 * @param props
 * @param props.media - The URL or base64 media to preview
 */
const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
  if (!media) {
    return null;
  }

  // if is a base64 pdf
  if (media.includes('application/pdf') || media.includes('.pdf')) {
    return <div id="media-preview" style={{ maxHeight: '80vh', overflow: 'auto', paddingBottom: 30 }}>
      <PDF maxHeight="auto" file={media} type="single" />
    </div>
  }

  return (
    <div>
      <img
        id="media-preview"
        src={media}
        alt="Media Preview"
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default MediaPreview;
